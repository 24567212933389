import { Component, OnInit,ViewChild, Inject, ChangeDetectorRef, ElementRef, AfterViewInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective } from '@angular/forms';
import { HttpService } from "../../shared/services/http.service";
import { HelperService } from 'app/helper.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatAutocompleteTrigger } from '@angular/material';
import { map, startWith } from 'rxjs/operators';
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { BehaviorSubject } from 'rxjs';
import { BarcodeFormat } from '@zxing/library';


@Component({
    selector: 'spare-parts',
    templateUrl: './spare-parts.component.html',
    styleUrls: ['./spare-parts.component.scss'],
})

export class SparePartsComponent implements OnInit {

    @ViewChild(MatAutocompleteTrigger, { static: true }) inputAutoComplit: MatAutocompleteTrigger;
    @ViewChild('spform', {static:true}) spform;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    @ViewChild("itempaginator", { static: false }) itempaginator: MatPaginator;
    @ViewChild("itemsort", { static: false }) itemsort: MatSort;
    @ViewChild("itemCodeQtyToAddPaginator", { static: false })
    itemAndQtyToAddPaginator: MatPaginator;
    @ViewChild("itemCodeAndQtyToAddSort", { static: false })
    itemAndQtyToAddsort: MatSort;
    @ViewChild("focusInput", { static: true }) focusInput: ElementRef;
    spareForm: FormGroup;
    selectedImage: string | null = null;
    sparePartsForm: FormGroup;
    spareCodeFlag: boolean = true;
    spareParts: any = [];
    filesList: any = [];
    spareFormSubmit: FormGroup;
    projectList: any = [];
    spareRecords: any = [];
    count = 1;
    firstParam: any;
    projectItemCodes: any;
    projectName:any = "";
    maxRecords: number = 10;
    recordCount: any;
    errorMessage: string = '';
    showComponent: boolean = true;
    sparePartsPost: any = [];
    recordDetailsByID: any;
    recordDetailDescription: any;
    recordDetailstartDate: any;
    recordDetailspareParts: any;
    recordDetailfileUrls: any = [];
    recordDetailcloseRecord: any;
    recordDetailID: any;
    responseMessage:any;
    fileSizeError: string = '';
    displayedColumns = [ "recordId","description","createdBy","startDate"];

      // dataSource: MatTableDataSource<any>;
  dataSource: MatTableDataSource<UserDetailsInterface>;

  itemdataSoure: MatTableDataSource<any>;
  itemDisplayedColumns = ["itemCode", "quantity"];
  itemDisplayedColumnsWhileAdding = ["itemCode", "quantity", "delete"];
  itemCodeQuantityList: any;
  itemQuantityDataSourceToAdd: MatTableDataSource<any>;

    addedSpares: any = [];
    spareList: any = [];
    defectCodes: any = [];
    isSpareError: boolean = false;
    componentCodes: any = [];
    spareMasterCode: any = [];
    SMQuantity: number = 1;
    category:any;
    totalCount:any;
    scanStart:boolean=false; 
        scanSerialStart: boolean = false;
        qrResultString: string;
        availableDevices: MediaDeviceInfo[];
        hasDevices: boolean;
        qrResults: any=[];
        currentDevice: MediaDeviceInfo = null;
        hasPermission: boolean;
        torchAvailable$ = new BehaviorSubject<boolean>(false);
        torchEnabled = false;
        tryHarder = false;
        formatsEnabled: BarcodeFormat[] = [
          BarcodeFormat.CODE_128,
          BarcodeFormat.DATA_MATRIX,
          BarcodeFormat.EAN_13,
          BarcodeFormat.QR_CODE,
        ];
    openPanel(evt): void {
    evt.stopPropagation();
    this.inputAutoComplit.openPanel();
    }

constructor(private route: ActivatedRoute, private fb: FormBuilder, private httpService: HttpService, private spinner: NgxSpinnerService, private helperservice: HelperService, private cdRef: ChangeDetectorRef){
    this.spareForm = this.fb.group({
        recoveredSparePart: ['', [Validators.required]],
        otherSparePart:[''],
        quantity: ['1', [Validators.required]],
        isSparePartRecovered: ['YES', [Validators.required]],
    });
    this.spareFormSubmit = this.fb.group({

    });

    this.loadFilter();
}


  ngOnInit() {
    this.firstParam = this.route.snapshot.queryParamMap.get('projectID');
    this.getSpareParts();
    //this.fetchProjectDetail();
    this.fetchRecords();
    this.getProjectDetails();

      this.sparePartsForm = this.fb.group({
        description: [''],
      });
  }

  ngAfterViewInit() {
    $('Add-Spare-Dialog').on('shown.bs.modal',()=>{
      setTimeout(()=>{
        this.inputAutoComplit.updatePosition();
      },100);
    })
  }

  increaseQuantity(){
    this.SMQuantity++;
    this.spareForm.get('quantity').setValue(this.SMQuantity);
  }

  decreaseQuantity(){
    if(this.SMQuantity>1){
      this.SMQuantity--;
      this.spareForm.get('quantity').setValue(this.SMQuantity);
    }
  }

  getProjectDetails() {
    this.spinner.show();
    this.httpService.getRequest('specialProjectApi/v1/projectDetails?projectId=' + this.firstParam).subscribe(
      res => {
        this.spinner.hide();
        if(res['status'] === 'ERROR'){
          this.responseMessage = res['errorMessage'];
          this.helperservice.openStaticModal('ConfirmDialog');
          return;
        }
        this.projectItemCodes = res['projectDetails']['projectItemCodeMappingDtoList'];
        this.projectName = res['projectDetails']['projectName'];
        this.category = res['projectDetails']['category'];
        this.totalCount = res['projectDetails']['totalQuantity'];


      },
      err => {
        // alert("Internal issue to fetch the information");
        this.spinner.hide();
        this.responseMessage = "Internal issue to fetch the information";
        this.helperservice.openStaticModal('ConfirmDialog');
      }
    );
  }

  fetchRecords(){
    this.spinner.show();
    this.httpService
        .getRequest("sparePartApi/v1/getSparePartRecords?pageNumber=1&pageSize=100"+"&projectId="+this.firstParam+"&userId="+localStorage.getItem('userId'))
        .subscribe(
            (res) => {
                this.spinner.hide();
                if(res['status'] === 'ERROR'){
                  this.responseMessage = res['response'];
                  this.helperservice.openStaticModal('ConfirmDialog');
                  return;
                }
                console.log(res);
                this.spareRecords = res["response"];
                this.recordCount = this.spareRecords.length;
                this.projectList = res["projects"];
                this.dataSource =
                    new MatTableDataSource<UserDetailsInterface>(
                        this.spareRecords
                    );
                console.log(this.dataSource);
                // this.setPaginator();
            },
            err => {
              // alert("Internal issue to fetch the information");
              this.spinner.hide();
              this.responseMessage = "Internal issue to fetch the information";
              this.helperservice.openStaticModal('ConfirmDialog');
            }
        );
  }

  nextRecords(){
    this.count++;
    if(this.count >= 0){
        this.spinner.show();
        this.httpService
            .getRequest("sparePartApi/v1/getSparePartRecords?pageNumber="+this.count+"&pageSize=100"+"&projectId="+this.firstParam+"&userId="+localStorage.getItem('userId'))
            .subscribe(
                (res) => {
                    this.spinner.hide();
                    console.log(res);
                    if(res['status'] === 'ERROR'){
                      this.responseMessage = res['response'];
                      this.helperservice.openStaticModal('ConfirmDialog');
                      return;
                    }
                    if(res["response"].length == 0){
                        console.log(res["response"].length);
                        // alert("No more records");
                        this.responseMessage = "No more records";
                        this.helperservice.openStaticModal('ConfirmDialog');
                    } else {
                        this.spareRecords = res["response"];
                        this.recordCount = this.spareRecords.length;
                        this.projectList = res["projects"];
                        this.dataSource =
                            new MatTableDataSource<UserDetailsInterface>(
                                this.spareRecords
                            );
                        console.log(this.dataSource);
                        // this.setPaginator();
                    }
    
                },
                err => {
                  // alert("Internal issue to fetch the information");
                  this.spinner.hide();
                  this.responseMessage = "Internal issue to fetch the information";
                  this.helperservice.openStaticModal('ConfirmDialog');
                }
            );
    } else {
        // alert();
          this.responseMessage = "Internal issue to fetch the information";
          this.helperservice.openStaticModal('ConfirmDialog');
    }

  }

  previousRecords(){
    this.count--;
    if(this.count <= 0){
        // alert("No less records");
        this.responseMessage = "Page number can not be less than 0.";
        this.helperservice.openStaticModal('ConfirmDialog');
    } else {
        this.spinner.show();
        this.httpService
            .getRequest("sparePartApi/v1/getSparePartRecords?pageNumber="+this.count+"&pageSize=100"+"&projectId="+this.firstParam+"&userId="+localStorage.getItem('userId'))
            .subscribe(
                (res) => {
                    this.spinner.hide();
                    console.log(res);
                    if(res['status'] === 'ERROR'){
                      this.responseMessage = res['response'];
                      this.helperservice.openStaticModal('ConfirmDialog');
                      return;
                    }
                    if(res["response"].length == 0){
                        // alert("No more records");
                        this.responseMessage = "No more records";
                        this.helperservice.openStaticModal('ConfirmDialog');
                    } else {
                        this.spareRecords = res["response"];
                        this.projectList = res["projects"];
                        this.dataSource =
                            new MatTableDataSource<UserDetailsInterface>(
                                this.spareRecords
                            );
                        console.log(this.dataSource);
                        // this.setPaginator();
                    }
    
                },
                err => {
                  // alert("Internal issue to fetch the information");
                  this.spinner.hide();
                  this.responseMessage = "Internal issue to fetch the information";
                  this.helperservice.openStaticModal('ConfirmDialog');
                }
            );
    }

  }

//   fetchProjectDetail() {
//     this.spinner.show();
//     this.httpService
//         .getRequest("specialProjectApi/v1/projectList")
//         .subscribe(
//             (res) => {
//                 this.spinner.hide();
//                 console.log(res["items"]);
//                 this.projectList = res["projects"];
//                 this.dataSource =
//                     new MatTableDataSource<UserDetailsInterface>(
//                         this.projectList
//                     );
//                 console.log(this.dataSource);
//                 this.setPaginator();
//             },
//             (err) => {
//                 alert("Internal issue to fetch the information");
//                 this.spinner.hide();
//             }
//         );
// }

  spareRequiredYes(){

  }

  spareRequiredNo(){

  }

  setPaginator() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
}
setPaginatorForItem() {
    this.itemdataSoure.paginator = this.itempaginator;
    this.itemdataSoure.sort = this.itemsort;
}

setPaginatorForItemCodeAndQtyToAdd() {
    this.itemQuantityDataSourceToAdd.paginator =
        this.itemAndQtyToAddPaginator;
    this.itemQuantityDataSourceToAdd.sort = this.itemAndQtyToAddsort;
}

applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
}

  getSpareParts() {
    this.httpService
        .getRequest("specialProjectApi/v1/spareParts")
        .subscribe(
            (res) => {
                if ((res["status"] = "SUCCESS")) {
                  this.spareParts = res["spareParts"];
                  this.spareParts.push({"spareName":"Other"})
                } else {
                    // console.log("error fetching spare parts");
                    this.responseMessage = "Error fetching spare parts.";
                    this.helperservice.openStaticModal('ConfirmDialog');
                }
            },
            err => {
              // alert("Internal issue to fetch the information");
              this.spinner.hide();
              this.responseMessage = "Internal issue to fetch the information";
              this.helperservice.openStaticModal('ConfirmDialog');
            }
        );
  }

  loadFilter() {
    this.spareMasterCode = this.spareForm.get('recoveredSparePart').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterSpareCode(value))
      );
  
  }

  private _filterSpareCode(value: string): string[] {
    if ((value === undefined || value === null) || (this.spareParts === undefined || this.spareParts === null))
      return;
    const filterValue = value.toLowerCase();
    var data = this.spareParts.filter(option1 => String(option1.matnr12nc).toLowerCase().indexOf(filterValue) > -1 ||
      option1.spareName.toLowerCase().indexOf(filterValue) > -1);
    return data
  }

  SMcode: any;
  SMother:any
getSpareCodeVerified(event) {
  if(event.source.value=="Other" && event.isUserInput){
    this.spareForm.controls["otherSparePart"].setValidators([
      Validators.required,
  ]);
  }else{
    this.spareForm.controls['otherSparePart'].clearValidators();
  }
  this.spareForm.controls['otherSparePart'].updateValueAndValidity();
  this.spareCodeFlag = event.isUserInput;
}

focusOutSpareCode(code) {
    if (code != undefined) {
      var codeFound = this.spareParts.find(x => x.spareName == code);
      if (codeFound != undefined && code == codeFound.spareName) {
        this.spareCodeFlag = true;
      }
      else {
        this.spareCodeFlag = false;
      }
    }
  
  }

  cancelSpare() {
    this.isSpareError = false;
    this.spareForm.reset();
    this.spform.resetForm(
      {
        recoveredSparePart: '',
        otherSparePart:'',
        quantity: '1',
        isSparePartRecovered: 'YES',
      }
    );
    this.helperservice.hideModal('Add-Spare-Dialog');
    this.loadFilter();
  }
  

  addSpare() {
    if (!this.spareForm.valid) {
      this.helperservice.makeFormDirty(this.spareForm);
      return;
    }
    var spare = this.spareForm.value;
    console.log(spare);
    if (spare.recoveredSparePart != undefined) {
      var codeFound = this.spareParts.find(x => x.spareName == spare.recoveredSparePart);
      console.log("codeFound : "+codeFound);
      if(codeFound!=undefined){
      this.addedSpares.push(codeFound);
      console.log(this.addedSpares)
      this.sparePartsPost.push(spare);
      console.log(this.sparePartsPost);
      //Object.assign({})
        Object.assign(codeFound, {spareQuantity: spare.quantity},{spareStatus: spare.isSparePartRecovered},{otherSpareName:spare.otherSparePart});
        console.log(this.addedSpares);
      this.spareList.push(codeFound.matnr12nc);
          this.spareCodeFlag = false;
          this.cancelSpare() ; 
        }
      }
    }

    sparePartSubmitForm(formDirective: FormGroupDirective){
      if (!this.sparePartsForm.valid) {
        this.helperservice.makeFormDirty(this.sparePartsForm);
        return;
    }

    if (this.sparePartsPost.length === 0) {
        this.responseMessage = "Please add Spare parts";
        this.helperservice.openStaticModal('ConfirmDialog');
        return;
    }

    const fd = new FormData();
    fd.append('userId', 'ISA_SPECIAL_PROJECT');

    if (this.filesList.length > 0) {
        for (let file of this.filesList) {
            fd.append('files', file);
        }
        this.uploadFiles(fd, formDirective);
    } else {
        this.saveSparePartRecord([], formDirective);
    }
    }

    closePopup() {
      this.helperservice.hideModal('ConfirmDialog');
    }

    uploadImage(event: any) {
      var files = event.target.files;
      console.log(files);
  
      this.fileSizeError = '';
  
      for (var i = 0; i < files.length; i++) {
          var f = files[i];
          if (f.size > 5 * 1024 * 1024) {
              this.fileSizeError = `File ${f.name} exceeds the 5MB size limit.`;
              continue;
          }
  
          f.mediaSRC = URL.createObjectURL(f);
          this.filesList.push(f);
          console.log(this.filesList);
      }
  }

    // singleImageUpload1(data, formDirective) {
    //     //console.log(data);return;
    //     this.spinner.show();
    //     let d=new Date();
    //     d.setHours(d.getHours() + 5);
    //     d.setMinutes(d.getMinutes() + 30);
    //     let startDate = new Date(d)
    //     this.httpService.uploadImageRequest('specialProject/uploadFiles', data).subscribe(
    //       res => {
    //         var inspFrom = this.sparePartsForm.value;
    //         // console.log(res);return;
    //         let postData = {
    //           "projectId": this.firstParam,
    //           "startDate": startDate,
    //           "description": inspFrom.description,
    //           "fileUrls": res['fileUrl'],
    //           "spareParts": this.sparePartsPost,
    //           "createdBy": localStorage.getItem('userId'),
    //         };
    //         // if(inspFrom.classification != null && inspFrom.classification != "" ) {
    //         //     postData["classification"] = inspFrom.classification
    //         // }
    //         console.log("PostData : "+JSON.stringify(postData));
    //         this.httpService.postRequest('sparePartApi/v1/saveSparePartRecord', postData).subscribe(
    //           res => {
    //             this.spinner.hide();
    //             console.log(res);
    //             if (res["status"] == "SUCCESS") {
    //               this.responseMessage = res['response'];
    //               this.helperservice.openStaticModal('ConfirmDialog');
    //                 this.addedSpares = [];
    //                 this.sparePartsForm.reset();
    //                 formDirective.resetForm();
    //                 this.filesList = [];
    //                 const fileInput = document.getElementById("chooseFile") as HTMLInputElement;
    //                     if (fileInput) {
    //                         fileInput.value = "";
    //                     }
    //                 this.fetchRecords();
    //             }else {
    //               this.responseMessage = res['response'];
    //               this.helperservice.openStaticModal('ConfirmDialog');
    //             }
    //           },
    //           err => {
    //             this.spinner.hide();
    //             // console.log("error block");
    //             this.responseMessage = err.response;
    //             this.helperservice.openStaticModal('ConfirmDialog');
    //           }
    //         );
    //       },
    //       err => {
    //         this.spinner.hide();
    //         this.responseMessage = "Error occurred during file upload.";
    //         this.helperservice.openStaticModal('ConfirmDialog');
    //       }
    //     );
    //   }

    uploadFiles(data: FormData, formDirective: FormGroupDirective) {
      this.spinner.show();
      this.httpService.uploadImageRequest('specialProject/uploadFiles', data,"SPARE_PARTS").subscribe(
          res => {
              this.saveSparePartRecord(res['fileUrl'], formDirective);
          },
          err => {
              this.spinner.hide();
              this.responseMessage = "Error occurred during file upload.";
              this.helperservice.openStaticModal('ConfirmDialog');
          }
      );
  }
  
  saveSparePartRecord(fileUrls: string[], formDirective: FormGroupDirective) {
      this.spinner.show();
      let d = new Date();
      d.setHours(d.getHours() + 5);
      d.setMinutes(d.getMinutes() + 30);
      let startDate = new Date(d);
  
      const formData = this.sparePartsForm.value;
  
      let postData = {
          projectId: this.firstParam,
          startDate: startDate,
          description: formData.description,
          fileUrls: fileUrls,
          spareParts: this.sparePartsPost,
          createdBy: localStorage.getItem('userId'),
      };
  
      this.spinner.show();
      this.httpService.postRequest('sparePartApi/v1/saveSparePartRecord', postData).subscribe(
          res => {
              this.spinner.hide();
              if (res["status"] === "SUCCESS") {
                  this.responseMessage = res['response'];
                  this.helperservice.openStaticModal('ConfirmDialog');
                  this.addedSpares = [];
                  this.sparePartsForm.reset();
                  formDirective.resetForm();
                  this.filesList = [];
                  const fileInput = document.getElementById("chooseFile") as HTMLInputElement;
                  if (fileInput) {
                      fileInput.value = "";
                  }
                  this.fetchRecords();

                  this.sparePartsPost = [];
                  this.addedSpares = [];
              } else {
                  this.responseMessage = res['response'];
                  this.helperservice.openStaticModal('ConfirmDialog');
              }
          },
          err => {
              this.spinner.hide();
              this.responseMessage = err.response;
              this.helperservice.openStaticModal('ConfirmDialog');
          }
      );
  }
  

      formatDateUTC(date: Date): string {
        if (!date) return '';
        const options: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: 'UTC' // Ensures UTC time
        };
        return new Intl.DateTimeFormat('en-GB', options).format(date).replace(',', '');
    }

      getRecordDetails(recordID){
        this.recordDetailID = recordID;
        console.log(recordID);
        this.spinner.show();
        this.httpService
        .getRequest("sparePartApi/v1/getSparePartRecordDetails?recordId="+recordID)
        .subscribe(
            (res) => {
                this.spinner.hide();
                console.log(res["response"]);
                if ((res["status"] == "SUCCESS")) {
                this.recordDetailsByID = res["response"].recordId;
                this.recordDetailDescription = res["response"].description;
                // this.recordDetailstartDate = res["response"].startDate;
              this.recordDetailstartDate = this.formatDateUTC(new Date(res["response"].startDate));
                this.recordDetailspareParts = res["response"].spareParts;
                this.recordDetailfileUrls = res["response"].fileUrls;
                this.recordDetailcloseRecord = res["response"].createdBy;
                console.log(this.recordDetailsByID);
                this.helperservice.openStaticModal("seeProject-Dialog");
                } else {
                  this.responseMessage = res['response'];
                  this.helperservice.openStaticModal('ConfirmDialog');
                  }
            },
            err => {
              // alert("Internal issue to fetch the information");
              this.spinner.hide();
              this.responseMessage = "Internal issue to fetch the information";
              this.helperservice.openStaticModal('ConfirmDialog');
            }
        );
      }

      closeRecordModal(){
        this.helperservice.hideModal("seeProject-Dialog");
        this.closeImage();
      }

      closeRecord(){
        this.recordDetailID;
        // var date = new Date().toISOString().slice(0, 10);
        let d=new Date();
        d.setHours(d.getHours() + 5);
        d.setMinutes(d.getMinutes() + 30);
        let endDate = new Date(d)
        //console.log(date);return;
        this.spinner.show();
            let postData = {
              "projectId": this.firstParam,
              "recordId": this.recordDetailID,
              "endDate": endDate
            };
            console.log("PostData : "+JSON.stringify(postData));
            this.httpService.postRequest('sparePartApi/v1/closeRecord', postData).subscribe(
              res => {
                this.helperservice.hideModal("seeProject-Dialog");
                this.spinner.hide();
                this.fetchRecords();
                console.log("look here",res);
              },
              err => {
                this.spinner.hide();
                this.responseMessage = err.response;//ask
                this.helperservice.openStaticModal('ConfirmDialog');
              }
            );
      }

      editComments(){
        this.spinner.show();
        let postData = {
          "projectId": this.firstParam,
          "recordId": this.recordDetailID,
          "description": this.recordDetailDescription
        };
        console.log("PostData : "+JSON.stringify(postData));
        this.httpService.postRequest('sparePartApi/v1/updateRecordDescription', postData).subscribe(
          res => {
            this.helperservice.hideModal("seeProject-Dialog");
            this.spinner.hide();
            // alert("Comments Updated Successfully");
            this.responseMessage = "Comments Updated Successfully"
            this.helperservice.openStaticModal('ConfirmDialog');
            this.fetchRecords();
            console.log(res);
          },
          err => {
            this.spinner.hide();
            this.responseMessage = err.response;//ask
            this.helperservice.openStaticModal('ConfirmDialog');
          }
        );
      }

      openImage(imageUrl: string) {
        this.selectedImage = imageUrl;
    }
    
    closeImage() {
        this.selectedImage = null;
    }


    onCodeResult(resultString: string) {
      console.log("Scanned code:", resultString);
      if (!resultString) {
          console.error("No barcode detected");
          return;
      }
    
      if (this.scanStart) {
          this.spareForm.get('otherSparePart').setValue(resultString);
      }
    
      let audio = new Audio("assets/mp3/beep-02.mp3");
      audio.load();
      audio.play();
    
      this.spinner.show();
      setTimeout(() => {
          this.spinner.hide();
          this.closeScanner();
      }, 2000);
    }
    
    onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
    }
    onDeviceSelectChange(selected: string) {
    const device = this.availableDevices.find(x => x.deviceId === selected);
    this.currentDevice = device || null;
    }
    
    onHasPermission(has: boolean) {
    this.hasPermission = has;
    }
    
    onTorchCompatible(isCompatible: boolean): void {
    this.torchAvailable$.next(isCompatible || false);
    }
    
    toggleTorch(): void {
    this.torchEnabled = !this.torchEnabled;
    }
    
    toggleTryHarder(): void {
    this.tryHarder = !this.tryHarder;
    }
    
    closeScanner() {
    this.scanStart = false;
    this.helperservice.hideModal("barcodeScan");
    }
}


export interface UserDetailsInterface {
    description: string;
    createdBy: any;
    recordId: any;
  }