import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { HelperService } from 'app/helper.service';
import { HttpService } from 'app/shared/services/http.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserDetailsInterface } from '../special-projects.component';

@Component({
  selector: 'scrap-project',
  templateUrl: './scrap-project.component.html',
  styleUrls: ['./scrap-project.component.scss']
})
export class ScrapProjectComponent implements OnInit {

  @ViewChild('userDform', {static:true}) form;
  scrapProjectForm: FormGroup;
  firstParam: any;
  projectName:any = "";
  responseMessage: any;
  scrapList: any = [];
  count = 1;
  scrapRecordDetails: any;
  scrapRecordId: any;
  scrapRecordSoId: any;
  recordComments: any;   
  commentCreationDate: any;
  newComment: string = '';
  userName: any;
  recordCount: any;
    totalCount: any;
    category: any;

  displayedColumns = [
      "recordId",
      "soId",
      "projectName",
      "startTime"
  ];
  dataSource: MatTableDataSource<UserDetailsInterface>;
  commentdataSource: MatTableDataSource<any>;

  constructor(private route: ActivatedRoute, private fb: FormBuilder, private httpService: HttpService, private spinner: NgxSpinnerService, private helperservice: HelperService){

  }

  ngOnInit() {
    this.firstParam = this.route.snapshot.queryParamMap.get('projectID');
    this.userName = localStorage.getItem('userName');
    this.getProjectDetails();    
    

    this.scrapProjectForm = this.fb.group({
      SONumber: ['', [Validators.required, Validators.pattern('^[0-9]*$')] ],
      comment: [''],
    });
    this.fetchScrapList();
    this.scrapProjectForm.reset();
  }

  getProjectDetails() {
    this.spinner.show();
    this.httpService.getRequest('specialProjectApi/v1/projectDetails?projectId=' + this.firstParam).subscribe(
      res => {
        this.spinner.hide();
        if(res['status'] === 'ERROR'){
          this.responseMessage = res['errorMessage'];
          this.helperservice.openStaticModal('ConfirmDialog');
          return;
        }
        this.projectName = res['projectDetails']['projectName'];
        this.category = res['projectDetails']['category'];
        this.recordCount = res['projectDetails']['recordCount'];
        this.totalCount = res['projectDetails']['totalQuantity'];
      },
      err => {
        // alert("Internal issue to fetch the information");
        this.spinner.hide();
        this.responseMessage = "Internal issue to fetch the information";
        this.helperservice.openStaticModal('ConfirmDialog');
      }
    );
  }

  scrapSubmitForm(formDirective: FormGroupDirective){
    if (!this.scrapProjectForm.valid) {
      this.helperservice.makeFormDirty(this.scrapProjectForm);
      return;
    } else {
      this.singleImageUpload1(formDirective);
    }
  }

  singleImageUpload1(formDirective) {
    this.spinner.show();

        var inspFrom = this.scrapProjectForm.value;
        let postData = {
          "projectId": this.firstParam,
          "soId": inspFrom.SONumber,
          "createdBy": this.userName,
          "comment": inspFrom.comment,
          "startTime": new Date().toISOString()
        };
        this.httpService.postRequest('scrapApi/v1/saveRecord', postData).subscribe(
          res => {
            this.spinner.hide();
            if (res[status] = 'SUCCESS') {
              this.responseMessage = res['response'];
              this.scrapProjectForm.reset();
              formDirective.resetForm();
              this.fetchScrapList();
              this.helperservice.openStaticModal('ConfirmDialog');
            } else {
              this.responseMessage = res['response'];
              this.helperservice.openStaticModal('ConfirmDialog');
            }
          },
          err => {
            this.spinner.hide();
            // console.log("error block");
            this.responseMessage = "Error occurred while saving the record.";
            this.helperservice.openStaticModal('ConfirmDialog');
          }
        );
      }

  closePopup() {
        this.helperservice.hideModal('ConfirmDialog');
      }
  closeRecordModal() {
        this.helperservice.hideModal("seeRecord-Dialog");
        this.fetchScrapList()
    }

  fetchScrapList() {
        this.spinner.show();
        this.httpService
            .getRequest("scrapApi/v1/getOpenRecordsPageForTechnician?projectId="+this.firstParam+"&createdBy="+this.userName+"&pageNo="+this.count+"&pageSize=50")
            .subscribe(
                (res) => {
                    this.spinner.hide();
                    this.scrapList = res["response"];
                    this.dataSource =
                        new MatTableDataSource<UserDetailsInterface>(
                            this.scrapList
                        );
                        this.scrapList = [];
                },
                err => {
                  // alert("Internal issue to fetch the information");
                  this.spinner.hide();
                  this.responseMessage = "Internal issue to fetch the information";
                  this.helperservice.openStaticModal('ConfirmDialog');
                }
            );
    }

    nextRecords(){
      this.count++;
      if(this.count >= 0){
          this.spinner.show();
          this.httpService
              .getRequest("scrapApi/v1/getOpenRecordsPageForTechnician?projectId="+this.firstParam+"&createdBy=test&pageNo="+this.count+"&pageSize=50")
              .subscribe(
                  (res) => {
                      this.spinner.hide();
                      if(res["response"].length == 0){
                          // alert("No more records");
                          this.responseMessage = "No more records";
                          this.helperservice.openStaticModal('ConfirmDialog');
                      } else {
                        this.scrapList = res["response"];
                        this.dataSource =
                            new MatTableDataSource<UserDetailsInterface>(
                                this.scrapList
                            );
                            this.scrapList = [];
                      }
                  },
                  err => {
                    // alert("Internal issue to fetch the information");
                    this.spinner.hide();
                    this.responseMessage = err.response;
                    this.helperservice.openStaticModal('ConfirmDialog');
                  }
              );
      } else {
          // alert();
          this.responseMessage = "Internal issue to fetch the information";
          this.helperservice.openStaticModal('ConfirmDialog');
      }
  
    }
  
    previousRecords(){
      this.count--;
      if(this.count <= 0){
          // alert("No less records");
          this.responseMessage = "Page number can not be less than 0.";
          this.helperservice.openStaticModal('ConfirmDialog');
      } else {
          this.spinner.show();
          this.httpService
              .getRequest("scrapApi/v1/getOpenRecordsPageForTechnician?projectId="+this.firstParam+"&createdBy=test&pageNo="+this.count+"&pageSize=50")
              .subscribe(
                  (res) => {
                      this.spinner.hide();
                      if(res["response"].length == 0){
                          // alert("No more records");
                          this.responseMessage = "No more records";
                          this.helperservice.openStaticModal('ConfirmDialog');
                      } else {
                        this.scrapList = res["response"];
                        this.dataSource =
                            new MatTableDataSource<UserDetailsInterface>(
                                this.scrapList
                            );
                            this.scrapList = [];
                      }
      
                  },
                  err => {
                    // alert("Internal issue to fetch the information");
                    this.spinner.hide();
                    this.responseMessage = "Internal issue to fetch the information";
                    this.helperservice.openStaticModal('ConfirmDialog');
                  }
              );
      }
  
    }
    
    getScrapRecordDetails(recordId) {
      this.spinner.show();
      this.httpService
          .getRequest("scrapApi/v1/getRecordDetails?id=" + recordId)
          .subscribe(
              (res) => {
                  this.spinner.hide();
                  this.scrapRecordDetails = res["response"];
                  this.scrapRecordId =this.scrapRecordDetails["id"];
                  this.scrapRecordSoId = this.scrapRecordDetails["soId"];
                  this.recordComments = this.scrapRecordDetails["comments"];
                  this.helperservice.openStaticModal("seeRecord-Dialog");
              },
              err => {
                // alert("Internal issue to fetch the information");
                this.spinner.hide();
                this.responseMessage = "Internal issue to fetch the information";
                this.helperservice.openStaticModal('ConfirmDialog');
              }
          );
  }

  updateScrapRecordDetails(){
    this.spinner.show();
    if (!this.newComment.trim()) {
      // alert('Comment cannot be empty!');
      this.spinner.hide();
      this.closeRecordModal()
      this.responseMessage = "Comment cannot be empty!";
      this.helperservice.openStaticModal('ConfirmDialog');
      return;
    }
        let postData = {
          "recordId": this.scrapRecordId,
          "comment": this.newComment,
        };
        this.httpService.postRequest('scrapApi/v1/addComment', postData).subscribe(
          res => {
            this.spinner.hide();
            if (res[status] = 'SUCCESS') {
              this.responseMessage = res['response'];
              this.newComment = '';
              this.closeRecordModal()
              this.helperservice.openStaticModal('ConfirmDialog');
            } else {
              this.responseMessage = res['response'];
              this.helperservice.openStaticModal('ConfirmDialog');
              }
          },
          err => {
            // alert("Internal issue to fetch the information");
            this.spinner.hide();
            this.responseMessage = "Internal issue to fetch the information";
            this.helperservice.openStaticModal('ConfirmDialog');
          }
        );

  }

  closeScrapRecord(){
    this.spinner.show();
        let postData = {
          "recordId": this.scrapRecordId,
          "endTime": new Date().toISOString()
        };
        this.httpService.postRequest('scrapApi/v1/closeRecord', postData).subscribe(
          res => {
            this.spinner.hide();
            if (res[status] = 'SUCCESS') {
              this.responseMessage = res['response'];
              this.closeRecordModal()
              this.helperservice.openStaticModal('ConfirmDialog');
            } else {
              this.responseMessage = res['response'];
              this.helperservice.openStaticModal('ConfirmDialog');
              }
          },
          err => {
            // alert("Internal issue to fetch the information");
            this.spinner.hide();
            this.responseMessage = "Internal issue to fetch the information";
            this.helperservice.openStaticModal('ConfirmDialog');
          }
        );

  }

}
