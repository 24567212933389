import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { HttpService } from "../../shared/services/http.service";
import { NgxSpinnerService } from "ngx-spinner";
import { HelperService } from 'app/helper.service';
import { BehaviorSubject } from 'rxjs';
import { BarcodeFormat } from '@zxing/library';


@Component({
  selector: 'damaged-packing-inspection',
  templateUrl: './damaged-packing-inspection.component.html',
  styleUrls: ['./damaged-packing-inspection.component.scss'],
})
export class DamagedPackingInspectionComponent implements OnInit {

  @ViewChild('userDform', { static: true }) form;
  damagedPackingInspectionForm: FormGroup;
  isChecked: boolean = true;
  showClassification: boolean = false;
  filesList: any = [];
  firstParam: any;
  projectItemCodes: any;
  responseMessage: any;
  classificationValueList: any = [];
  allEvaluationTypeList: any = [];
  filteredEvaluationTypeList: any = [];
  evaluationTypeList: any = [];
  qualityCheckOkStatus: boolean = true;
  showMixerZoneValues: boolean = false;
  showMixerZone: boolean = false;
  showProblemFixed: boolean = false;
  mixerzoneValues = [];
  categoryCode: any = "";
  projectName: any = "";
  fileSizeError: string = '';
  scanStart:boolean=false; 
    scanSerialStart: boolean = false;
    scanItemCodeStart:boolean = false;
    qrResultString: string;
    availableDevices: MediaDeviceInfo[];
    hasDevices: boolean;
    qrResults: any=[];
    currentDevice: MediaDeviceInfo = null;
    hasPermission: boolean;
    torchAvailable$ = new BehaviorSubject<boolean>(false);
    torchEnabled = false;
    tryHarder = false;
    recordCount: any;
    totalCount: any;
    category: any;
  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private httpService: HttpService,
    private spinner: NgxSpinnerService,
    private helperservice: HelperService
  ) { }

  ngOnInit() {
    this.firstParam = this.route.snapshot.queryParamMap.get('projectID');
    this.classificationValueList = [];
    this.getProjectDetails();
    this.getClassifications();
    this.getMixerZoneValues();
    this.getEvaluationTypes();

    this.damagedPackingInspectionForm = this.fb.group({
      itemcode: ['', [Validators.required]],
      holderNumber: ['', [Validators.required, Validators.maxLength(20), Validators.minLength(9)]],
      serialNumber: [''],
      qualityCheckStatus: ['OK', [Validators.required]],
      classification: [''],
      // mixerzone: [''],
      mixerzone: [[]],
      description: [''],
      evaluationType: ['', [Validators.required]], 
      problem: ['NO']
    });

    // this.damagedPackingInspectionForm.reset();
    this.damagedPackingInspectionForm.patchValue({
      qualityCheckStatus: 'OK',
      problem: 'NO'
    });

    this.categoryCode = "";
  }

  getProjectDetails() {
    this.spinner.show();
    this.httpService.getRequest(`specialProjectApi/v1/projectDetails?projectId=${this.firstParam}`).subscribe(
      res => {
        this.spinner.hide();
        if (res['status'] === 'ERROR') {
          this.responseMessage = res['errorMessage'];
          this.helperservice.openStaticModal('ConfirmDialog');
          return;
        }
        this.projectItemCodes = res['projectDetails']['projectItemCodeMappingDtoList'];
        this.categoryCode = res['projectDetails']['categoryCode'];
        this.category = res['projectDetails']['category'];
        this.recordCount = res['projectDetails']['recordCount'];
        this.totalCount = res['projectDetails']['totalQuantity'];
        this.projectName = res['projectDetails']['projectName'];
        this.showMixerZoneValues = this.categoryCode === "F89" || this.categoryCode === "G11";
      },
      err => {
        this.spinner.hide();
        this.responseMessage = "Internal issue to fetch the information";
        this.helperservice.openStaticModal('ConfirmDialog');
      }
    );
  }

  getClassifications() {
    this.httpService.getRequest('specialProjectApi/v1/getClassificationForTechnicians').subscribe(
      res => {
        this.classificationValueList = res['items'];
      },
      err => {
        this.responseMessage = "No classification found.";
        this.helperservice.openStaticModal('ConfirmDialog');
      }
    );
  }

  getMixerZoneValues() {
    this.httpService.getRequest('specialProjectApi/v1/getMixerZones').subscribe(
      res => {
        this.mixerzoneValues = res['items'];
      },
      err => {
        this.responseMessage = "No classification found.";
        this.helperservice.openStaticModal('ConfirmDialog');
      }
    );
  }

  getEvaluationTypes() {
    this.httpService.getRequest('/specialProjectApi/v1/getAllEvaluationTypes').subscribe(
      res => {
        res['items'].forEach((obj) => {
          this.allEvaluationTypeList.push(obj.evaluationTypeString);
          if (obj.enableForQualityCheckOk) {
            this.filteredEvaluationTypeList.push(obj.evaluationTypeString);
          }
        });
        this.changeEvaluationTypeList();
      },
      err => {
        this.responseMessage = "Error in getting evaluation types.";
        this.helperservice.openStaticModal('ConfirmDialog');
      }
    );
  }

  damagedPackingInspectionSubmitForm(formDirective: FormGroupDirective) {
    if (!this.damagedPackingInspectionForm.valid) {
      this.helperservice.makeFormDirty(this.damagedPackingInspectionForm);
      return;
    }

    const fd = new FormData();
    fd.append('userId', 'ISA_SPECIAL_PROJECT');

    if (this.filesList.length > 0) {
      for (let file of this.filesList) {
        fd.append('files', file);
      }
      this.uploadFiles(fd, formDirective);
    } else {
      this.saveDamagedPackingInspectionRecord([], formDirective);
    }
  }

  uploadImage(event: any) {
    var files = event.target.files;
    console.log(files);

    this.fileSizeError = '';

    for (var i = 0; i < files.length; i++) {
        var f = files[i];
        if (f.size > 5 * 1024 * 1024) {
            this.fileSizeError = `File ${f.name} exceeds the 5MB size limit.`;
            continue;
        }

        f.mediaSRC = URL.createObjectURL(f);
        this.filesList.push(f);
        console.log(this.filesList);
    }
}

  closePopup() {
    this.helperservice.hideModal('ConfirmDialog');
  }

  uploadFiles(data: FormData, formDirective: FormGroupDirective) {
    this.spinner.show();
    this.httpService.uploadImageRequest('specialProject/uploadFiles', data,"DAMAGED_PACKING_INSPECTION").subscribe(
      res => {
        this.saveDamagedPackingInspectionRecord(res['fileUrl'], formDirective);
      },
      err => {
        this.spinner.hide();
        this.responseMessage = "Error occurred during file upload.";
        this.helperservice.openStaticModal('ConfirmDialog');
      }
    );
  }

  saveDamagedPackingInspectionRecord(fileUrls: string[], formDirective: FormGroupDirective) {
    this.spinner.show();
    const formData = this.damagedPackingInspectionForm.value;
    const currentHolder = formData.holderNumber;
    let postData = {
      projectId: this.firstParam,
      itemCode: formData.itemcode,
      holderNumber: formData.holderNumber,
      serialNumber: formData.serialNumber,
      quality: formData.qualityCheckStatus,
      description: formData.description,
      zoneList: Array.isArray(formData.mixerzone) ? formData.mixerzone : [],
      // zoneList:  formData.mixerzone ,
      problemFixedStatus: formData.problem,
      createdBy: localStorage.getItem('userName'),
      fileUrls: fileUrls,
      evaluationType: formData.evaluationType
    };

    if (formData.classification) {
      postData["classification"] = formData.classification;
    }

    this.httpService.postRequest('damagedPackingInspApi/v1/saveDamagedPackingInspectionRecord', postData).subscribe(
      res => {
        this.spinner.hide();
        if (res['status'] === 'SUCCESS') {
          this.responseMessage = res['response'];
          // this.damagedPackingInspectionForm.reset();
          // formDirective.resetForm();
          const defaultValues = {
            itemcode: '',
            holderNumber: currentHolder, // retain the current holder number
            serialNumber: '',
            qualityCheckStatus: 'OK',
            classification: '',
            mixerzone: [],
            description: '',
            evaluationType: '',
            problem: 'NO'
          };
          this.damagedPackingInspectionForm.reset(defaultValues);
          formDirective.resetForm(defaultValues);
          this.showClassification = false;
          this.showProblemFixed = false;
          this.filesList = [];
          const fileInput = document.getElementById("chooseFile") as HTMLInputElement;
                                    if (fileInput) {
                                        fileInput.value = "";
                                    }
          this.showMixerZone = false;
          this.helperservice.openStaticModal('ConfirmDialog');
        } else {
          this.responseMessage = res['response'];
          this.helperservice.openStaticModal('ConfirmDialog');
        }
      },
      err => {
        this.spinner.hide();
        this.responseMessage = "Error occurred while saving the record.";
        this.helperservice.openStaticModal('ConfirmDialog');
      }
    );
  }

  changeQualityCheckStatusToOK() {
    this.qualityCheckOkStatus = true;
    this.showClassification = false;
    this.showProblemFixed = false;
    this.showMixerZone = false;
    this.changeEvaluationTypeList();

    if (this.filteredEvaluationTypeList.length > 0) {
      this.damagedPackingInspectionForm.controls['evaluationType'].setValue(this.filteredEvaluationTypeList[0]);
  }
  // this.showMixerZoneValues = false;
  this.mixerzoneValues = [];
  }

  changeQualityCheckStatusToNotOK() {
    this.qualityCheckOkStatus = false;
    this.showClassification = true;
    this.showProblemFixed=  true;
    this.changeEvaluationTypeList();
    this.showMixerZone = this.categoryCode === 'F89' || this.categoryCode === 'G11';
    if(this.showMixerZone){
      this.getMixerZoneValues();
    }
  }


  changeEvaluationTypeList() {
    const currentItemCode = this.damagedPackingInspectionForm.get('itemcode').value;

    this.evaluationTypeList = this.qualityCheckOkStatus
        ? this.filteredEvaluationTypeList
        : this.allEvaluationTypeList;

    if (currentItemCode) {
        this.damagedPackingInspectionForm.controls['itemcode'].setValue(currentItemCode, { emitEvent: false });
    }

    if (this.qualityCheckOkStatus && this.evaluationTypeList.length > 0) {
      this.damagedPackingInspectionForm.controls['evaluationType'].setValue(this.evaluationTypeList[0]);
  }
}

onEvaluationTypeChange() {
  // Preserve item code after evaluation type selection
  const currentItemCode = this.damagedPackingInspectionForm.get('itemcode').value;

  if (currentItemCode) {
      this.damagedPackingInspectionForm.controls['itemcode'].setValue(currentItemCode, { emitEvent: false });
  }
}

onCodeResult(resultString: string) {
  console.log("Scanned code:", resultString);
  if (!resultString) {
      console.error("No barcode detected");
      return;
  }

  if (this.scanStart) {
      this.damagedPackingInspectionForm.get('holderNumber').setValue(resultString);
  } else if (this.scanSerialStart) {
      this.damagedPackingInspectionForm.get('serialNumber').setValue(resultString);
  }
  else if (this.scanItemCodeStart) {
    this.damagedPackingInspectionForm.get('itemcode').setValue(resultString);
}

  let audio = new Audio("assets/mp3/beep-02.mp3");
  audio.load();
  audio.play();

  this.spinner.show();
  setTimeout(() => {
      this.spinner.hide();
      this.closeScanner();
  }, 2000);
}

onCamerasFound(devices: MediaDeviceInfo[]): void {
this.availableDevices = devices;
this.hasDevices = Boolean(devices && devices.length);
}
onDeviceSelectChange(selected: string) {
const device = this.availableDevices.find(x => x.deviceId === selected);
this.currentDevice = device || null;
}

onHasPermission(has: boolean) {
this.hasPermission = has;
}

onTorchCompatible(isCompatible: boolean): void {
this.torchAvailable$.next(isCompatible || false);
}

toggleTorch(): void {
this.torchEnabled = !this.torchEnabled;
}

toggleTryHarder(): void {
this.tryHarder = !this.tryHarder;
}

closeScanner() {
this.scanStart = false;
this.scanSerialStart = false;
this.scanItemCodeStart=false
this.helperservice.hideModal("barcodeScan");
} 
}
