import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { HttpModule } from '@angular/http';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import 'hammerjs';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { fuseConfig } from 'app/fuse-config';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { AppRoutingModule } from 'app/app-routing.module'
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { RepairRefurbComponent } from './repair-refurb/repair-refurb.component';
import { ProfileComponent } from './profile/profile.component';
import { CheckInComponent } from './check-in/check-in.component';
import { CheckOutComponent } from './check-out/check-out.component';
import { RepairInfoComponent } from './repair-info/repair-info.component';
import { RefurbInfoComponent } from './refurb-info/refurb-info.component';
import { CheckInRefurbComponent } from './check-in-refurb/check-in-refurb.component';
import { CheckInRepairComponent } from './check-in-repair/check-in-repair.component';
import { CheckOutReportComponent } from './check-out-report/check-out-report.component';
import { CheckOutPrintComponent} from './check-out-print/check-out-print.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { NgxTimerModule } from 'ngx-timer';
import { NgxSpinnerModule } from "ngx-spinner";
import {MatMenuModule} from '@angular/material/menu';
import {WebcamModule} from 'ngx-webcam';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { MatListModule } from '@angular/material/list';
import {MomentTimezoneModule} from 'angular-moment-timezone';
// import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {MatFormFieldModule} from '@angular/material/form-field';
// import { MatInputModule } from '@angular/material';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCardModule} from '@angular/material/card';
import { MatTableExporterModule } from 'mat-table-exporter';

// Services
import { HelperService } from './helper.service';
import { HttpService } from './shared/services/http.service';
import { SessionHandler } from './shared/services/session-handler.service';
import { CommonService } from './shared/services/common.service';
import { ScrapComponent } from './scrap/scrap.component';
import { HealthcheckInfoComponent } from './healthcheck-info/healthcheck-info.component';
import { LoggingComponent } from './logging/logging.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule, MatSortModule, MatDatepickerModule} from '@angular/material';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { AuthGuard } from './auth/auth.guard';
import { TraysComponent, DialogOverviewExampleDialog, AddSocDialog } from './trays/trays.component';
import { ExtensiverepairInfoComponent } from './extensiverepair-info/extensiverepair-info.component';
import { DialogComponent, ConfirmDialogComponent, ConfirmationDialogComponent,WarningDialogComponent,ScrapDialogComponent, CompanyDialog} from './dialog/dialog.component';
import { CommonComponent } from './common/common.component';
import { SafePipe } from './shared/pipe/trusty.pipe';

import { AuthInterceptor } from './auth/auth.interceptor';
import { SocCreateManuallyComponent } from './soc-create-manually/soc-create-manually.component';
import { TraysLogsComponent } from './trays-logs/trays-logs.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { DashboardComponent, FilterDialogComponent,SelectCheckAllComponent } from './dashboard/dashboard.component';
import { ManagerReportComponent } from './manager-report/manager-report.component';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MissingSpareFilterComponent, MissingSparesReportComponent,MissingSpareUpdateComponent } from './missing-spares-report/missing-spares-report.component';
import {MatTreeModule} from '@angular/material/tree';
import { SplitPipe } from './shared/pipe/split.pipe';
import { UserManagementComponent } from './user-management/user-management.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutofocusDirective } from './shared/directives/autofocus.directive';
import { SpecialProjectsComponent } from './special-projects/special-projects.component';
import { OtiRepairComponent } from './special-projects/oti-repair/oti-repair.component';
import { SafeLaunchComponent } from './special-projects/safe-launch/safe-launch.component';
import { OtiChecksComponent } from './special-projects/oti-checks/oti-checks.component';
import { StandardInspectionComponent } from './special-projects/standard-inspection/standard-inspection.component';
import { DamagedPackingInspectionComponent } from './special-projects/damaged-packing-inspection/damaged-packing-inspection.component';
import { ReplugComponent } from './special-projects/replug/replug.component';
import { SparePartsComponent } from './special-projects/spare-parts/spare-parts.component';
import { ScrapProjectComponent } from './special-projects/scrap-project/scrap-project.component';
import { ReworkComponent } from './special-projects/rework/rework.component';


const appRoutes: Routes = [];

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        SidenavComponent,
        RepairRefurbComponent,
        ProfileComponent,
        CheckInComponent,
        CheckOutComponent,
        RepairInfoComponent,
        RefurbInfoComponent,
        CheckInRefurbComponent,
        CheckInRepairComponent,
        CheckOutReportComponent,
        CheckOutPrintComponent,
        ScrapComponent,
        HealthcheckInfoComponent,
        LoggingComponent,
        TraysComponent,
        DialogOverviewExampleDialog,
        ExtensiverepairInfoComponent,
        DialogComponent,
        CommonComponent,
        AddSocDialog,
        ConfirmDialogComponent,
        ConfirmationDialogComponent,
        WarningDialogComponent,
        ScrapDialogComponent,
        CompanyDialog,
        FilterDialogComponent,
        SafePipe,
        SocCreateManuallyComponent,
        TraysLogsComponent,
        DashboardComponent,
        SelectCheckAllComponent,
        ManagerReportComponent,
        MissingSparesReportComponent,
        SplitPipe,
        MissingSpareFilterComponent,
        MissingSpareUpdateComponent,
        UserManagementComponent,
        AutofocusDirective,
        SpecialProjectsComponent,
        OtiRepairComponent,
        SafeLaunchComponent,
        OtiChecksComponent,
        StandardInspectionComponent,
        DamagedPackingInspectionComponent,
        ReplugComponent,
        SparePartsComponent,
        ScrapProjectComponent,
        ReworkComponent,
    ],
    entryComponents: [DialogOverviewExampleDialog,DialogComponent,AddSocDialog,ConfirmDialogComponent,ConfirmationDialogComponent,WarningDialogComponent,ScrapDialogComponent,CompanyDialog,FilterDialogComponent,MissingSpareFilterComponent,MissingSpareUpdateComponent],
   
    imports : [
        FormsModule,
        CdkTreeModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpModule,
        RouterModule.forRoot(appRoutes),
        MatTooltipModule,
        TranslateModule.forRoot(),
        MatSnackBarModule,
        // Material moment date module
        MatMomentDateModule,
        MatExpansionModule,
        MatRadioModule,
        MatFormFieldModule,
        // Material
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        MatTabsModule,
        MatSelectModule,
        MatInputModule,
        MatAutocompleteModule,
        MatMenuModule,
        MatListModule,
        MatCardModule,
        MatSortModule,
        // NgxMatSelectSearchModule,
        MatDialogModule,
        MatTableModule,
        MatPaginatorModule,
        //Mat Table Export Module to export tabel in csv
        MatTableExporterModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        SampleModule,
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        NgxTimerModule,
        NgxSpinnerModule,
        WebcamModule,
        DeviceDetectorModule.forRoot(),
        //ngx scanner
        ZXingScannerModule,
        //Moment timezone module
        MomentTimezoneModule,

        MatProgressSpinnerModule,
        NgxExtendedPdfViewerModule,
        BsDatepickerModule.forRoot(),
        MatTreeModule,
        ReactiveFormsModule,
        MatDatepickerModule
    ],
    
    providers:[HttpService,SessionHandler,CommonService,HelperService,AuthGuard,SelectCheckAllComponent,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
    constructor(){
         //console.log=function(){}
        }
    
}
  