import {RouterModule, Routes} from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RepairRefurbComponent } from './repair-refurb/repair-refurb.component';
import { ProfileComponent } from './profile/profile.component';
import { CheckInComponent } from './check-in/check-in.component';
import { CheckOutComponent } from './check-out/check-out.component';
import { CheckOutReportComponent } from './check-out-report/check-out-report.component';
import { CheckOutPrintComponent } from './check-out-print/check-out-print.component';
import { RepairInfoComponent } from './repair-info/repair-info.component';
import { RefurbInfoComponent } from './refurb-info/refurb-info.component';
import { CheckInRepairComponent } from './check-in-repair/check-in-repair.component';
import { CheckInRefurbComponent } from './check-in-refurb/check-in-refurb.component';
import { ScrapComponent } from './scrap/scrap.component';
import { HealthcheckInfoComponent } from './healthcheck-info/healthcheck-info.component';
import { LoggingComponent } from './logging/logging.component';
import { AuthGuard } from './auth/auth.guard';
import { TraysComponent} from './trays/trays.component';
import { ExtensiverepairInfoComponent } from './extensiverepair-info/extensiverepair-info.component';
import { CommonComponent } from './common/common.component';
import { SocCreateManuallyComponent } from './soc-create-manually/soc-create-manually.component';
import { TraysLogsComponent } from './trays-logs/trays-logs.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ManagerReportComponent } from './manager-report/manager-report.component';
import { MissingSparesReportComponent } from './missing-spares-report/missing-spares-report.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { SpecialProjectsComponent } from './special-projects/special-projects.component';
import { OtiRepairComponent } from './special-projects/oti-repair/oti-repair.component';
import { SafeLaunchComponent } from './special-projects/safe-launch/safe-launch.component';
import { OtiChecksComponent } from './special-projects/oti-checks/oti-checks.component';
import { StandardInspectionComponent } from './special-projects/standard-inspection/standard-inspection.component';
import { DamagedPackingInspectionComponent } from './special-projects/damaged-packing-inspection/damaged-packing-inspection.component';
import { ReplugComponent } from './special-projects/replug/replug.component';
import { SparePartsComponent } from './special-projects/spare-parts/spare-parts.component';
import { ScrapProjectComponent } from './special-projects/scrap-project/scrap-project.component';
import { ReworkComponent } from './special-projects/rework/rework.component';

const AppRoutes: Routes = [

  { path: '', pathMatch: 'full', redirectTo: '/login' },
  { path: 'login', component: LoginComponent },
  { path: 'repair', component: RepairRefurbComponent,canActivate :[AuthGuard] },
  { path: 'profile', component: ProfileComponent ,canActivate :[AuthGuard] },
  { path: 'checkin', component: CheckInComponent ,canActivate :[AuthGuard] },
  { path: 'checkout', component: CheckOutComponent ,canActivate :[AuthGuard] },
  { path: 'checkout-report', component: CheckOutReportComponent ,canActivate :[AuthGuard] },
  { path: 'checkout-print', component: CheckOutPrintComponent ,canActivate :[AuthGuard] },
  { path: 'repair-info', component: RepairInfoComponent ,canActivate :[AuthGuard] },
  { path: 'refurb-info', component: RefurbInfoComponent ,canActivate :[AuthGuard] },
  { path: 'check-in-repair', component: CheckInRepairComponent ,canActivate :[AuthGuard] },
  { path: 'check-in-refurb', component: CheckInRefurbComponent ,canActivate :[AuthGuard] },
  { path: 'scrap', component: ScrapComponent ,canActivate :[AuthGuard] },
  { path: 'healthcheck-info', component: HealthcheckInfoComponent ,canActivate :[AuthGuard] },
  { path: 'trayslogs', component: TraysLogsComponent ,canActivate :[AuthGuard] },
  { path: 'logs', component: LoggingComponent ,canActivate :[AuthGuard] },
  { path: 'trays', component: TraysComponent ,canActivate :[AuthGuard] },
  { path: 'extensiverepair-info', component: ExtensiverepairInfoComponent ,canActivate :[AuthGuard] },
  { path: 'soc-creation', component: SocCreateManuallyComponent ,canActivate :[AuthGuard] },
  { path: 'common', component: CommonComponent ,canActivate :[AuthGuard] },
  { path: 'dashboard', component: DashboardComponent ,canActivate :[AuthGuard] },
  { path: 'return_dashboard', component: DashboardComponent ,canActivate :[AuthGuard] },
  { path: 'mreport', component: ManagerReportComponent ,canActivate :[AuthGuard] },
  { path: 'missing-spares-report', component: MissingSparesReportComponent ,canActivate :[AuthGuard] },
  { path: 'mreport', component: ManagerReportComponent ,canActivate :[AuthGuard] } ,
  { path: 'user_management', component: UserManagementComponent ,canActivate :[AuthGuard] },
  { path: 'special_projects', component: SpecialProjectsComponent ,canActivate :[AuthGuard]},
  { path: 'oti-repair', component: OtiRepairComponent ,canActivate :[AuthGuard]},
  { path: 'safe-launch', component: SafeLaunchComponent ,canActivate :[AuthGuard]},
  { path: 'oti-checks', component: OtiChecksComponent ,canActivate :[AuthGuard]},
  { path: 'standard-inspection', component: StandardInspectionComponent ,canActivate :[AuthGuard]},
  { path: 'damaged-packing-inspection', component: DamagedPackingInspectionComponent ,canActivate :[AuthGuard]},
  { path: 'replug', component: ReplugComponent ,canActivate :[AuthGuard]},
  { path: 'scrap-project', component: ScrapProjectComponent ,canActivate :[AuthGuard]},
  { path: 'spare-part', component: SparePartsComponent ,canActivate :[AuthGuard]},
  { path: 'rework', component: ReworkComponent ,canActivate :[AuthGuard]}
];

export const AppRoutingModule = RouterModule.forRoot(AppRoutes, {useHash: true});
