import { Component, OnInit, ViewChild } from '@angular/core';
import { HelperService } from './../helper.service';
import { Router } from '@angular/router';
import { HttpService } from "../shared/services/http.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ZebraPrintService } from '../shared/services/zebra-print.service';
import { map, startWith } from 'rxjs/operators';
import { ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Subject, ReplaySubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { PlatformLocation } from '@angular/common';
import { APPCONFIG } from '../config';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment-timezone';
import { CountupTimerService, countUpTimerConfigModel, timerTexts } from 'ngx-timer';
import { DialogComponent, ConfirmDialogComponent, ConfirmationDialogComponent,WarningDialogComponent } from '../dialog/dialog.component'
import { CommonService } from 'app/shared/services/common.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-check-out-report',
  templateUrl: './check-out-report.component.html',
  styleUrls: ['./check-out-report.component.scss']
})

export class CheckOutReportComponent implements OnInit {
  @ViewChild('showFocus', { static: false }) upsFocus;

  color = 'primary';
  mode = 'determinate';
  value = 50;
  icon: boolean = false;
  iconexist: boolean = false;
  iconTogl: boolean = false;
  ClosedAction: any;
  pdfDownload:any=[];
  workReport:any=[];
  actionPerformed: any;
  IS_4TH:boolean=false;
  D2C:boolean=false;
  holder: any;
  packing: any;
  notPauseApi: boolean=false;
  permissions: any;
  isDoc: boolean;
  showDocuments:any= [];
  result:any=[];
  temp:any=[];
  arr:any=[];
  imgUrl: any;
  docLanguage: any;
  docName: any;
  CheckCountry: any;
  showCHFlag: boolean;
  porformaData: any;
  porformaURL: any;

  click() {
    this.icon = !this.icon;
  }

  clickTogl() {
    this.iconTogl = !this.iconTogl;
  }
  textForm: FormGroup;
  trayNo: String = '';
  type: String = '';
  itemDetails: any;
  processStatus: String = '';
  sapResponse: any;
  repairDetails: any;
  date: string;
  productId: any;
  trayFreeErrorMsg: string = '';
  confirmMsg: string = '';
  trayFreeError: boolean = false;
  languageData: any = [];
  langForm: FormGroup;
  languageListError: boolean = false;
  languageListErrorMsg: string = '';
  // printerListForm: FormGroup;
  // printerListData: any = [];
  // printerListError: boolean = false;
  // printerListErrorMsg: string = '';
  showingMediaErrorMsg: any;
  showingmediaError: boolean;
  showDocument: any = [];
  callLightBox: boolean;
  printContents: string='';
  popupWin: any;
  showReport: boolean = false;
  showImage: boolean = false;
  showPDF: boolean = false;
  imageURL: string = '../assets/pdf/image.png';
  pdfURL: any;
  pdfMatSpinner: boolean = false;
  mobileFlag: boolean = false;
  socID: string = '';
  deviceInfo = null;
  isMobile: boolean;
  isTablet: boolean;
  isDesktopDevice: boolean;
  cameraOpen: boolean = false;
  userName: string = '';
  currentDate: Date;
  numFiles: any;
  filesList: any = [];
  showUploadedFiles: boolean = false;
  caoruseExist: boolean = false;
  isuploadError: boolean = false;
  uploadErrorMsg: String = "";
  showingAllmediaError: boolean;
  showingAllMediaErrorMsg: any;
  showAllDocument: any = [];
  socNum: any;
  soNum: any;
  sapEttexts: any;
  spareTextCode: any;
  NumberForUps:any;
  CheckoutLeadTime:any;
  zone: string = "";
   testConfig: countUpTimerConfigModel;
  //webcam variable start 
  // toggle webcam on/off
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: WebcamImage = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  //webcam end
  /*ngAfterViewInit() {
      this.printContents = document.getElementById('imageDiv').innerHTML;
      const originalContents = document.body.innerHTML;
  }*/

  userLDapResonseRoles: any;
  technicalRequest: any;
  uploadFlag: boolean = false;
  dialogref: any;
  selected;
  show4thChoiceLabel:boolean=false;
  constructor(private locationFORURL: Location,private commonService: CommonService, public sanitizer: DomSanitizer,private countupTimerService: CountupTimerService, private deviceService: DeviceDetectorService, private location: PlatformLocation, public fb: FormBuilder, private helperService: HelperService, private httpService: HttpService, private route: ActivatedRoute, private spinner: NgxSpinnerService, private router: Router,
      public dialog: MatDialog) {

    // this.userLDapResonseRoles = JSON.parse(localStorage.getItem('loggedUserRoles'));
    // if (this.userLDapResonseRoles.rolesMap.code.includes('TECH')
    //     ||  this.userLDapResonseRoles.rolesMap.code.includes('SA')
    //     ||  this.userLDapResonseRoles.rolesMap.code.includes('BA')) {
    // } else {

      // if (this.userLDapResonseRoles.rolesMap.code.includes('TECH')
      //   || this.userLDapResonseRoles.rolesMap.code.includes('SA')
      //   || this.userLDapResonseRoles.rolesMap.code.includes('BA')
      // ) {
      //   this.router.navigate(['/scrap']);
      // } else {
        // this.router.navigate(['/common']);
      //  }
    // }

    this.permissions = JSON.parse(localStorage.getItem('permissions'))
    let permissionFlag = false;
    if(this.permissions !== null)
    this.permissions['checkOutReport'].toLowerCase() == 'true'? permissionFlag = true : permissionFlag = false;
    if(!permissionFlag) {
      this.commonService.openSnackBar("Permission to access this page is restricted, contact Administrator", "Close"); 
      this.locationFORURL.back();
    }

    if (this.httpService.loginCheckSession()) { }
    else {
      this.httpService.showSessionError();

    }


    this.route.queryParams.subscribe(params => {
      this.trayNo = params['trayId'];
      this.soNum = params['soId'];
      this.socNum = params['socId'];
    });
    if ((this.trayNo == null || this.trayNo == undefined || this.trayNo == "") && (this.soNum == null || this.soNum == undefined || this.soNum == "") && (this.socNum == null || this.socNum == undefined || this.socNum == "")) {
      this.router.navigate(['checkout']);
      return;
    }

  }

  ngOnInit() {
    this.getDetails();
    this.langForm = this.fb.group({
      language: ['', [Validators.required]],
      comment: ['', []],
    });
    // this.printerListForm = this.fb.group({
    //   printer: ['', [Validators.required]],
    // });
    this.textForm = this.fb.group({
      tdid: ['', [Validators.required]],
      text: ['']
    });
    this.testConfig = new countUpTimerConfigModel();
    //this.testConfig.timerClass  = 'test_Timer_class';
    this.testConfig.timerTexts = new timerTexts();
    this.testConfig.timerTexts.hourText = " :"; //default - hh
    this.testConfig.timerTexts.minuteText = " :"; //default - mm
    this.testConfig.timerTexts.secondsText = " ";

    this.zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.languageDatalist();
    // this.printwerDatalist();
    if (typeof this.helperService.sapResponse != "undefined") {
      const resp = this.helperService.sapResponse['response'];
      this.repairDetails=resp.repairDetails;
      this.itemDetails=resp.itemDetails;
      this.sapResponse = resp.sapResponse;
      
      this.CheckoutLeadTime = resp.checkoutLeadTime;
      if (this.sapResponse) {
        var dt = this.sapResponse.esproductdetails.crmpurchasedate;
        this.socID = this.sapResponse.essocdata.socid;
        this.date = [dt.slice(0, 4), "-", dt.slice(4, 6), "-", dt.slice(6, 8)].join('');
        if (this.sapResponse.esproductdetails.refproduct) {
          this.productId = this.sapResponse.esproductdetails.refproduct.replace(/^0+/, '');
        }
         this.processStatus = this.CheckoutLeadTime && this.CheckoutLeadTime.status?this.CheckoutLeadTime.status:'';
        if (resp.trayId) {
          this.trayNo = resp.trayId;
        }
        let postData = {
          "transactionId": this.sapResponse.essocdata.socid,
        };
        this.NumberForUps =this.sapResponse.essocdata.socid;
        this.imageShow(postData);
        if (resp.repairDetails) {
          this.technicalRequest = resp.repairDetails.requestNo;
          let postData1 = {
            "transactionId": resp.repairDetails.requestNo, //technical request number
          };
          this.imageShowbyso(postData1);
          this.NumberForUps =resp.repairDetails.requestNo;
        }
      }
      if (this.sapResponse.ettexts) {
        this.sapEttexts = this.sapResponse.ettexts
        //  this.loadFilter();
      }
      this.startTimer(this.CheckoutLeadTime);
      
      this.setAction();
      this.spinner.hide();
     
    } else {
      this.getDetails();
      
    }

    this.userName = localStorage.getItem('userName');

  }
  // loadFilter() {
  //   this.spareTextCode = this.textForm.get('tdid').valueChanges
  // .pipe(
  //   startWith(''),
  //   map(value => this._filterText(value))
  // );
  // }

  // private _filterText(value: string): string[] {
  //   if ((value === undefined || value === null ) || (this.sapEttexts === undefined || this.sapEttexts === null ) ) 
  //   return ;
  //   const filterValue = value.toLowerCase();
  //   var data = this.sapEttexts.filter(option => String(option.tdid).toLowerCase().indexOf(filterValue) > -1 ||
  //     option.text.toLowerCase().indexOf(filterValue) > -1);
  //   return data
  // }
  // getDetails(){
  //   this.spinner.show();

  //   this.httpService.getRequest('checkInOperationApi/v1/getSpareItemByItemId?itemId='+this.itemId+"&type="+this.type)
  //  .subscribe(res=>{
  //   const resp=res['response'];
  //   this.itemDetails=resp.itemDetails;
  //   this.repairDetails=resp.repairDetails;      
  //   this.sapResponse=resp.sapResponse
  //   if(this.sapResponse){
  //     var dt=this.sapResponse.esproductdetails.crmpurchasedate;
  //     this.date = [dt.slice(0, 4), "-", dt.slice(4, 6), "-", dt.slice(6, 8)].join('');
  //   }
  //   this.spinner.hide();

  //  },err=>{

  //   this.spinner.hide();

  //  }) 
  // }



  print() {
    this.router.navigate(['checkout-print'], { queryParams: { trayId: this.trayNo } });
  }

  getDetails() {

    var newUrl = null;
    if (this.trayNo != null) {
      newUrl = 'checkOutOperationApi/v1/getDetailByTrayNO?trayNO=' + this.trayNo;
    }
    else if (this.socNum != null) {
      newUrl = 'checkOutOperationApi/v1/getSpareItemBySocNo?socNumber=' + this.socNum;
    } else if (this.soNum != null) {
      newUrl = 'checkOutOperationApi/v1/getSpareItemBySoNo?soNumber=' + this.soNum;
    } else {
      return;
    }
    this.spinner.show();
    this.httpService.getRequest(newUrl)
      .subscribe(res => {
        const resp = res['response'];
        if(res['status']=='ERROR'){
          this.spinner.hide();
          this.router.navigate(['checkout']);
        }else{   
        this.CheckCountry = resp.sapResponse.esbpaddress.country; 
        this.itemDetails=resp.itemDetails;
        this.sapResponse = resp.sapResponse;
        this.CheckoutLeadTime = resp.checkoutLeadTime;
        if (this.sapResponse) {
          var dt = this.sapResponse.esproductdetails.crmpurchasedate;
          this.socID = this.sapResponse.essocdata.socid;
          this.date = [dt.slice(0, 4), "-", dt.slice(4, 6), "-", dt.slice(6, 8)].join('');
          if (this.sapResponse.esproductdetails.refproduct) {
            this.productId = this.sapResponse.esproductdetails.refproduct.replace(/^0+/, '');
          }
          //  this.processStatus = resp.processDetail.status;
          let postData = {
            "transactionId": this.sapResponse.essocdata.socid,
          };
          this.imageShow(postData);
          this.NumberForUps =this.sapResponse.essocdata.socid;
          if (resp.trayId) {
            this.trayNo = resp.trayId;
          }
          if (resp.repairDetails) {
            let postData1 = {
              "transactionId": resp.repairDetails.requestNo, //technical request number
            };
            this.imageShowbyso(postData1);
            this.NumberForUps =resp.repairDetails.requestNo;
          }
        }
        this.startTimer(this.CheckoutLeadTime);

        if (this.sapResponse.ettexts) {
          this.sapEttexts = this.sapResponse.ettexts
          //  this.loadFilter();
        }
        this.setAction();
      }
        this.spinner.hide();

      }, err => {
        // alert("Invalid request");
        this.spinner.hide();

      })
  }

  startTimer(objCheckoutTime){
    if (objCheckoutTime) {
      var dateStr = objCheckoutTime.startTime;
      console.log(" Get Details Start Date  : "+dateStr);
      let dateJson = new Date(dateStr);
      var m1 = moment(dateJson).tz(this.zone).format();      // 2014-06-22T09:21:08-07:00
      let date1 = new Date(m1);
      let date2 = new Date();
      var duration = date2.getTime() - date1.getTime();
      let startDate = (new Date().getTime()) - duration;
      this.countupTimerService.startTimer(startDate);
    } else {
      this.countupTimerService.startTimer()
    }
  }
  clearTray() {
    if(this.CheckCountry == 'CH'){
      this.spinner.show();
      this.httpService.getRequest('checkOutOperationApi/v1/genrateProforma?socNO=' + this.sapResponse.essocdata.socid)
        .subscribe(
          res => {
            this.showCHFlag = true;
            this.spinner.hide();
            this.porformaData = res['serviceDocFileLink'];
            this.porformaURL = this.porformaData;
        }, err => {
          this.spinner.hide();
        })
    } else {
      this.spinner.show();
      this.httpService.getRequest('checkInOperationApi/v1/clearTray?trayId=' + this.trayNo+"&type="+"checkout")
        .subscribe(
          // this.httpService.postRequest('checkInOperationApi/v1/clearTray', postData).subscribe(
          res => {
          if (res['status'] == 'ERROR') {
            this.trayFreeErrorMsg = res['response'];
            this.confirmMsg = res['response'];
            this.helperService.openStaticModal('conform_Dialog');
          } else {
            this.spinner.hide();
            this.uploadFlag=true;
            this.uploadData();
            if(this.sapResponse.esbpaddress.email && this.sapResponse.esbpaddress.email !== ""){
            this.imageShowReport();
            }
            this.trayFreeError = false;
            this.helperService.openStaticModal('conform_Dialog');
            this.confirmMsg = "Request Submitted Successfully ";
            this.notPauseApi=true
          }
        }, err => {
          this.spinner.hide();
  
        })
    }

  }

  closeProformaDialogue(){
    this.showCHFlag = false;
    this.spinner.show();
    this.httpService.getRequest('checkInOperationApi/v1/clearTray?trayId=' + this.trayNo+"&type="+"checkout")
      .subscribe(
        // this.httpService.postRequest('checkInOperationApi/v1/clearTray', postData).subscribe(
        res => {
        if (res['status'] == 'ERROR') {
          this.trayFreeErrorMsg = res['response'];
          this.confirmMsg = res['response'];
          this.helperService.openStaticModal('conform_Dialog');
        } else {
          this.spinner.hide();
          this.uploadFlag=true;
          this.uploadData();
          if(this.sapResponse.esbpaddress.email && this.sapResponse.esbpaddress.email !== ""){
          this.imageShowReport();
          }
          this.trayFreeError = false;
          this.helperService.openStaticModal('conform_Dialog');
          this.confirmMsg = "Request Submitted Successfully ";
          this.notPauseApi=true
        }
      }, err => {
        this.spinner.hide();

      })
  }

  sendWorkReportViaEmail(postData){

    console.log(postData)
        this.httpService.postRequest('checkInOperationApi/v1/workReportEmail', postData).subscribe(
        res => {
        if (res['status'] == 'ERROR') {
          this.trayFreeErrorMsg = res['response'];
          this.confirmMsg = res['response'];
        } 
      }, err => {
      })
  }

  redirect(path) {
    window.location.href = path;
  }
  openInfoDialog(){
    if (!this.langForm.valid) {
      this.helperService.makeFormDirty(this.langForm);
      return;
    }
    var lang = this.langForm.value;
    if(lang.comment){
      let msg="The comment will be added to the work report. Are you sure to proceed.";
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true, 
        maxWidth: "400px",
        data: {
          message:msg
        }
      });
      dialogRef.afterClosed().subscribe(dialogResult => {
        console.log(dialogResult)
        if(dialogResult){
          this.generateReport('work_report');
        }
      });
    }else{
      this.generateReport('work_report');
    }
}
  generateReport(reportType) {
    
    if(reportType=='work_report'){
      if (!this.langForm.valid) {
        this.helperService.makeFormDirty(this.langForm);
        return;
      }
      this.urlblob = "assets\images\not-available.jpg";
      this.showPDF = true;
      this.pdfMatSpinner = true;
      let postDataUSb = {
        "transactionId": this.NumberForUps, //the SO Number, if present or the SOC Number, if a Standalone Document
        "attachmentType": "UPS_LABEL"
      };
      this.imageShowUsb(postDataUSb);
      this.showReport = true;
      var lang = this.langForm.value
      setTimeout(() => {
        this.upsFocus.nativeElement.scrollIntoView();
      }, 100);
      //this.spinner.show("spinner1");
  
      this.httpService.getRequest('checkOutOperationApi/v1/genratePdf?socNO=' + this.sapResponse.essocdata.socid + "&lang=" + lang.language+ "&comment="+ lang.comment)
        .subscribe(res => {
          const resp = res['serviceDocFileLink'];
          this.pdfDownload = resp;
          this.generateUrl(this.pdfDownload);
        }, err => {
          // alert("Invalid request");
          // console.log("error occured while fetching details");
          this.spinner.hide();
  
        })
    }else if(reportType=='4th_choice'){
      this.urlblob = "assets\images\not-available.jpg";
      this.showPDF = true;
      this.pdfMatSpinner = true;

      this.show4thChoiceLabel=true;
      setTimeout(() => {
        this.upsFocus.nativeElement.scrollIntoView();
      }, 100);
      //this.spinner.show("spinner1");
  
      this.httpService.getRequest('checkOutOperationApi/v1/generateFourthChoice?socNO=' + this.sapResponse.essocdata.socid + "&lang=" + 'EN')
        .subscribe(res => {
          const resp = res['serviceDocUri'];
          this.pdfDownload = resp;
          this.generateUrl(this.pdfDownload);
        }, err => {
          // alert("Invalid request");
          // console.log("error occured while fetching details");
          this.spinner.hide();
  
        })
    }
  }

  async generateUrl(pdfDownload) {
    this.mobileFlag = false;
    let response = await fetch(pdfDownload);
    let data = await response.blob();
    var blob = new Blob([data], { type: 'application/pdf' });
    this.urlblob = (URL || webkitURL).createObjectURL(blob);
    //  this.urlblob = this.urlblob+"#toolbar=0";
    //this.urlblob= this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl + "#toolbar=0");
    if (window.navigator.userAgent.match(/Chrome/i) && window.navigator.userAgent.match(/Mobile/i)) {
      this.mobileFlag = true;
    }else if(window.navigator.userAgent.match(/Mobile/i)){
      this.mobileFlag = true;
    }
    
    this.pdfMatSpinner = false;
  }


  languageDatalist() {
    this.httpService.getRequest("checkOutOperationApi/v1/language").subscribe(
      res => {
        this.languageData = res['response'];
        this.languageListError = false;
      },

      err => {
        this.languageListErrorMsg = "error occured while getting exchange Codes details";
        this.languageListError = true;
        // console.log("error occured while getting exchange Codes details");
        this.spinner.hide();

      }
    );
  }

  // printwerDatalist() {
  //   this.printerListData = this.zabraPrintSrv.getAvailableDevices();
  //       this.printerListError = false;
  //       this.printerListErrorMsg = "error occured while getting exchange Codes details";
  //       this.printerListError = true;
  //       // console.log("error occured while getting exchange Codes details");
  //       this.spinner.hide();
  // }

  imageShowUsb(data) {
    //this.spinner.show();

    this.httpService.downloadPostRequest('document/downloadDocument', data).subscribe(
      res => {
        this.spinner.hide();

        if (res['code'] != '200') {
          this.showingMediaErrorMsg = res['message'];
          this.showingmediaError = true;
          this.showImage = true;
        } else {
          this.showingmediaError = false;
          this.showDocument = res['data'];
          if (this.showDocument && this.showDocument.length > 0) {
            this.imageURL = this.showDocument[0].urlMediaLink;
            //this.callLightBox = true;
            this.showImage = false;
            var conformMsg = res['message'];
            setTimeout(() => {
              this.lightboxInit();
            }, 100);
          } else {
            this.showImage = true;
          }
          //this.spinner.hide();

        }
      },
      err => {
        // console.log("error occured fetch image data ");
        this.showingMediaErrorMsg = "error in fetch image data";
        this.showingmediaError = true;
        this.spinner.hide();

      }
    );
  }

  downloadImage() {
    window.open(this.imageURL);
    //window.open('https://www.googleapis.com/storage/v1/b/staging.whirlpool-gdpr-dev.appspot.com/o/download%20(1).jpg?&alt=media');
  }

  downloadPDF() {
    window.open(this.pdfDownload);
  }

  printDocument(cmpName) {
    window.print();
  }

  printInvoice(): void {
    this.printContents = document.getElementById("imageDiv").innerHTML;
    const originalContents = document.body.innerHTML;    
    this.printContents=this.printContents.replace('custom-width=""','width="750px" height="550px"')    
   // this.printContents=this.printContents.replace('custom-width=""','width="500px"   style="transform: rotate( 90deg );height: 450px;"')
    setTimeout(()=>{
      this.popupWin = window.open();
      var is_chrome = Boolean(this.popupWin.chrome);
      console.log(is_chrome);
      this.popupWin.document.open();
      this.popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="styles/main.css" /></head><body onload="">' + this.printContents + '</html>');
     if(is_chrome){
      setTimeout(()=>{
        this.popupWin.print();
        this.popupWin.document.close();
      },1000)
    }else{
            this.popupWin.print();
            this.popupWin.document.close();
      }
     
    })
  }
  urlblob: any;
  async printPDF() {
    this.spinner.show();

    let response = await fetch(this.pdfDownload);
    let data = await response.blob();
    var blob = new Blob([data], { type: 'application/pdf' });
    const blobUrl = URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = blobUrl;
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
    this.spinner.hide();
  }

  downloadAndPrint(): void {
    //this.popupWin =  window.open("https://drive.google.com/viewerng/viewer?embedded=true&toolbar=0&navpanes=0&scrollbar=0&url=" + this.pdfURL);
    this.popupWin = window.open();
    //this.popupWin =  window.open('../assets/pdf/sample.pdf');
    var embedPDF = document.createElement("embed");
    embedPDF.setAttribute("src", "https://drive.google.com/viewerng/viewer?embedded=true&toolbar=0&navpanes=0&scrollbar=0&url=" + this.pdfURL);
    //document.getElementById("pdfDiv").appendChild(embedPDF);
    this.popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="styles/main.css" /></head><body onload="">' + embedPDF.innerHTML + '</body></html>');
    //this.popupWin.document.close();
    this.popupWin.focus();
    // this.popupWin.document.print();
    //this.popupWin.close();
    // window.open("");
  }

  printPdf(): void {
    //debugger;
    var iframe = document.createElement('iframe');
    // iframe.id = 'pdfIframe'
    iframe.className = 'pdfIframe'
    document.body.appendChild(iframe);
    iframe.style.display = 'none';
    iframe.src = this.pdfURL;
    //iframe.contentWindow.print();
    URL.revokeObjectURL(this.pdfURL);
    iframe.focus();
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  // IMAGE UPLOAD
  uploadImage(event: any) {
    this.currentDate = new Date();
    $('.isa-current-media').trigger('destroy.owl.carousel');
    $('#lightbox-current').trigger('destroy.owl.carousel');
    $('#lightbox-already').trigger('destroy.owl.carousel');
    setTimeout(() => {
      this.isaMedia();
    }, 20);
    this.numFiles = event.target.files.length;
    $(".isa-med-lenght").text(this.numFiles);
    $('.avatar-preview').css('display', 'flex');
    var files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      var f = files[i];
      f.mediaSRC = URL.createObjectURL(files[i]);
      this.filesList.push(f);
    }
    this.showUploadedFiles = true;
  }

  lightboxInit() {
    ($('.isa-current-media') as any).owlCarousel({
      margin: 0,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: true,
      items: 1
    });

    ($('.isa-media-slider') as any).owlCarousel({
      loop: false,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: true,
      margin: 0,
      items: 1
    });

    ($('#lightbox-already') as any).owlCarousel({
      margin: 0,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: false,
      autoHeight: true,
      items: 1
    });

    ($('#lightbox-current') as any).owlCarousel({
      margin: 0,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: false,
      autoHeight: true,
      items: 1
    });

    ($('.lightboxGallery') as any).owlCarousel({
      autoplay: false,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: false,
      margin: 0,
      items: 1
    });



  }

  isaMedia() {

    ($('#lightbox-already') as any).owlCarousel({
      margin: 0,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: false,
      autoHeight: true,
      items: 1
    });

    ($('#lightbox-current') as any).owlCarousel({
      margin: 0,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: false,
      autoHeight: true,
      items: 1
    });

    this.caoruseExist = true;

    ($('.isa-current-media') as any).owlCarousel({
      margin: 0,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: true,
      items: 1
    });

    ($('.isa-media-slider') as any).owlCarousel({
      loop: false,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: true,
      margin: 0,
      items: 1
    });


  }

  getObjectUrl(file) {
    return URL.createObjectURL(file);
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  removeCam() {
    this.cameraOpen = false;
    this.helperService.hideModal('camera-media-dialog');
    this.filesList = [];
    this.webcamImage = null;
  }


  removeFile(file) {
    $('.isa-current-media').trigger('destroy.owl.carousel');
    $('#lightbox-current').trigger('destroy.owl.carousel');
    $('#lightbox-already').trigger('destroy.owl.carousel');
    let index = this.filesList.indexOf(file);
    this.filesList.splice(index, 1);
    if (this.filesList.length == 0) {
      this.webcamImage = null;
      this.helperService.hideModal("isa-current-slider");
      $('#chooseFile').val('');
    }
    //$(".isa-current-media").trigger('remove.owl.carousel', index).trigger('refresh.owl.carousel');
    setTimeout(() => {
      this.isaMedia();
    }, 20);
  }
  uploadData() {
    if (this.filesList.length == 0) {
      return;
    }

    var fd = new FormData();
    var applianceId = null;
    fd.append('userId', localStorage.getItem('userId'));
    fd.append('userName', this.userName);
    fd.append('system', APPCONFIG.system);
    fd.append('Region', APPCONFIG.Region);
    fd.append('country', APPCONFIG.country);
    fd.append('Language', APPCONFIG.Language);
    // fd.append('Printer', APPCONFIG.selectPrinter);
    fd.append('documentSource', APPCONFIG.documentSource);
    fd.append('consumerId', this.sapResponse.esbpdata.partnerid);
    if (this.sapResponse.esproductdetails.productid) {
      applianceId = this.sapResponse.esproductdetails.productid.replace(/^0+/, '');
    }
    fd.append('applianceId', applianceId);
    fd.append('transactionId', this.sapResponse.essocdata.socid);
    for (var i = 0; i < this.filesList.length; i++) {
      var file = this.filesList[i]
      fd.append('files', file);
    }

    this.singleImageUpload(fd);
  }
  singleImageUpload(data) {
    if (!this.uploadFlag) {
      console.log('upload')
      this.spinner.show();
    }
    this.httpService.uploadPostRequest('document/uploadDocument', data).subscribe(
      res => {
        if (!this.uploadFlag) {
          console.log('upload hide')
          this.spinner.hide();
        }
        if (res['code'] != '200') {
          this.uploadErrorMsg = res['message'];
          //   this.isuploadError =true;
          if (!this.uploadFlag) {
            this.openInformationDialog(this.uploadErrorMsg);
          }
        } else if (res['code'] == '200') {
          //this.isuploadError = false;
          var conformMsg = res['message'];
          if (!this.uploadFlag) {
            this.openInformationDialog(conformMsg);
            this.getImages();
            this.filesList = [];
        }
        } else {
          if (!this.uploadFlag) {
            var msg = 'Failed to process request. Please contact administrator.';
            this.openInformationDialog(msg);
          }
        }
      },
      err => {
        if (!this.uploadFlag) {
          console.log('upload hide')
          this.spinner.hide();
        }
        //  console.log("error occured while media upload");
        this.uploadErrorMsg = "error occured while media upload";
       // this.isuploadError = true;
        //this.helperService.openModal('conform_Dialog');
      }
    );

  }
  imageShow(data) {
    $('#lightbox-already').trigger('destroy.owl.carousel');
    this.spinner.show();

    this.httpService.downloadPostRequest('document/downloadDocument', data).subscribe(
      res => {
        this.spinner.hide();

        if (res['code'] != '200') {
          this.showingAllMediaErrorMsg = res['message'];
          this.showingAllmediaError = true;
        } else {
          this.showingAllmediaError = false;
          var documentData = res['data'];
          var conformMsg = res['message'];
          if (documentData != undefined && documentData != null && documentData.length > 0) {
            for (var i = 0; i < documentData.length; i++) {
              if (documentData[i].attachmentType != 'UPS_LABEL' &&
                (documentData[i].documentName.toLowerCase().includes('jpeg') ||
                  documentData[i].documentName.toLowerCase().includes('jpg') ||
                  documentData[i].documentName.toLowerCase().includes('png') ||
                  documentData[i].documentName.toLowerCase().includes('mp4'))
              ) {
                this.showAllDocument.push(documentData[i]);
              }
            }
            this.callLightBox = true;
            setTimeout(() => {
              this.lightboxInit();
            }, 1000);
          }
          this.spinner.hide();

        }
      },
      err => {
        this.showingAllMediaErrorMsg = "error in fetch image data";
        this.showingAllmediaError = true;
        this.spinner.hide();

      }
    );

  }
  diviceFind() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }
  openCamera() {
    this.cameraOpen = true;
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });

  }
  closeCam() {
    this.cameraOpen = false;
    this.helperService.hideModal('camera-media-dialog');
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }
  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    var imges = this.dataURItoBlob((webcamImage.imageAsDataUrl.substring(webcamImage.imageAsDataUrl.indexOf(',') + 1)));
    const imageFile = new File([imges], new Date().valueOf() + "" + Math.round(Math.random() * 500) + '.jpeg', { type: 'image/jpeg' });
    this.currentDate = new Date();
    $('.isa-current-media').trigger('destroy.owl.carousel');
    $('#lightbox-current').trigger('destroy.owl.carousel');
    $('#lightbox-already').trigger('destroy.owl.carousel');
    setTimeout(() => {
      this.isaMedia();
    }, 20);
    // this.numFiles = $("input:file")[0].files.length;
    $('.avatar-preview').css('display', 'flex');
    var f: any = imageFile;
    f.mediaSRC = URL.createObjectURL(imageFile);
    this.filesList.push(f);
    this.showUploadedFiles = true;
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }
  imageShowbyso(data) {
    $('#lightbox-already').trigger('destroy.owl.carousel');
    this.spinner.show();

    this.httpService.downloadPostRequest('document/downloadDocument', data).subscribe(
      res => {
        this.spinner.hide();

        if (res['code'] != '200') {
          this.showingAllMediaErrorMsg = res['message'];
          this.showingAllmediaError = true;
        } else {
          this.showingAllmediaError = false;
          var documentData = res['data'];
          var conformMsg = res['message'];
          if (documentData != undefined && documentData != null && documentData.length > 0) {
            for (var i = 0; i < documentData.length; i++) {
              if (documentData[i].attachmentType != 'UPS_LABEL' &&
                (documentData[i].documentName.toLowerCase().includes('jpeg') ||
                  documentData[i].documentName.toLowerCase().includes('jpg') ||
                  documentData[i].documentName.toLowerCase().includes('png') ||
                  documentData[i].documentName.toLowerCase().includes('mp4'))
              ) {
                this.showAllDocument.push(documentData[i]);
              }
            }
            this.callLightBox = true;
            setTimeout(() => {
              this.lightboxInit();
            }, 1000);
          }
          this.spinner.hide();

        }
      },
      err => {
        // console.log("error occured fetch image data ");
        this.showingAllMediaErrorMsg = "error in fetch image data";
        this.showingAllmediaError = true;
        this.spinner.hide();

      }
    );
  }
  ngOnDestroy() {
    this.helperService.hideAllModal();
   if(!this.notPauseApi && this.trayNo)
    this.pauseTimer();
    //console.log("check ")
  }

  onselectModelValue(desc) {
    const d = { 'text': desc };
    this.textForm.patchValue(d);
    this.textForm.controls['text'].disable();
  }
  setAction(){
    console.log("Action"+this.actionPerformed);
    if( this.sapResponse && this.sapResponse.etadditionalfields && (JSON.stringify( this.sapResponse).indexOf('FLOW') > -1 || JSON.stringify( this.sapResponse).indexOf('HOLDER') > -1 || JSON.stringify( this.sapResponse).indexOf('PACKING') > -1)){
      for (var i = 0; i < this.sapResponse.etadditionalfields.length; i++) {
        if(this.sapResponse && this.sapResponse.etadditionalfields[i].key == 'FLOW')
         this.actionPerformed  =this.sapResponse.etadditionalfields[i].value ;  
         if(this.sapResponse && this.sapResponse.etadditionalfields[i].key == 'HOLDER')
         this.holder  =this.sapResponse.etadditionalfields[i].value ;
         if(this.sapResponse && this.sapResponse.etadditionalfields[i].key == 'PACKING')
         this.packing  =this.sapResponse.etadditionalfields[i].value ;
         if(this.sapResponse && this.sapResponse.etadditionalfields[i].key == 'IS_4TH')
         this.IS_4TH  =true;
         if(this.sapResponse && this.sapResponse.etadditionalfields[i].key == 'IS_D2C')
         this.D2C  =true ;
         
        console.log(this.actionPerformed);
        }
    }
    if(this.itemDetails && this.itemDetails.isD2C) {
      this.D2C  =true ;
    } 
  }

  getImages(){
    this.showAllDocument=[];
    let postData = {
       "transactionId": this.sapResponse.essocdata.socid, //this.sapResponse.essocdata.socid
    };
    this.imageShow(postData);
    if(this.technicalRequest){
    let postData1 = {
        "transactionId": this.technicalRequest, //technical request number
    };
    this.imageShowbyso(postData1);
  }
  }


  deleteMedia(userId,ref,name){
    // let postData = {
    //   "userId": userId,
    //   "userName": name,
    //   "documentRef": ref
    // };
    var data = new FormData();
    data.append('userId',userId); 
    data.append('userName',name);
    data.append('documentRef', ref);
    console.log('data', data)
    this.spinner.show();
    this.httpService.uploadPostRequest('document/deleteDocument',data).subscribe(
    res => {
        this.spinner.hide();
        if (res['code'] == '200') {
          var msg = res['message'];
          this.openInformationDialog(msg);
          this.getImages();
        } else {
          if (res['code'] == '400') {
            var msg = res['message'];
            this.openInformationDialog(msg);
          }else if (res['code'] == '500') {
            var msg = res['message'];
            this.openInformationDialog(msg);
          }else if (res['code'] == '600') {
            var msg = res['message'];
            this.openInformationDialog(msg);
          }
          else {
            var msg = 'Failed to process request. Please contact administrator.';
            this.openInformationDialog(msg);
          }
        }
      },
      err => {
        var errorMsg = err;
        this.spinner.hide();

      }
    );
  }     

  openInformationDialog(msg) {
    this.dialogref = this.dialog.open(ConfirmationDialogComponent)
      .addPanelClass(['no-padding', 'custom-openconfromDialog', 'custom-mat-dialog'])
    this.dialogref.componentInstance.msg = msg;
    this.dialogref.componentInstance.isNotConformation = true;
  }


  pauseTimer() {
    this.spinner.show();
    if (!this.sapResponse) {
      this.spinner.hide();
      return;
    }
    this.httpService.getRequest("checkOutOperationApi/v1/pauseCheckoutTimer?socNo=" + this.sapResponse.essocdata.socid).subscribe(
      res => {
        this.spinner.hide();
        if (res['status'] == 'ERROR') {
          // console.log("timer Pause",res['status']);
        } else {
          // console.log("timer Pause",res['status']);
        }
      },
      err => {
        // console.log("error occured while close refurb");
        this.spinner.hide();
      }
    );

  }

  generate4thChoiceLabel(){
    this.show4thChoiceLabel=true;
  }
 

  imageShowReport() { 
    
    let postDataReport = {
      "transactionId": this.sapResponse.essocdata.socid, 
    };
    
    this.httpService.downloadPostRequest('document/downloadDocument', postDataReport).subscribe(
      res => {
        this.spinner.hide();

        if (res['code'] != '200') {
          this.showingMediaErrorMsg = res['message'];
          this.showingmediaError = true;
        } else {
          this.showingmediaError = false;
          this.showDocuments = res['data'];
          if (this.showDocuments && this.showDocuments.length > 0) {
            for(let i=0;i<this.showDocuments.length;i++)
            {
              if(this.showDocuments[i].documentName.match(/.(pdf)$/i))
              {  
                  this.showDocuments.sort((a,b) => +new Date(a.documentUploadDate) - +new Date(b.documentUploadDate));
                   this.workReport.push(this.showDocuments[i]);     
              }
            }
          } 
 this.workReport=this.workReport.reverse();
 this.result.push(this.workReport[0]);
 console.log(this.result);

  this.imgUrl=this.result[0].urlMediaLink;
  
  this.docName=this.result[0].documentName;

  if(this.docName.search("EN.pdf") !== -1){
    this.docLanguage="EN";
  }else if(this.docName.search("FR.pdf") !== -1){
    this.docLanguage="FR";
  }else if(this.docName.search("DE.pdf") !== -1){
    this.docLanguage="DE";
  }else if(this.docName.search("NL.pdf") !== -1){
    this.docLanguage="NL";
  }else if(this.docName.search("IT.pdf") !== -1){
    this.docLanguage="IT";
  }   
      var conformMsg = res['message'];
      setTimeout(() => {
        this.lightboxInit();
      }, 100);
    
    let postData = {
      "imgUrl": this.imgUrl, "docName": this.docName, "docLanguage": this.docLanguage, "socNumber": this.sapResponse.essocdata.socid, "soNumber":this.repairDetails.requestNo,"customerEmail": this.sapResponse.esbpaddress.email
    }; 
    console.log(postData)
    this.sendWorkReportViaEmail(postData);
  
  }
  
},
err => {

  this.showingMediaErrorMsg = "error in fetch image data";
  this.showingmediaError = true;
  this.spinner.hide();

}
    );
  }

}


