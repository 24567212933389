import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
} from "@angular/core";
import { HttpService } from "../shared/services/http.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import {
    MatDialog,
} from "@angular/material/dialog";
import {
    FormGroup,
    FormBuilder,
    Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { HelperService } from "app/helper.service";
import { CommonService } from "app/shared/services/common.service";
import { Location } from "@angular/common";
import { MatAutocompleteTrigger } from '@angular/material';
import * as _moment from 'moment';
import { Console } from "console";
const moment = _moment; 
import { map, startWith } from 'rxjs/operators';
import { saveAs } from 'file-saver';

@Component({
    selector: "app-special-projects",
    templateUrl: "./special-projects.component.html",
    styleUrls: ["./special-projects.component.scss"],
})
export class SpecialProjectsComponent implements OnInit {
    @ViewChild(MatAutocompleteTrigger, { static: true }) inputAutoComplit: MatAutocompleteTrigger;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    @ViewChild("itempaginator", { static: false }) itempaginator: MatPaginator;
    @ViewChild("itemsort", { static: false }) itemsort: MatSort;
    @ViewChild("itemCodeQtyToAddPaginator", { static: false })
    itemAndQtyToAddPaginator: MatPaginator;
    @ViewChild("itemCodeAndQtyToAddSort", { static: false })
    itemAndQtyToAddsort: MatSort;
    @ViewChild("focusInput", { static: true }) focusInput: ElementRef;

    projectList: any = [];

    displayedColumns = [
        "projectName",
        "projectType",
        "createdBy",
        "category",
        "totalQuantity",
        "projectStatus",
        "startDate",
        "endDate",
    ];
    displayedColumnsForTeamLead = [
        "projectName",
        "projectType",
        "createdBy",
        "category",
        "totalQuantity",
        "projectStatus",
        "startDate",
        "endDate",
        "downloadReport"
    ]
    // dataSource: MatTableDataSource<any>;
    dataSource: MatTableDataSource<UserDetailsInterface>;

    itemdataSoure: MatTableDataSource<any>;
    itemDisplayedColumns = ["itemCode", "quantity"];
    itemDisplayedColumnsWhileAdding = ["itemCode", "quantity", "delete"];
    itemCodeQuantityList: any;
    itemQuantityDataSourceToAdd: MatTableDataSource<any>;

    userLDapResonseRoles: any;
    isError: boolean;
    result: any;
    userDetailForm: FormGroup;
    addProjectForm: FormGroup;
    downloadRecordForm: FormGroup;
    @ViewChild("userDform", { static: true }) form;
    saveUserDetailFormResponse: any;
    newUser: boolean = true;
    userDetailErrorMsg: String = "";
    userEditErrorMsg: String = "";
    isUserDetailError: boolean = false;
    isUserEditError: boolean = false;
    userDetailAppl;
    userEditAppl;
    dialogref;
    UserrResponse: String = "";
    permissions: any;
    currentCompany;
    selected: any;
    itemList: any;
    projectName: any;
    projectDetailsAccordingToID: any;
    projectNameAccordingToID: any;
    projectCreatedByAccordingToID: any;
    projectTypeAccordingToID: any;
    inspectionTypeAccordingToID: any;
    projectCategoryAccordingToID: any;
    projectTotalQuantityAccordingToID: any;
    projectItemCodeAccordingToID: any;
    replugFromAccordingToID: any;
    replugToAccordingToID: any;
    plugAccordingToID: any;
    projectDescriptionAccordingToID: any;
    userName: any;
    projectItemQuantity: any;
    selectedProjectID: any;
    responseMessage: any;
    projectTypes: any;
    replugOptions:any;
    categories: any;
    itemCodes: any;
    itemCodeValues:any;
    showInspectionType: boolean = false;
    isScrap: boolean = false;
    isSparePartProject: boolean = false;
    showInspectionTypeInDetail: boolean = false;
    showReplug: boolean = false;
    showReplugInDetail: boolean = false;
    isScrapInDetail: boolean = false;
    isSparePartProjectInDetail: boolean = false;
    responsePlugList: any;
    sparePartPlugs: any=["W11649501 - CORD, POWER - SWISS (5K45SSSOB)"
        ,"W11667284 - CORD-POWER, BLACK WITH WRAP, SWISS" 
        ,"W11445184 - ANDROMEDA POWER CORD-SWISS (5KSB4027S)" ];
    todayDate:Date = new Date();
    teamLeadActionsPermission = false;
    spareCodeFlag: boolean = true;
    itemCodeFlag:boolean = true;
    minDate:Date = new Date();
    startDateForDownload:Date;
    bsRangeValue: Date[];
    downloading:any = [];
    isDamagedPackingInspection: boolean = false;
    isOpen: boolean = false;
    openPanel(evt): void {
        evt.stopPropagation();
        // this.inputAutoComplit.openPanel();
    }


    constructor(
        private location: Location,
        private commonService: CommonService,
        private helperservice: HelperService,
        private spinner: NgxSpinnerService,
        private httpService: HttpService,
        public dialog: MatDialog,
        private router: Router,
        private fb: FormBuilder
    ) {
        this.userLDapResonseRoles = JSON.parse(
            localStorage.getItem("loggedUserRoles")
        );
        this.permissions = JSON.parse(localStorage.getItem("permissions"));
        let permissionFlag = false;
        if (this.permissions !== null)
            this.permissions["specialProjects"].toLowerCase() == "true"
                ? (permissionFlag = true)
                : (permissionFlag = false);
        if (this.permissions !== null)
            this.permissions["teamLeadActions"].toLowerCase() == "true"
                ? (this.teamLeadActionsPermission = true)
                : (this.teamLeadActionsPermission = false);
        if (!permissionFlag) {
            this.commonService.openSnackBar(
                "Permission to access this page is restricted, contact Administrator",
                "Close"
            );
            this.location.back();
        }

        if (this.httpService.loginCheckSession()) {
        } else {
            this.httpService.showSessionError();
        }

       
    }

    ngOnInit() {
        this.userName = localStorage.getItem("userName");
        this.minDate.setDate(this.minDate.getDate()-30);
        this.bsRangeValue = [this.minDate, this.todayDate];
        this.userDetailForm = this.fb.group({
            userFullName: ["", [Validators.required]],
            companyId: ["", [Validators.required]],
            wpassId: ["", [Validators.required]],
        });
        this.downloadRecordForm = this.fb.group({
            projectIdForDownload: ["", [Validators.required]],
            projectNameForDownload: ["", [Validators.required]],
            projectTypeForDownload: ["", [Validators.required]],
            userNameForDownload: ["", [Validators.required]],
            startDate:[this.minDate, [Validators.required]],
            endDate: [this.todayDate, [Validators.required]],
        })

        this.addProjectForm = this.fb.group({
            projectname: ["", [Validators.required]],
            createdBy: ["", [Validators.required]],
            selectedProjectType: ["", [Validators.required]],
            selectedCategory: ["", [Validators.required]],
            totalQuantity: ["", [Validators.required]],
            itemCodes: ["", [Validators.required]],
            quantity: ["", [Validators.required]],
            inspectionType: [""],
            replugFrom: [""],
            replugTo: [""],
            description: [""],
            selectedPlug: [""],
            endDate:[ moment("12/25/1995", "MM/DD/YYYY"),[Validators.required]],
        });
        //this.selected = 'option2';
        this.fetchProjectDetail(); //to display project details
        
        
    }

    setPaginator() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }
    setPaginatorForItem() {
        this.itemdataSoure.paginator = this.itempaginator;
        this.itemdataSoure.sort = this.itemsort;
    }

    setPaginatorForItemCodeAndQtyToAdd() {
        this.itemQuantityDataSourceToAdd.paginator =
            this.itemAndQtyToAddPaginator;
        this.itemQuantityDataSourceToAdd.sort = this.itemAndQtyToAddsort;
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    fetchProjectDetail() {
        this.spinner.show();
        this.httpService
            .getRequest("specialProjectApi/v1/projectList")
            .subscribe(
                (res) => {
                    this.spinner.hide();
                    if(res['status'] === 'ERROR'){
                        this.responseMessage = res['errorMessage'];
                        this.helperservice.openStaticModal('ConfirmDialog');
                        return;
                      }
                    console.log(res["items"]);
                    console.log(res);
                    this.projectList = res["projects"];
                    this.dataSource =
                        new MatTableDataSource<UserDetailsInterface>(
                            this.projectList
                        );
                    console.log(this.dataSource);
                    this.setPaginator();
                },
                err => {
                    // alert("Internal issue to fetch the information");
                    this.spinner.hide();
                    this.responseMessage = "Internal issue to fetch the information";
                    this.helperservice.openStaticModal('ConfirmDialog');
                  }
            );
    }

    projectTypeChange() {
        var projectType = this.addProjectForm.value.selectedProjectType;
        console.log("project type : " + projectType);
        this.showInspectionType = false;
        this.showReplug = false;
        this.isScrap = false;
        this.isSparePartProject = false;
        this.isDamagedPackingInspection = false;

        this.addProjectForm.controls["inspectionType"].clearValidators();
        this.addProjectForm.controls["replugFrom"].clearValidators();
        this.addProjectForm.controls["replugTo"].clearValidators();
        this.addProjectForm.controls["endDate"].clearValidators();
        this.addProjectForm.controls['selectedPlug'].clearValidators();
        this.addProjectForm.controls['description'].clearValidators();
        this.addProjectForm.controls['itemCodes'].clearValidators();
        this.addProjectForm.controls['quantity'].clearValidators();



        if (projectType != null && (projectType == "STANDARD_INSPECTION" || projectType == "SAFE_LAUNCH")) {
            this.showInspectionType = true;
            this.addProjectForm.controls["inspectionType"].setValidators([
                Validators.required,
            ]);
        }
        if (projectType != null && projectType == "REPLUG") {
            this.showReplug = true;
            this.addProjectForm.controls["replugFrom"].setValidators([
                Validators.required,
            ]);
            this.addProjectForm.controls["replugTo"].setValidators([
                Validators.required,
            ]);
            this.addProjectForm.controls['selectedPlug'].setValidators([
                Validators.required,
            ]);
        }
        if (projectType != null && projectType == "SCRAP") {
            this.isScrap = true;
            this.addProjectForm.controls['selectedCategory'].clearValidators();
            this.addProjectForm.controls['itemCodes'].clearValidators();
            this.addProjectForm.controls['quantity'].clearValidators();
            this.addProjectForm.controls['inspectionType'].clearValidators();
            this.addProjectForm.controls['replugFrom'].clearValidators();
            this.addProjectForm.controls['replugTo'].clearValidators();
            this.addProjectForm.controls['selectedPlug'].clearValidators();
            this.addProjectForm.controls['description'].clearValidators();
    
            this.addProjectForm.controls['selectedCategory'].updateValueAndValidity();
            this.addProjectForm.controls['itemCodes'].updateValueAndValidity();
            this.addProjectForm.controls['quantity'].updateValueAndValidity();
            this.addProjectForm.controls['description'].updateValueAndValidity();
        }
        if (projectType != null && projectType == "SPARE_PARTS") {
            this.isSparePartProject = true;
            this.addProjectForm.controls["endDate"].setValidators([
                Validators.required,
            ]);
            this.addProjectForm.controls['selectedCategory'].clearValidators();
            this.addProjectForm.controls['itemCodes'].clearValidators();
            this.addProjectForm.controls['quantity'].clearValidators();
            this.addProjectForm.controls['selectedCategory'].updateValueAndValidity();
            this.addProjectForm.controls['itemCodes'].updateValueAndValidity();
            this.addProjectForm.controls['quantity'].updateValueAndValidity();
        }

        if (projectType != null && projectType == "DAMAGED_PACKING_INSPECTION") {
            this.isDamagedPackingInspection = true;
            
            this.addProjectForm.controls["inspectionType"].clearValidators();
            this.addProjectForm.controls["replugFrom"].clearValidators();
            this.addProjectForm.controls["replugTo"].clearValidators();
            this.addProjectForm.controls["selectedPlug"].clearValidators();
            this.addProjectForm.controls["description"].clearValidators();
            this.addProjectForm.controls["itemCodes"].clearValidators();
            this.addProjectForm.controls["quantity"].clearValidators();
    
            this.addProjectForm.controls["inspectionType"].updateValueAndValidity();
            this.addProjectForm.controls["replugFrom"].updateValueAndValidity();
            this.addProjectForm.controls["replugTo"].updateValueAndValidity();
            this.addProjectForm.controls["selectedPlug"].updateValueAndValidity();
            this.addProjectForm.controls["description"].updateValueAndValidity();
            this.addProjectForm.controls["itemCodes"].updateValueAndValidity();
            this.addProjectForm.controls["quantity"].updateValueAndValidity();
        }
       
        this.addProjectForm.controls["inspectionType"].updateValueAndValidity();
        this.addProjectForm.controls["replugFrom"].updateValueAndValidity();
        this.addProjectForm.controls["replugTo"].updateValueAndValidity();
        this.addProjectForm.controls["selectedPlug"].updateValueAndValidity();
        this.addProjectForm.controls["endDate"].updateValueAndValidity();
    }

    resetFormValidators() {
        this.showInspectionType = false;
        this.showReplug = false;
        this.isScrap=false;
        this.isSparePartProject = false;
        this.isDamagedPackingInspection = false
        this.addProjectForm.controls['selectedPlug'].clearValidators();
        this.addProjectForm.controls["inspectionType"].clearValidators();
        this.addProjectForm.controls["replugFrom"].clearValidators();
        this.addProjectForm.controls["replugTo"].clearValidators();
        this.addProjectForm.controls["inspectionType"].updateValueAndValidity();
        this.addProjectForm.controls["replugFrom"].updateValueAndValidity();
        this.addProjectForm.controls["replugTo"].updateValueAndValidity();
        this.addProjectForm.controls["endDate"].clearValidators();
        this.addProjectForm.controls["endDate"].updateValueAndValidity();
        this.addProjectForm.controls['selectedPlug'].updateValueAndValidity();
    }

    openDownloadDialog(id,name,type,startDate){
        console.log(id +" "+name+" "+type); 
        this.downloadRecordForm.controls["projectIdForDownload"].setValue(id);
        this.downloadRecordForm.controls["projectNameForDownload"].setValue(name);
        this.downloadRecordForm.controls["projectTypeForDownload"].setValue(type);
        this.downloadRecordForm.controls["userNameForDownload"].setValue(localStorage.getItem("userId"));
       this.startDateForDownload = startDate;
        this.helperservice.openStaticModal("downloadRecord-Dialog");
    }
    dateFilterChanged(bsRangeValue: string) {
        const d={'startDate': bsRangeValue[0],'endDate': bsRangeValue[1]};
        this.downloadRecordForm.patchValue(d);
      }
    
    
      downloadProjectSummary() {
        // Hardcoded payload 
        this.spinner.show();
        const postData = {
          "user": localStorage.getItem("userId"),
          "projectType": "PROJECT_SUMMARY",
          "projectId": "-1"
         };
         var currentdate = new Date(); 
         var datetimeofprojectsummary = currentdate.getDate() + "/"
         + (currentdate.getMonth()+1)  + "/" 
         + currentdate.getFullYear() + " @ "  
         + currentdate.getHours() + ":"  
         + currentdate.getMinutes() + ":" 
         + currentdate.getSeconds();
  
        this.httpService
                .postRequestWithAnyResponse("extractProjectSummary", postData)
        .subscribe(
          (res: Blob) => {
            this.spinner.hide();
            const fileName = 'ProjectSummary' + datetimeofprojectsummary + ".xlsx";
            saveAs(res, fileName);
          },  
          (err) => {
            this.spinner.hide();
            console.error('Error downloading project summary:', err);
            this.responseMessage = 'Error downloading project summary';
          }
        );
      }
    
    addProject() {
        //alert();
        this.itemCodeQuantityList = [];
        this.itemQuantityDataSourceToAdd = new MatTableDataSource<any>(
            this.itemCodeQuantityList
        );
        this.helperservice.openStaticModal("addProject-Dialog");
        this.getProjectTypes(); //to fetch project types
        this.getProductCategories();
        this.getItemCodes();
        this.getReplugOptions();
        this.addProjectForm.reset();
        this.addProjectForm.controls["selectedProjectType"].clearValidators();
        this.addProjectForm.controls["projectname"].clearValidators();
        this.addProjectForm.controls["itemCodes"].clearValidators();
        this.addProjectForm.controls["selectedCategory"].clearValidators();
        this.addProjectForm.controls["quantity"].clearValidators();
        this.addProjectForm.controls["itemCodes"].clearValidators();
        this.resetFormValidators();

        this.addProjectForm.get("createdBy").setValue(localStorage.getItem("userName"));
        Object.keys(this.addProjectForm.controls).forEach((key) => {
            this.addProjectForm.get[key].clearValidators();
            this.addProjectForm.get[key].setErrors(null);
            this.addProjectForm.get[key].updateValueAndValidity();
        });
        
    }
    
    
        seeProject(id) {
        this.selectedProjectID = id;
        this.spinner.show();
        this.showInspectionTypeInDetail = false;
        this.showReplugInDetail = false;
        this.isScrapInDetail = false;
        this.isSparePartProjectInDetail = false;
        this.httpService
            .getRequest("specialProjectApi/v1/projectDetails?projectId=" + id)
            .subscribe(
                (res) => {
                    this.spinner.hide();
                    console.log(res["projectDetails"]);
                    this.projectDetailsAccordingToID = res["projectDetails"];
                    this.projectNameAccordingToID =
                        this.projectDetailsAccordingToID["projectName"];
                    this.projectCreatedByAccordingToID =
                        this.projectDetailsAccordingToID["createdBy"];
                    this.projectTypeAccordingToID =
                        this.projectDetailsAccordingToID["projectType"];
                    this.projectDescriptionAccordingToID =
                        this.projectDetailsAccordingToID["description"];
                    if (this.projectDetailsAccordingToID["projectType"] == "STANDARD_INSPECTION" ||
                        this.projectDetailsAccordingToID["projectType"] == "SAFE_LAUNCH") {
                        this.showInspectionTypeInDetail = true;
                        this.inspectionTypeAccordingToID =
                            this.projectDetailsAccordingToID["inspectionType"];
                    }
                    if (this.projectDetailsAccordingToID["projectStatus"] ==
                        "OPEN"){
                            this.isOpen = true;
                        }
                    if (
                        this.projectDetailsAccordingToID["projectType"] ==
                        "REPLUG"
                    ) {
                        this.showReplugInDetail = true;
                        this.replugFromAccordingToID =
                            this.projectDetailsAccordingToID["from"];
                        this.replugToAccordingToID =
                            this.projectDetailsAccordingToID["to"];
                        this.plugAccordingToID =
                            this.projectDetailsAccordingToID["plug"];
                    }
                    if (
                        this.projectDetailsAccordingToID["projectType"] ==
                        "SCRAP"
                    ) {
                        this.isScrapInDetail = true;
                    }
                    if (
                        this.projectDetailsAccordingToID["projectType"] ==
                        "SPARE_PARTS"
                    ) {
                        this.isSparePartProjectInDetail = true;
                    }

                    this.projectCategoryAccordingToID = this.projectDetailsAccordingToID["category"];
                    this.projectTotalQuantityAccordingToID = this.projectDetailsAccordingToID["totalQuantity"];
                    this.projectItemCodeAccordingToID = this.projectDetailsAccordingToID["projectItemCodeMappingDtoList"];
                    this.itemdataSoure = new MatTableDataSource<any>(
                        this.projectItemCodeAccordingToID
                    );
                    this.setPaginatorForItem();
                    this.projectItemQuantity =
                        this.projectDetailsAccordingToID["quantity"];
                    this.helperservice.openStaticModal("seeProject-Dialog");
                },
                err => {
                    // alert("Internal issue to fetch the information");
                    this.spinner.hide();
                    this.responseMessage = "Internal issue to fetch the information";
                    this.helperservice.openStaticModal('ConfirmDialog');
                  }
            );
    }

    closeProject(){
        this.spinner.show();
            let postData = {
              "projectId": this.selectedProjectID
            };
            this.httpService.postRequest('specialProjectApi/v1/closeProject', postData).subscribe(
              res => {
                this.spinner.hide();
                if (res[status] = 'SUCCESS') {
                  this.responseMessage = res['response'];
                  this.closeProjectDetail()
                  this.helperservice.openStaticModal('ConfirmDialog');
                  this.fetchProjectDetail();
                } else {
                  this.responseMessage = res['response'];
                  this.helperservice.openStaticModal('ConfirmDialog');
                  }
              },
              err => {
                this.spinner.hide();
                this.responseMessage = "Internal issue to fetch the information";
                this.helperservice.openStaticModal('ConfirmDialog');
              }
            );
            this.fetchProjectDetail(); //to display project details
            this.isOpen = false;
      }
     
    closeProjectDetail() {
        this.helperservice.hideModal("seeProject-Dialog");
    }

    closeDownloadRecordForm() {
        this.helperservice.hideModal("downloadRecord-Dialog");
    }
    closePopup() {
        this.helperservice.hideModal('ConfirmDialog');
      }
    downloadRecords(){
        if (!this.downloadRecordForm.valid) {
            this.helperservice.makeFormDirty(this.downloadRecordForm);
            return;
        }
        console.log(this.downloadRecordForm);
            var downloadForm = this.downloadRecordForm.value;
            this.downloading.push(downloadForm.projectIdForDownload)
            let stard=new Date(downloadForm.startDate);
            stard.setHours(stard.getHours() + 5);
            stard.setMinutes(stard.getMinutes() + 30);
            let downloadStartDate = new Date(stard)
            let endd=new Date(downloadForm.endDate);
            endd.setHours(endd.getHours() + 5);
            endd.setMinutes(endd.getMinutes() + 30);
            let downloadEndDate = new Date(endd)
            let postData = 
                {
                    "user":downloadForm.userNameForDownload,
                    "startDate":downloadStartDate,
                    "endDate":downloadEndDate,
                    "projectType":downloadForm.projectTypeForDownload,
                    "projectId":downloadForm.projectIdForDownload,
                    "projectName":downloadForm.projectNameForDownload
                }
                
            this.httpService
                .postRequestWithAnyResponse("extractFile", postData)
                .subscribe(
                    (res) => {
                        
                        const index = this.downloading.indexOf(downloadForm.projectIdForDownload);

                        if (index !== -1) {
                            this.downloading.splice(index, 1);
                        }
                        var currentdate = new Date(); 
                var datetime = currentdate.getDate() + "/"
                + (currentdate.getMonth()+1)  + "/" 
                + currentdate.getFullYear() + " @ "  
                + currentdate.getHours() + ":"  
                + currentdate.getMinutes() + ":" 
                + currentdate.getSeconds();
                        let fileName = downloadForm.projectNameForDownload+"_"+downloadForm.projectTypeForDownload+"_"+ datetime + ".xlsx";
                        var file = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;' });
                        saveAs(file, fileName);
                    },
                    async (err) => {
                        console.log("any response error"+JSON.parse(await err.text()));
                        let error = JSON.parse(await err.text())
                        const index = this.downloading.indexOf(downloadForm.projectIdForDownload);
                        if (index !== -1) {
                            this.downloading.splice(index, 1);
                        }
                        this.responseMessage = error['response']
                        this.helperservice.openStaticModal('ConfirmDialog')
                    }
                );         
        this.closeDownloadRecordForm();
        this.downloadRecordForm.reset();
        this.bsRangeValue = [this.minDate, this.todayDate];
        this.downloadRecordForm.controls["startDate"].setValue(this.minDate);
        this.downloadRecordForm.controls["endDate"].setValue(this.todayDate);
    }
    closeAddProjectForm() {
        this.resetFormValidators();
        this.addProjectForm.reset();
        this.form.resetForm();
        this.resetFormValidators();
        this.helperservice.hideModal("addProject-Dialog");

    }
    
    


    addProjectSubmitForm() {
        if (!this.addProjectForm.valid) {
            this.helperservice.makeFormDirty(this.addProjectForm);
            return;
        } else if (
            !this.isDamagedPackingInspection && !this.isScrap && !this.isSparePartProject &&(this.itemCodeQuantityList === undefined ||
            this.itemCodeQuantityList.length == 0)
        ) {
            // alert("At least 1 item code is required.");
            this.responseMessage = "At least 1 item code is required.";
            this.helperservice.openStaticModal('ConfirmDialog');
        } else {
            this.spinner.show();
            var inspFrom = this.addProjectForm.value;
            
            let d=new Date(inspFrom.endDate);
            d.setHours(d.getHours() + 5);
            d.setMinutes(d.getMinutes() + 30);
            let projectEndDate = new Date(d)
            let postData = {
                projectName: inspFrom.projectname,
                projectType: inspFrom.selectedProjectType,
                category: inspFrom.selectedCategory,
                createdBy: inspFrom.createdBy,
                startDate: new Date().toISOString().slice(0, 10),
                projectItemCodes: this.itemCodeQuantityList,
                totalQuantity: inspFrom.totalQuantity,
                inspectionType: inspFrom.inspectionType,
                description: inspFrom.description,
                replugFrom: inspFrom.replugFrom,
                replugTo: inspFrom.replugTo,
                plugSparePart: inspFrom.selectedPlug,
                endDate:inspFrom.endDate!=null?projectEndDate:null,
            };
            console.log(postData);
            this.httpService.postRequest("specialProjectApi/v1/createProject", postData)
            .subscribe(
                (res) => {
                    this.spinner.hide();
                    if (res['status'] === 'ERROR') {
                        // Show error message in modal
                        this.responseMessage = res['errorMessage'] || "Project creation failed. Please try again.";
                        this.helperservice.openStaticModal('ConfirmDialog');
                        return;
                    }
                    this.fetchProjectDetail();
                    this.helperservice.hideModal("addProject-Dialog");
                },
                (err) => {
                    this.spinner.hide();
                    this.responseMessage = "Project creation failed due to an internal error. Please contact the administrator.";
                    this.helperservice.openStaticModal('ConfirmDialog');
                }
            );
            this.helperservice.hideModal("addProject-Dialog");
        }
    }
    // getSpareCodeVerified(event) {
    //     this.spareCodeFlag = event.isUserInput;
    //   }
    //   focusOutSpareCode(code) {
    //     if (code != undefined) {
    //       var codeFound = this.sparePartPlugs.find(x => x.description == code);
    //       if (codeFound != undefined && code == codeFound.description) {
    //         this.spareCodeFlag = true;
    //       }
    //       else {
    //         this.spareCodeFlag = false;
    //       }
    //     }
      
    //   }

      getItemCodeVerified(event) {
        this.itemCodeFlag = event.isUserInput;
      }
      focusOutItemCode(code) {
        if (code != undefined) {
          var codeFound = this.itemCodes.find(x => x == code);
          if (codeFound != undefined && code == codeFound) {
            this.itemCodeFlag = true;
          }
          else {
            this.itemCodeFlag = false;
          }
        }
      
      }

    clickOnProject(type) {
        this.helperservice.hideModal("seeProject-Dialog");
        if (type == "OTI_REPAIR") {
            this.router.navigate(["/oti-repair/"], {
                queryParams: { projectID: this.selectedProjectID },
            });
        } else if (type == "STANDARD_INSPECTION") {
            this.router.navigate(["/standard-inspection/"], {
                queryParams: { projectID: this.selectedProjectID },
            });
        } else if (type == "REPLUG") {
            this.router.navigate(["/replug/"], {
                queryParams: { projectID: this.selectedProjectID },
            });
        } else if (type == "DAMAGED_PACKING_INSPECTION") {
            this.router.navigate(["/damaged-packing-inspection/"], {
                queryParams: { projectID: this.selectedProjectID },
            });
        } else if (type == "SAFE_LAUNCH") {
            console.log("SAFE_LAUNCH");
            this.router.navigate(["/safe-launch/"], {
                queryParams: { projectID: this.selectedProjectID },
            });
        } else if (type == "OTI_CHECK") {
            this.router.navigate(["/oti-checks/"], {
                queryParams: { projectID: this.selectedProjectID },
            });
        }    else if (type == "SPARE_PARTS") {
            this.router.navigate(["/spare-part"], {
                queryParams: {projectID: this.selectedProjectID },
            });
        } else if (type == "SCRAP") {
            this.router.navigate(["/scrap-project/"], {
                queryParams: { projectID: this.selectedProjectID },
            });
        }
        else if (type == "REWORK") {
            this.router.navigate(["/rework"], {
                queryParams: { projectID: this.selectedProjectID },
            });
        }
    }
    getReplugOptions(){
        this.spinner.show();
        this.httpService
            .getRequest("specialProjectApi/v1/getReplugOptions")
            .subscribe(
                (res) => {
                    this.spinner.hide();
                    this.replugOptions = res["items"];
                },
                err => {
                    // alert("Internal issue to fetch the information");
                    this.spinner.hide();
                    this.responseMessage = "Internal issue to fetch the information";
                    this.helperservice.openStaticModal('ConfirmDialog');
                  }
            );
    }

    getProjectTypes() {
        this.spinner.show();
        this.httpService
            .getRequest("specialProjectApi/v1/projectTypes")
            .subscribe(
                (res) => {
                    this.spinner.hide();
                    this.projectTypes = res["items"];
                },
                err => {
                    // alert("Internal issue to fetch the information");
                    this.spinner.hide();
                    this.responseMessage = "Internal issue to fetch the information";
                    this.helperservice.openStaticModal('ConfirmDialog');
                  }
            );
    }

    getProductCategories() {
        this.spinner.show();
        this.httpService
            .getRequest("specialProjectApi/v1/productCategories")
            .subscribe(
                (res) => {
                    this.spinner.hide();
                    console.log(res["Categories"]);
                    this.categories = res["Categories"];
                },
                err => {
                    // alert("Internal issue to fetch the information");
                    this.spinner.hide();
                    this.responseMessage = "Internal issue to fetch the information";
                    this.helperservice.openStaticModal('ConfirmDialog');
                  }
            );
    }

    getItemCodes() {
        this.spinner.show();
        this.httpService.getRequest("specialProjectApi/v1/itemCodes").subscribe(
            (res) => {
                this.spinner.hide();
                console.log(res["ItemCodes"]);
                this.itemCodeValues = res["ItemCodes"];
                this.loadFilterForItemCode();
            },
            err => {
                // alert("Internal issue to fetch the information");
                this.spinner.hide();
                this.responseMessage = "Internal issue to fetch the information";
                this.helperservice.openStaticModal('ConfirmDialog');
              }
        );
    }
    // getPlugList() {
    //     this.httpService
    //         .getRequest("specialProjectApi/v1/getPlugSpareParts")
    //         .subscribe(
    //             (res) => {
    //                 console.log(res["ItemCodes"]);
    //                 if ((res["status"] = "SUCCESS")) {
    //                     this.responsePlugList = res["response"];
    //                     this.sparePartPlugs = this.responsePlugList;
    //                 } else {
    //                     this.responseMessage = res["response"];
    //                     this.helperservice.openStaticModal('ConfirmDialog');
    //                     console.log("error fetching plug");
    //                 }
    //             },
    //             err => {
    //                 // alert("Internal issue to fetch the information");
    //                 this.spinner.hide();
    //                 this.responseMessage = "Internal issue to fetch the information";
    //                 this.helperservice.openStaticModal('ConfirmDialog');
    //               }
    //         );
    // }
    // loadFilter() {
    //     this.spareMasterCode = this.addProjectForm.get('selectedPlug').valueChanges
    //       .pipe(
    //         startWith(''),
    //         map(value => this._filterSpareCode(value))
    //       );
      
    //   }
    
    //   private _filterSpareCode(value: string): string[] {
    //     if ((value === undefined || value === null) || (this.sparePartPlugs === undefined || this.sparePartPlugs === null))
    //       return;
    //     const filterValue = value.toLowerCase();
    //     var data = this.sparePartPlugs.filter(option1 => String(option1.code).toLowerCase().indexOf(filterValue) > -1 ||
    //       option1.description.toLowerCase().indexOf(filterValue) > -1);
    //     return data
    //   }
      loadFilterForItemCode() {
        this.itemCodes = this.addProjectForm.get('itemCodes').valueChanges
          .pipe(
            startWith(''),
            map(value => this._filterItemCode(value))
          );
      
      }
    
      private _filterItemCode(value: string): string[] {
        if ((value === undefined || value === null) || (this.itemCodeValues === undefined || this.itemCodeValues === null))
          return;
        const filterValue = value.toLowerCase();
        var data = this.itemCodeValues.filter(option1 =>option1.toLowerCase().indexOf(filterValue) > -1);
        return data
      }
    addItemCodeQuantity() {
        if (
            this.addProjectForm.value.itemCodes == null ||
            this.addProjectForm.value.quantity == null
        ) {
            // alert("Item code and quantity cannot be empty.");
            this.responseMessage = "Item code and quantity cannot be empty.";
            this.helperservice.openStaticModal('ConfirmDialog');
        } else if (this.itemCodeQuantityList.some(item => item.itemCode === this.addProjectForm.value.itemCodes)){
            // alert("Duplicate Item code selected. \n\n\nNote: To change the quantity of the item code, Please delete "+this.addProjectForm.value.itemCodes+" form the previously selected item codes and add it again with the updated quantity.");
            this.responseMessage = "Duplicate Item code selected. \n\n\nNote: To change the quantity of the item code, Please delete "+this.addProjectForm.value.itemCodes+" form the previously selected item codes and add it again with the updated quantity.";
            this.helperservice.openStaticModal('ConfirmDialog');     
        } else {
            if (this.itemCodeQuantityList === undefined) {
                this.itemCodeQuantityList = [
                    {
                        itemCode: this.addProjectForm.value.itemCodes,
                        quantity: this.addProjectForm.value.quantity,
                    },
                ];
            } else {
                this.itemCodeQuantityList.push({
                    itemCode: this.addProjectForm.value.itemCodes,
                    quantity: this.addProjectForm.value.quantity,
                });
            }
            this.itemQuantityDataSourceToAdd = new MatTableDataSource<any>(
                this.itemCodeQuantityList
            );
            this.setPaginatorForItemCodeAndQtyToAdd();
            this.addProjectForm.get("itemCodes").reset();
            this.addProjectForm.get("quantity").reset();
        }
    }

    removeItemCodeQtyFromList(index) {
        this.itemCodeQuantityList.splice(index, 1);
        this.itemQuantityDataSourceToAdd = new MatTableDataSource<any>(
            this.itemCodeQuantityList
        );
    }
    // openUserDailog() {
    //     this.getCurrentCompany();
    //    this.helperservice.openStaticModal('userDetailForm-Dialog');
    //    setTimeout(()=>{
    //     this.focus()
    //   },1000)
    // }

    // editUser(user) {
    //   console.log("In edit user function",user);
    //   this.userEditForm.setValue({
    //        'companyId' :  user.company.crmcompanyId,
    //        'wpassId' : user.userName
    //   });
    //   this.helperservice.openStaticModal('userEditForm-Dialog');
    // }

    // confirmDelete(user) {
    //   this.dialogref = this.dialog.open(ConfirmationDialogComponent, {
    //     disableClose: true,
    //     data: {
    //       message: "Do you want to deactivate this user."
    //     },
    //     panelClass: ['no-padding', 'custom-openconfromDialog', 'custom-mat-dialog']
    //   });
    //   this.dialogref.afterClosed().subscribe(res => {
    //     console.log(res);
    //     if(res && user.userName){
    //     this.deleteUser(user);
    //     }
    //   })
    // }

    // deleteUser(user){
    //  this.spinner.show();
    //  let data={"wpassId" : user.userName}
    //    this.httpService.postRequest('adminApi/v1/deleteUser',data)
    //    .subscribe(res=>{
    //     this.spinner.hide();
    //      if(res['status']=='ERROR' || res['responseCode']!=200) {
    //       this.UserrResponse="Something went wrong. Please Contact admin";
    //      } else {
    //        this.fetchUserDetail();
    //        this.UserrResponse="User Deleted Succesfully";
    //      }
    //      this.helperservice.openStaticModal('ConfirmDialog')
    //    },err=>{
    //      this.spinner.hide();
    //     this.UserrResponse="Something went wrong. Please Contact admin";
    //     this.helperservice.openStaticModal('ConfirmDialog')

    //    });
    // }

    // confirmUserForm() {
    //   if (!this.userDetailForm.valid) {
    //     this.helperservice.makeFormDirty(this.userDetailForm);
    //     return;
    //   }
    //   var msg = 'Do you confirm the new user Details ?';
    //   this.openconfromationDialog(msg);
    //   this.dialogref.afterClosed().subscribe(result => {
    //     if (result) {
    //       console.log(result);
    //     this.saveNewUserDetails();
    //     }
    //   });
    // }

    // confirmEditForm() {
    //   if (!this.userEditForm.valid) {
    //     this.helperservice.makeFormDirty(this.userEditForm);
    //     return;
    //   }
    //   var msg = 'confirmed the new user Details ?';
    //   this.openconfromationDialog(msg);
    //   this.dialogref.afterClosed().subscribe(result => {
    //     if (result) {
    //       console.log(result);
    //     this.EditUserDetails();
    //     }
    //   });
    // }

    // openconfromationDialog(msg) {
    //   this.dialogref = this.dialog.open(ConfirmationDialogComponent, {
    //     disableClose: true,
    //     data: {
    //       message: msg
    //     },
    //     panelClass: ['no-padding', 'custom-openconfromDialog', 'custom-mat-dialog']
    //   });
    // }

    // clearUserForm(){
    //   this.userDetailAppl = null;
    //   this.userDetailForm.reset();
    //   this.form.resetForm();
    //   this.helperservice.hideModal('userDetailForm-Dialog');
    // }

    // clearUserEditForm(){
    //   this.userEditAppl = null;
    //   this.userEditForm.reset();
    //   this.form.resetForm();
    //   this.helperservice.hideModal('userEditForm-Dialog');
    // }

    // saveNewUserDetails() {
    //   if(!this.userDetailForm.valid) {
    //     this.helperservice.makeFormDirty(this.userDetailForm);
    //     return;
    //   }
    //  // this.spinner.show();
    //   var user = this.userDetailForm.value;
    //   console.log("User Details=",user);
    //    this.httpService.postRequest('adminApi/v1/addUser',user)
    //    .subscribe(res=>{
    //     //this.spinner.hide();
    //      if(res['status']=='ERROR'|| res['responseCode']!=200) {
    //       this.clearUserForm();
    //       this.UserrResponse="Something went wrong. Please Contact admin";
    //        this.isUserDetailError =true;
    //        setTimeout(()=>{
    //          this.isUserDetailError =false;
    //       },2000)
    //      } else {
    //       this.UserrResponse="User Saved Succesfully";
    //        console.log(this.UserrResponse);
    //        this.clearUserForm();
    //        this.fetchUserDetail();
    //      }
    //      this.helperservice.openStaticModal('ConfirmDialog')
    //    },err=>{
    //      //this.spinner.hide();
    //    });

    // }
    // redirect(){
    //   this.helperservice.hideModal('ConfirmDialog');
    // }

    // EditUserDetails() {
    //   if(!this.userEditForm.valid) {
    //     this.helperservice.makeFormDirty(this.userEditForm);
    //     return;
    //   }
    //  // this.spinner.show();
    //   var user = this.userEditForm.value;
    //   console.log("User Details=",user);
    //    this.httpService.postRequest('adminApi/v1/updateUserDetails',user)
    //    .subscribe(res=>{
    //     //this.spinner.hide();
    //      if(res['status']=='ERROR' || res['responseCode']!=200) {
    //       // this.UserrResponse= res['response'];
    //       this.UserrResponse="Something went wrong. Please Contact admin";
    //        this.isUserEditError =true;
    //        this.clearUserEditForm();

    //        setTimeout(()=>{
    //          this.isUserEditError =false;
    //       },2000)
    //      } else {
    //       //  this.UserrResponse=res['response'];
    //       this.UserrResponse="User updated succesfully";
    //        console.log(this.UserrResponse);
    //        this.clearUserEditForm();
    //        this.fetchUserDetail();
    //      }
    //      this.helperservice.openStaticModal('ConfirmDialog')
    //    },err=>{
    //      //this.spinner.hide();
    //    });

    // }

    // getCurrentCompany() {
    //   let companyFromStore=null;
    //   if(localStorage.getItem('companyId')){
    //      companyFromStore = localStorage.getItem('companyId');
    //   }else{
    //     companyFromStore=JSON.parse(localStorage.getItem('loggedUserResponse')).company.id
    //   }
    //   let companyList= this.commonService.getCompamyList();
    //   if (companyFromStore !== '')
    //     companyList.forEach(ele => {
    //       if(ele.id.toString() == companyFromStore){
    //         this.currentCompany = ele;
    //       }
    //     });

    //   this.userDetailForm.get('companyId').setValue(this.currentCompany.crmcompanyId.trim())
    // }
    // focus(){
    //   this.focusInput.nativeElement.focus();
    // }
}

export interface UserDetailsInterface {
    userFullName: string;
    userName: string;
    companyId: number;
    Role: [];
}
