function makeAppConfig() {
    let env = "prod"; //Change this for [ dev | uat | prod ] release

    let isaConstant = new ISAConstants(env);
    let AppConfig = {
        brand: " ISA",
        URL: isaConstant.BACKEND_API_URL,
        SERVICEDOCURL: isaConstant.SERVICEDOC_URL,
        ESRP_URL: isaConstant.ESRP_URL, //Url From ESRP tool to forget and unlock user
        REPORT_URL: isaConstant.REPORT_URL,
        apiKey: "AIzaSyCCMDnRCxHGZslLe9q1v3edr4eHdsnkoZ8",
        HOMEPAGE: "https://isa-" + env + ".appspot.com",
        emailPattern: /^.+@.+\..+$/,

        system: isaConstant.system,
        Region: isaConstant.Region,
        country: isaConstant.country,
        Language: isaConstant.Language,
        documentSource: isaConstant.documentSource,
    };
    return AppConfig;
}

//constant parameter for upload api
// export class AppConstant {
//  system:any="ECD"; //dev
//   // system:any="ECQ";//uat
//  // system:any="ECP";//prod
//   Region :any="EMEA";
//   country: any="KA";
//   Language:any="EN";
//   documentSource:any="ISA";

// }
export class ISAConstants {
    BACKEND_API_URL = "";
    SERVICEDOC_URL = "";
    ESRP_URL = "";
    REPORT_URL: string = "";
    system: any = "";
    Region: any = "EMEA";
    country: any = "KA";
    Language: any = "EN";
    documentSource: any = "ISA";

    constructor(env: string) {
        console.log("******* initializing app constants *******  " + env);
        if (env == "prod") {
            this.BACKEND_API_URL =
                // "https://spare-part-test-dot-backendservice-dot-isa-dev.appspot.com";
                "https://backendservice-dot-isa-prod.ew.r.appspot.com";

            this.SERVICEDOC_URL =
                "https://ka.documents.whirlpool.com/wiseservice";
            this.ESRP_URL = "https://kitchenaid-esrp-prod.appspot.com";
            this.REPORT_URL = "https://datastudio.google.com/s/hhy6MwMInH4";
            this.system = "ECP";
        } else if (env == "uat") {
            //  this.BACKEND_API_URL='https://backendservice-dot-isa-uat.appspot.com';
            this.BACKEND_API_URL =
                " https://backendservice-dot-isa-dev.appspot.com";
            this.SERVICEDOC_URL =
                "https://qa.ka.documents.whirlpool.com/wiseservice";
            this.ESRP_URL = "https://kitchenaid-esrp-qa.appspot.com";
            this.REPORT_URL = "https://datastudio.google.com/s/nywrLts7xxs";
            this.system = "ECQ";
        } else {
            //dev
            this.BACKEND_API_URL =
                // "https://reporting-test-dot-backendservice-dot-isa-dev.appspot.com";  // deplay by SW
                "https://backendservice-dot-isa-dev.appspot.com"
            this.SERVICEDOC_URL =
                "https://qa.ka.documents.whirlpool.com/wiseservice";
            this.ESRP_URL = "https://kitchenaid-esrp-qa.appspot.com";
            this.REPORT_URL = "https://datastudio.google.com/s/isA8sWgmwJk";
            this.system = "ECD";
        }
        console.log("****** done *****");
    }
}
export const APPCONFIG = makeAppConfig();
