import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HelperService } from './../helper.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { RequestedInformation } from '../models/requested-information';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../shared/services/http.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Socrequest } from "../models/socrequest";
import { DomSanitizer } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Subject, ReplaySubject, BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { PlatformLocation } from '@angular/common';
import { APPCONFIG } from 'app/config';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent, ConfirmDialogComponent, ConfirmationDialogComponent, WarningDialogComponent } from '../dialog/dialog.component'
import { CommonService } from 'app/shared/services/common.service';
import { Location } from '@angular/common';
import { PermissionService } from 'app/shared/services/permission.service';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { BarcodeFormat } from '@zxing/library';



@Component({
  selector: 'app-check-in-repair',
  templateUrl: './check-in-repair.component.html',
  styleUrls: ['./check-in-repair.component.scss']
})
export class CheckInRepairComponent implements OnInit {
  @ViewChild(MatAutocompleteTrigger, { static: true }) inputAutoComplit: MatAutocompleteTrigger;
  @ViewChild('focusInput', { static: true }) focusInput: ElementRef;

  step = 0;
  inspectionErrorMsg: any;
  obsoleteMsg: string = "";
  actionPerformed: any;
  uploadFlag: boolean = false;
  dialogref: any;
  D2C: boolean=false;
  IS_4TH:boolean=false;
  hiddenstNumber: boolean=false;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step = 0;

  }

  color = 'primary';
  mode = 'determinate';
  value = 50;
  icon: boolean = false;
  iconexist: boolean = false;
  iconTogl: boolean = false;
  errorFetcheancode: boolean = false;

  click() {
    this.icon = !this.icon;
  }

  clickTogl() {
    this.iconTogl = !this.iconTogl;
  }
  textForm: FormGroup;
  requestForm: FormGroup;
  currentDate: Date;
  requestNo: string;
  socList: any = [];
  showError: boolean = false;
  showSaveButton = true;
  successMsg = "Successfully created SOC Number <scoid> for the appliance <applid> of the Customer <cname>"
  responseMsg = '';
  isSubmitted = false;
  resStatus = '';
  saveResponse: any = {};
  date: string;
  year: string;
  month: string;
  day: string;
  productId: string = '';
  filesList: any = [];
  numFiles: any;
  showUploadedFiles: boolean = false;
  caoruseExist: boolean = false;
  isuploadError: boolean = false;
  uploadErrorMsg: String = "";
  showingmediaError: boolean;
  showingMediaErrorMsg: any;
  showDocument: any = [];
  callLightBox: boolean;
  deviceInfo = null;
  isMobile: boolean;
  isTablet: boolean;
  isDesktopDevice: boolean;
  cameraOpen: boolean = false;
  userName: string = '';
  isinspectionError: boolean = false;
  EanCode: boolean = true;
  Mnumber: any;
  trayNumberFlag: boolean = false;
  holderNumberFlag: boolean = false;
  fp_nfp_flag: boolean = false;
  companyId='';
  isObsolete: boolean = false;
  //adding here
  scanStart:boolean=false; 
    scanSerialStart: boolean = false;
    qrResultString: string;
    availableDevices: MediaDeviceInfo[];
    hasDevices: boolean;
    qrResults: any=[];
    currentDevice: MediaDeviceInfo = null;
    hasPermission: boolean;
    torchAvailable$ = new BehaviorSubject<boolean>(false);
    torchEnabled = false;
    tryHarder = false;
  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];
  //ending here  

  //webcam variable start 
  // toggle webcam on/off
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];
  dataEancode: any;
  dataEancodeFilter: any;

  // latest snapshot
  public webcamImage: WebcamImage = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  //webcam end
  userLDapResonseRoles: any;
  sapEttexts: any
  spareTextCode: any

  qntyVal: String;
  sizeErrorMsg: string = '';
  modelNumberFlag: boolean = true;
  permissions = [];

  constructor(private location: Location,private commonService: CommonService, private fb: FormBuilder, private httpService: HttpService, private deviceService: DeviceDetectorService, private helperService: HelperService, private route: ActivatedRoute, private httpservice: HttpService, private spinner: NgxSpinnerService, private sanitizer: DomSanitizer, private router: Router, public dialog: MatDialog,
    private permissionsService: PermissionService,    private helperservice: HelperService  ) {
    this.route.queryParams.subscribe(params => {
      this.requestNo = params['requestNo'];
    });
    this.userLDapResonseRoles = JSON.parse(localStorage.getItem('loggedUserRoles'));
    /*

    if(this.userLDapResonseRoles.rolesMap.code.includes('TECH')

          ||  this.userLDapResonseRoles.rolesMap.code.includes('SA')

          ||  this.userLDapResonseRoles.rolesMap.code.includes('BA')) 

      {

      }else{

        

        // if(this.userLDapResonseRoles.rolesMap.code.includes('TECH')              

        // ||  this.userLDapResonseRoles.rolesMap.code.includes('SA')

        // ||  this.userLDapResonseRoles.rolesMap.code.includes('BA')

        // ){  

        //   this.router.navigate(['/scrap']);

        // }else{

          this.router.navigate(['/common']);

        // }

      }

*/
    this.permissions = JSON.parse(localStorage.getItem('permissions'))
    let permissionFlag = false;
    if(this.permissions !== null)
    this.permissions['checkInRepair'].toLowerCase() == 'true'? permissionFlag = true : permissionFlag = false;
    if(!permissionFlag) {
      this.commonService.openSnackBar("Permission to access this page is restricted, contact Administrator", "Close"); 
      this.location.back();
    }

    if ((this.requestNo == null || this.requestNo == undefined || this.requestNo == "")) {
      this.router.navigate(['checkin']);
      return;
    }
    this.diviceFind();
    this.userName = localStorage.getItem('userName');
    // location.onPopState(() => { console.log('pressed back!');
    // if($('.modal').hasClass('modal fade')) {
    // // alert("Modal is visible");
    //  this.helperService.hideAllModal()
    //    this.closeCam();
    //    console.log(window.location.href)
    // window.history.forward();
    // history.pushState(null, null, location.href);
    // window.onload=function(){null};
    // }
    //});
    if (this.httpservice.loginCheckSession()) { }
    else {
      this.httpservice.showSessionError();

    }
    this.companyId=this.permissionsService.companyId;

  }

  ngOnInit() {
    this.permissions = JSON.parse(localStorage.getItem('permissions'))
    // if (this.permissions !== null) {
    //   this.permissions['receiving.refurb.create_soc.tray_number'].toLowerCase() == 'true' ? this.trayNumberFlag = true : this.trayNumberFlag = false;
    //   this.permissions['receiving.refurb.create_soc.holder_number'].toLowerCase() == 'true' ? this.holderNumberFlag = true : this.holderNumberFlag = false;
    //   this.permissions['factory/non-factory-pack'].toLowerCase() == 'true' && this.companyId !='2002'? this.fp_nfp_flag = true : this.fp_nfp_flag = false;
    // }


    this.fromLoading();
    this.textForm = this.fb.group({
      tdid: ['', [Validators.required]],
      text: ['']
    });
    const data = localStorage.getItem("reqInfo");
    if (data == null || data == '') {
      this.fetchReturnOrder();

    } else {
      localStorage.removeItem("reqInfo");
      this.socList = JSON.parse(data).response;
      if (this.socList) {
        if (this.socList.sapReqNoDetail.esproductdetails.refproduct) {
          this.productId = this.socList.sapReqNoDetail.esproductdetails.refproduct.replace(/^0+/, '');
        }
        if (this.socList.sapReqNoDetail.ettexts) {
          this.sapEttexts = this.socList.sapReqNoDetail.ettexts
          // this.loadFilter();
        }
        if (this.socList.sapReqNoDetail.esproductdetails.crmpurchasedate) {
          var dt = this.socList.sapReqNoDetail.esproductdetails.crmpurchasedate;
          this.date = [dt.slice(0, 4), "-", dt.slice(4, 6), "-", dt.slice(6, 8)].join('');
        }
      }
      // this.setData();
      let postData = {
        "transactionId": this.requestNo, //this.soId
      };
      this.imageShow(postData);
    }
    this.fetchEancode();
    this.loadFilter();
    this.setAction();

  }
  fromLoading() {
    this.requestForm = this.fb.group({
      serialNumber: ['', [Validators.required, Validators.pattern(/^((229)$)|^((000)$)|(([y|Y]{1}[A-Za-z0-9]{1}(([0-9]{1}[0-9]{1})|([5]{1}[0-3]{1}))\d{2})|([W|w|Y|y]{1}[A-Za-z0-9]{1}(([0-9]{1}[0-9]{1})|([5]{1}[0-3]{1}))\d{5})|([W|w|Y|y]{1}[A-Za-z0-9]{1}[A-Za-z0-9](([0-9]{1}[0-9]{1})|([5]{1}[0-3]{1}))\d{5}))$/)]],
      modelNO: ['', []],
      item12NC: ['', []],
      comment: [''],
      trayNumber:['']
    });
    // if (this.fp_nfp_flag) {
    //   this.requestForm.addControl('factoryPack', new FormControl("", [Validators.required]));
    //   this.requestForm.controls['factoryPack'].updateValueAndValidity();
    // } else
    //   this.requestForm.removeControl('factoryPack');

    // if (this.holderNumberFlag) {
    //   this.requestForm.addControl('holderNumber', new FormControl("", [Validators.required, Validators.maxLength(9), Validators.pattern(/([S|s]{1}[T|t]{1}\d{6})/)]));
    //   this.requestForm.controls['holderNumber'].updateValueAndValidity();
    // } else
    //   this.requestForm.removeControl('holderNumber');

    // if (this.trayNumberFlag) {
    //   this.requestForm.addControl('trayNumber', new FormControl("", [Validators.required, Validators.min(100), Validators.max(999), Validators.minLength(3), Validators.maxLength(3)]));
    //   this.requestForm.controls['trayNumber'].updateValueAndValidity();
    // } else
    //   this.requestForm.removeControl('trayNumber');
    // this.requestForm.updateValueAndValidity();
  }


  loadFilter() {
    //   this.spareTextCode = this.textForm.get('tdid').valueChanges
    // .pipe(
    //   startWith(''),
    //   map(value => this._filterText(value))
    // );
    this.dataEancodeFilter = this.requestForm.get('modelNO').valueChanges
      .pipe(
        startWith(''),
        map(val => this._filter(val))
      );
    this.setData();
  }

  private _filter(value: string): string[] {
    if ((value === undefined || value === null) || (this.dataEancode === undefined || this.dataEancode === null))
      return;
    const filterValue = value.toLowerCase();
    var data = this.dataEancode.filter(option => String(option.matnr12nc).toLowerCase().indexOf(filterValue) > -1 ||
      option.commCode.toLowerCase().indexOf(filterValue) > -1);
    return data
  }

  // private _filterText(value: string): string[] {
  //   if ((value === undefined || value === null ) || (this.sapEttexts === undefined || this.sapEttexts === null ) ) 
  //   return ;
  //   const filterValue = value.toLowerCase();
  //   var data = this.sapEttexts.filter(option => String(option.tdid).toLowerCase().indexOf(filterValue) > -1 ||
  //     option.text.toLowerCase().indexOf(filterValue) > -1);
  //   return data
  // }






  fetchReturnOrder() {
    this.spinner.show();
    this.showError = false;
    let postData = {
      "requestNo": this.requestNo, //this.soId
    };
    this.httpService.postRequest('checkInOperationApi/v1/retrieveSOCRequestFromSAP', postData).subscribe(
      res => {
        if (res['status'] == 'SUCCESS') {
          this.socList = res['response'];
          if (this.socList) {
            if (this.socList.sapReqNoDetail.esproductdetails.refproduct) {
              this.productId = this.socList.sapReqNoDetail.esproductdetails.refproduct.replace(/^0+/, '');
            }
            if (this.socList.sapReqNoDetail.ettexts) {
              this.sapEttexts = this.socList.sapReqNoDetail.ettexts
              this.loadFilter();
            }
            if (this.socList.sapReqNoDetail.esproductdetails.crmpurchasedate) {
              var dt = this.socList.sapReqNoDetail.esproductdetails.crmpurchasedate;
              this.date = [dt.slice(0, 4), "-", dt.slice(4, 6), "-", dt.slice(6, 8)].join('');
            }
            //    this.setAction()
            //  this.setData();
            let postData = {
              "transactionId": this.requestNo, //this.soId
            };
            this.imageShow(postData);

          }

          //     this.year=this.date.substring(0,4);
          //  this.month=this.date.substring(4,6);
          //  this.day=this.date.substring(6,8);
        } else {
          this.responseMsg = res['response'];
          this.helperService.openStaticModal('ConfirmDialog')
          this.resStatus = 'SUCCESS';
        }
        this.spinner.hide();
      },
      err => {
        this.showError = true;
        // console.log("error occured while fetching appliances");
        this.spinner.hide();
      }
    );
  }
  openPanel(evt): void {
    evt.stopPropagation();
    this.inputAutoComplit.openPanel();
  }
   setData() {    
    if (this.socList.sapReqNoDetail ) {
      var strModel = this.socList.sapReqNoDetail.esproductdetails.zz0013;
      var res = strModel.split(" ");
      const d={'modelNO':res[0] ,'item12NC':this.productId,'serialNumber':this.socList.sapReqNoDetail.esproductdetails.zz0014?this.socList.sapReqNoDetail.esproductdetails.zz0014:''}; 
      this.requestForm.patchValue(d);
      this.requestForm.controls['item12NC'].disable();
      // setTimeout(()=>{
      //   this.requestForm.controls['item12NC'].disable();
      // },5000)

      // this.checkObsolete(res[0])
      //    this.requestForm.controls['serialNumber'].disable();
    }
    if (this.socList.trayId) {
      this.showSaveButton = false;
      const d = { 'trayNumber': this.socList.trayId, 'serialNumber': this.socList.serialNo };
      this.requestForm.patchValue(d);
      this.requestForm.disable();
    }
  }

  save() {
    this.requestForm.controls['item12NC'].enable();
    this.isSubmitted = true;
    if (this.requestForm.invalid) {
      this.helperService.makeFormDirty(this.requestForm);
      return;
    }
    let reqBody = this.requestForm.value;
    reqBody.requestNumber = this.requestNo;
    reqBody.crmCompanyId  = '0221706574';
    this.spinner.show();
    this.httpService.postRequest('checkInOperationApi/v1/updateInspection', reqBody).subscribe(
      res => {
        this.spinner.hide();
        this.resStatus = res['status']
        if (res['status'] == 'SUCCESS') {
          this.uploadFlag = true;
          this.uploadData()
          this.saveResponse = res['response'];
          var appId = reqBody.serialNumber;
          this.responseMsg = this.successMsg.replace('<scoid>', this.saveResponse.evsocid ? this.saveResponse.evsocid : '')
            .replace('<applid>', appId ? appId : '')
            .replace('<cname>', (this.saveResponse.evbpsurname ? this.saveResponse.evbpsurname : '') + " " + (this.saveResponse.evbpname ? this.saveResponse.evbpname : ''));
          this.requestForm.reset();
          this.helperService.hideModal('saveInspection')
          this.helperService.openStaticModal('ConfirmDialog')

        } else {
          this.isinspectionError = true;
          this.requestForm.controls['item12NC'].disable();
          this.inspectionErrorMsg = res['response'] || 'Something went wrong! Please try again later.'
          setTimeout(() => {
            this.isinspectionError = false;
          }, 5000)

          //         this.requestForm.reset();
          //   this.helperService.openStaticModal('ConfirmDialog')
        }

      },
      err => {
        this.isinspectionError = true;
        this.inspectionErrorMsg = err['response'] || 'Something went wrong! Please try again later.'
        this.requestForm.controls['item12NC'].disable();
        this.requestForm.reset();
        //  this.helperService.openStaticModal('ConfirmDialog')
        this.spinner.hide();
      }
    );
  }

  redirect(status) {
    this.helperService.hideModal('ConfirmDialog')
    if (status && status === 'SUCCESS')
      window.location.href = '#/checkin';
  }

  // IMAGE UPLOAD
  uploadImage(event: any) {
    this.currentDate = new Date();
    $('.isa-current-media').trigger('destroy.owl.carousel');
    $('#lightbox-current').trigger('destroy.owl.carousel');
    $('#lightbox-already').trigger('destroy.owl.carousel');
    setTimeout(() => {
      this.isaMedia();
    }, 10);
    this.numFiles = event.target.files.length;
    $(".isa-med-lenght").text(this.numFiles);
    $('.avatar-preview').css('display', 'flex');
    var files = event.target.files;
    // console.log("file",files);
    for (var i = 0; i < files.length; i++) {
      var f = files[i];
      f.mediaSRC = URL.createObjectURL(files[i]);
      this.filesList.push(f);
    }
    this.showUploadedFiles = true;
  }


  lightboxInit() {
    ($('.isa-current-media') as any).owlCarousel({
      margin: 0,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: true,
      items: 1
    });

    ($('.isa-media-slider') as any).owlCarousel({
      loop: false,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: true,
      margin: 0,
      items: 1
    });

    ($('#lightbox-already') as any).owlCarousel({
      margin: 10,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: false,
      autoHeight: true,
      items: 1
    });

    ($('#lightbox-current') as any).owlCarousel({
      margin: 10,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: false,
      autoHeight: true,
      items: 1
    });

    ($('#isa-allready-slider') as any).on('shown.bs.modal', function (e) {
      $('.modal-backdrop').addClass('isa-backdrop-modal');
    });

    ($('.lightboxGallery') as any).owlCarousel({
      autoplay: false,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: false,
      margin: 0,
      items: 1
    });

    ($('#lightbox') as any).lightGallery({
      thumbnail: false,
      mousewheel: false,
      autoplay: false,
      share: false,
      play: false,
      hash: false,
      zoom: false,
      autoplayControls: false,
      progressBar: false
    });

    setTimeout(() => {
      let self = this;
      $('.isa-media-slider .owl-next').on('click', function () { if (self.iconexist) { self.iconexist = !self.iconexist; } });
      $('.isa-media-slider .owl-prev').on('click', function () { if (self.iconexist) { self.iconexist = !self.iconexist; } });
    }, 200);

  }

  isaMedia() {
    ($('#lightbox-already') as any).owlCarousel({
      margin: 10,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: false,
      autoHeight: true,
      items: 1
    });

    ($('#lightbox-current') as any).owlCarousel({
      margin: 10,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: false,
      autoHeight: true,
      items: 1
    });

    this.caoruseExist = true;
    ($('.isa-current-media') as any).owlCarousel({
      margin: 0,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: true,
      items: 1
    });

    ($('.isa-media-slider') as any).owlCarousel({
      loop: false,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: true,
      margin: 0,
      items: 1
    });

    setTimeout(() => {
      let self = this;
      $('.isa-current-media .owl-next').on('click', function () { if (self.icon) { self.icon = !self.icon; } });
      $('.isa-current-media .owl-prev').on('click', function () { if (self.icon) { self.icon = !self.icon; } });
    }, 200);
  }

  getObjectUrl(file) {
    return URL.createObjectURL(file);
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  removeCam() {
    this.cameraOpen = false;
    this.helperService.hideModal('camera-media-dialog');
    this.filesList = [];
    this.webcamImage = null;
  }


  removeFile(file) {
    $('.isa-current-media').trigger('destroy.owl.carousel');
    $('#lightbox-current').trigger('destroy.owl.carousel');
    $('#lightbox-already').trigger('destroy.owl.carousel');
    let index = this.filesList.indexOf(file);
    this.filesList.splice(index, 1);
    if (this.filesList.length == 0) {
      this.webcamImage = null;
      this.helperService.hideModal("isa-current-slider");
      $('#chooseFile').val('');
    }
    //$(".isa-current-media").trigger('remove.owl.carousel', index).trigger('refresh.owl.carousel');
    setTimeout(() => {
      this.isaMedia();
    }, 10);
  }
  uploadData() {
    if (this.filesList.length == 0) {
      return;
    }

    var fd = new FormData();
    var applianceId = null;
    fd.append('userId', localStorage.getItem('userId'));
    fd.append('userName', this.userName);
    fd.append('system', APPCONFIG.system);
    fd.append('Region', APPCONFIG.Region);
    fd.append('country', APPCONFIG.country);
    fd.append('Language', APPCONFIG.Language);
    fd.append('documentSource', APPCONFIG.documentSource);
    fd.append('consumerId', this.socList.sapReqNoDetail.esbpdata.partnerid);
    if (this.socList.sapReqNoDetail.esproductdetails.productid) {
      applianceId = this.socList.sapReqNoDetail.esproductdetails.productid.replace(/^0+/, '');
    }
    fd.append('applianceId', applianceId);
    fd.append('transactionId', this.requestNo);
    for (var i = 0; i < this.filesList.length; i++) {
      var file = this.filesList[i]
      if (file.newName && file.newName != undefined && file.newName != "") {
        fd.append('files', file, file.newName);
      } else {
        fd.append('files', file);
      }
    }
    this.singleImageUpload(fd);
  }
  singleImageUpload(data) {
    if (!this.uploadFlag) {
      console.log('upload')
      this.spinner.show();
    }
    this.httpService.uploadPostRequest('document/uploadDocument', data).subscribe(
      res => {
        if (!this.uploadFlag) {
          console.log('upload hide')
          this.spinner.hide();
        }
        if (res['code'] != '200') {
          this.uploadErrorMsg = res['message'];
          //   this.isuploadError =true;
          if (!this.uploadFlag) {
            this.openInformationDialog(this.uploadErrorMsg);
          }
        } else if (res['code'] == '200') {
          this.isuploadError = false;
          var conformMsg = res['message'];
          if (!this.uploadFlag) {
            this.openInformationDialog(conformMsg);
            let postData = {
              "transactionId": this.requestNo, //this.soId
            };
            this.imageShow(postData);
          }

          this.filesList = [];
        } else {
          if (!this.uploadFlag) {
            var msg = 'Failed to process request. Please contact administrator.';
            this.openInformationDialog(msg);
          }
        }
      },
      err => {
        if (!this.uploadFlag) {
          console.log('upload hide')
          this.spinner.hide();
        }
        //  console.log("error occured while media upload");
        this.uploadErrorMsg = "error occured while media upload";
        this.isuploadError = true;
        //this.helperService.openModal('conform_Dialog');
      }
    );

  }

  imageShow(data) {
    $('#lightbox-already').trigger('destroy.owl.carousel');
    this.spinner.show();
    this.showDocument = [];
    this.httpService.downloadPostRequest('document/downloadDocument', data).subscribe(
      res => {
        this.spinner.hide();
        if (res['code'] != '200') {
          this.showingMediaErrorMsg = res['message'];
          this.showingmediaError = true;
        } else {
          this.showingmediaError = false;
          var documentData = res['data'];
          var conformMsg = res['message'];
          if (documentData != undefined && documentData != null && documentData.length > 0) {
            for (var i = 0; i < documentData.length; i++) {
              if (documentData[i].attachmentType != 'UPS_LABEL' &&
                (documentData[i].documentName.toLowerCase().includes('jpeg') ||
                  documentData[i].documentName.toLowerCase().includes('jpg') ||
                  documentData[i].documentName.toLowerCase().includes('png') ||
                  documentData[i].documentName.toLowerCase().includes('mp4'))
              ) {
                this.showDocument.push(documentData[i]);
              }
            }
            this.callLightBox = true;
            setTimeout(() => {
              this.lightboxInit();
            }, 1000);

          }

          this.spinner.hide();
        }
      },
      err => {
        //  console.log("error occured fetch image data ");
        this.showingMediaErrorMsg = "error in fetch image data";
        this.showingmediaError = true;
        this.spinner.hide();
      }
    );

  }
  diviceFind() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }
  openCamera() {
    this.cameraOpen = true;
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });

  }
  closeCam() {
    this.cameraOpen = false;
    this.helperService.hideModal('camera-media-dialog');
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }
  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    var imges = this.dataURItoBlob((webcamImage.imageAsDataUrl.substring(webcamImage.imageAsDataUrl.indexOf(',') + 1)));
    const imageFile = new File([imges], new Date().valueOf() + "" + Math.round(Math.random() * 500) + '.jpeg', { type: 'image/jpeg' });
    this.currentDate = new Date();
    $('.isa-current-media').trigger('destroy.owl.carousel');
    $('#lightbox-current').trigger('destroy.owl.carousel');
    $('#lightbox-already').trigger('destroy.owl.carousel');
    setTimeout(() => {
      this.isaMedia();
    }, 10);
    // this.numFiles = $("input:file")[0].files.length;
    // $(".isa-med-lenght").text(this.numFiles);
    $('.avatar-preview').css('display', 'flex');
    var f: any = imageFile;
    f.mediaSRC = URL.createObjectURL(imageFile);
    this.filesList.push(f);
    this.showUploadedFiles = true;
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  ngOnDestroy() {
    this.helperService.hideAllModal()
  }

  onselectModelValue(desc) {
    //   var codeFound =  this.sapEttexts.find(x => x.tdid == desc);
    const d = { 'text': desc };
    this.textForm.patchValue(d);
    this.textForm.controls['text'].disable();
  }
  fetchEancode() {
    this.errorFetcheancode = false;
    // if (modelNumber.toString().length == 0||modelNumber.toString().length == 2 ) {
    //   return;
    // }
    this.spinner.show();
    this.httpService.getRequest('checkInOperationApi/v1/getEanCodeModel?modelCode=' + '').subscribe(
      res => {
        this.spinner.hide();
        this.dataEancode = res['response']
        if (this.dataEancode.length > 0) {
          // const d={'item12NC': this.dataEancode.eancode};
          // this.applianceForm.patchValue(d);
          this.loadFilter();
        }
        else {
          this.errorFetcheancode = true;
        }
      },
      err => {
        alert("Invalid request");
        // console.log("error occured while fetching ean code");
        this.errorFetcheancode = true;
        this.spinner.hide();

      }
    );
  }
  focusOutModelNumber(code) {
    if (code == null || code == "") { code = undefined; }
    if (code != undefined) {
      var codeFound = this.dataEancode.find(x => x.commCode == code);
      if (codeFound != undefined && code == codeFound.commCode) {
        this.modelNumberFlag = true;
      }
      else {
        this.modelNumberFlag = false;
        this.obsoleteMsg = ""
      }
    }
  }

  onselectModelNumerValue(event) {
    this.obsoleteMsg = ""
    this.modelNumberFlag = event.option._selected;
    var codeFound = this.dataEancode.find(x => x.commCode == event.option.value);
    if (codeFound != undefined && event.option.value == codeFound.commCode) {
      const d = { 'item12NC': codeFound.matnr12nc };
      this.requestForm.patchValue(d);
      this.requestForm.controls['item12NC'].disable();
      // this.checkObsolete(codeFound.commCode)
    }
  }

  checkObsolete(commCode) {
    var codeFound = this.dataEancode.find(x => x.commCode == commCode);
    if (codeFound != undefined && commCode == codeFound.commCode) {
      if (codeFound.materialStatus && codeFound.materialStatus == '01') {
        // this.obsoleteMsg="This item is Obsolete phase-out (still stock)"
        this.obsoleteMsg = ""
      } else if (codeFound.materialStatus && codeFound.materialStatus == 'ZZ') {
        this.obsoleteMsg = "This item is Obsolete (out of range No stock)"
      } else if (codeFound.materialStatus && codeFound.materialStatus == 'ZD') {
        this.obsoleteMsg = "This item is Obsolete (BTO No stock )"
      } else if (codeFound.materialStatus && codeFound.materialStatus == 'ZI') {
        //this.obsoleteMsg="This item is Obsolete phase in ( Never arrived before in the warehouse)"
        this.obsoleteMsg = ""
      } else if (codeFound.materialStatus && codeFound.materialStatus == 'BL') {
        this.obsoleteMsg = "This item is Obsolete (delivery block due to supply issues)"
      } else {
        this.obsoleteMsg = ""
      }
      console.log('obslute msg', this.obsoleteMsg);
    }
  }
     
setAction(){
  if( this.socList.sapReqNoDetail && this.socList.sapReqNoDetail.etadditionalfields && JSON.stringify( this.socList).indexOf('FLOW') > -1){
    for (var i = 0; i < this.socList.sapReqNoDetail.etadditionalfields.length; i++) {
      if(this.socList.sapReqNoDetail && this.socList.sapReqNoDetail.etadditionalfields[i].key == 'FLOW')
        this.actionPerformed  =this.socList.sapReqNoDetail.etadditionalfields[i].value ;  
        if(this.socList.sapReqNoDetail && this.socList.sapReqNoDetail.etadditionalfields[i].key == 'IS_D2C')
          this.D2C  =true ;
        if(this.socList.sapReqNoDetail && this.socList.sapReqNoDetail.etadditionalfields[i].key == 'IS_4TH')
          this.IS_4TH  =true ;
      console.log(this.actionPerformed);
      }
  } 
  }
 
  openInformationDialog(msg) {
    this.dialogref = this.dialog.open(ConfirmationDialogComponent)
      .addPanelClass(['no-padding', 'custom-openconfromDialog', 'custom-mat-dialog'])
    this.dialogref.componentInstance.msg = msg;
    this.dialogref.componentInstance.isNotConformation = true;
  }
  deleteMedia(userId, ref, name) {
    // let postData = {
    //   "userId": userId,
    //   "userName": name,
    //   "documentRef": ref
    // };
    var data = new FormData();
    data.append('userId', userId);
    data.append('userName', name);
    data.append('documentRef', ref);
    console.log('data', data)
    this.spinner.show();
    this.httpService.uploadPostRequest('document/deleteDocument', data).subscribe(
      res => {
        this.spinner.hide();
        if (res['code'] == '200') {
          var msg = res['message'];
          this.openInformationDialog(msg);
          let postData = {
            "transactionId": this.requestNo, //this.soId
          };
          this.imageShow(postData);
        } else {
          if (res['code'] == '400') {
            var msg = res['message'];
            this.openInformationDialog(msg);
          } else if (res['code'] == '500') {
            var msg = res['message'];
            this.openInformationDialog(msg);
          } else if (res['code'] == '600') {
            var msg = res['message'];
            this.openInformationDialog(msg);
          }
          else {
            var msg = 'Failed to process request. Please contact administrator.';
            this.openInformationDialog(msg);
          }
        }
      },
      err => {
        var errorMsg = err;
        this.spinner.hide();

      }
    );
  }

  onFileChange(file, event) {
    var updateName = event.target.value;
    let index = this.filesList.indexOf(file);

    if ((updateName == null || updateName == undefined || updateName == "")) {
      this.filesList[index].newName = '';
      return;
    }
    // var fileExtension = '.' + file.name.split('.')[1];
    var fileExtension = '.' + file.name.split(".").pop();
    if (updateName.includes(fileExtension)) {
      this.filesList[index].newName = updateName;
    } else {
      this.filesList[index].newName = updateName + fileExtension;
    }
    console.log('newName', this.filesList[index].newName);
    console.log('file -- ' + index, this.filesList[index]);
  }

  UpdateMedia(userId, ref, username, oldFileName, newFileName) {
    if ((newFileName == null || newFileName == undefined || newFileName == "")) {
      newFileName = '';
      return;
    }
    if (oldFileName == newFileName) {
      return;
    }
    var fileName = this.updateFileName(oldFileName, newFileName);
    var data = new FormData();
    data.append('userId', userId);
    data.append('userName', username);
    data.append('documentRef', ref);
    data.append('documentName', fileName);

    console.log('data', data)
    this.spinner.show();
    this.httpService.uploadPostRequest('document/update', data).subscribe(
      res => {
        this.spinner.hide();
        if (res['code'] == '200') {
          var msg = res['message'];
          this.openInformationDialog(msg);
          let postData = {
            "transactionId": this.requestNo, //this.soId
          };
          this.imageShow(postData);
        } else {
          if (res['code'] == '400') {
            var msg = res['message'];
            this.openInformationDialog(msg);
          } else if (res['code'] == '500') {
            var msg = res['message'];
            this.openInformationDialog(msg);
          } else if (res['code'] == '600') {
            var msg = res['message'];
            this.openInformationDialog(msg);
          }
          else {
            var msg = 'Failed to process request. Please contact administrator.';
            this.openInformationDialog(msg);
          }
        }
      },
      err => {
        var errorMsg = err;
        this.spinner.hide();

      }
    );
  }
  updateFileName(oldFileName, newFileName) {
    // var fileExtension = '.' + file.name.split('.')[1];
    var fileExtension = '.' + oldFileName.split(".").pop();
    // if (!newFileName.includes(fileExtension)) {
    //   newFileName = newFileName + fileExtension;
    // }
    if (newFileName.includes(fileExtension)) {
      newFileName = newFileName.replace(fileExtension, '')
    }
    console.log('newName', newFileName);
    return newFileName;
  }
  omit_special_char(event)
{   
   var k;  
   k = event.charCode;  //         k = event.keyCode;  (Both can be used)
   return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 ||k == 46 ||k == 95|| k == 32 || (k >= 48 && k <= 57)); 
}

setInspectionAppliance(){
  /*** *** Commented bcoz Not required in isa-2022 enhancement ***  */
  // this.hiddenstNumber=false;
  //  if(this.D2C)
  // {
  // this.requestForm.get('holderNumber').setValidators([ Validators.maxLength(9) ,Validators.pattern(/([S|s]{1}[T|t]{1}\d{6})/)]); 
  // }else{
  //   this.hiddenstNumber=true;
  //   this.requestForm.get('holderNumber').clearValidators();
  // }
  // this.requestForm.get('holderNumber').updateValueAndValidity();
  this.helperService.openStaticModal('saveInspection');
  setTimeout(()=>{
    this.focus()
  },1000)
}
focus(){
  this.focusInput.nativeElement.focus();
}


scannedSerialNumber: string = '';

// // Open scanner modal
// openScanner() {
//     this.scanStart = true;
//     $('#barcodeScan').modal('show');
// }



// toggleTorch(): void {
//   this.torchEnabled = !this.torchEnabled;
// }

// // Close scanner modal
// closeScanner() {
//     this.scanStart = false;
//     $('#barcodeScan').modal('hide');
// }

// // Handle successful barcode scan
// onCodeResult(resultString: string) {
//     this.qrResultString = resultString;
//     this.scannedSerialNumber = resultString; // Autofill serial number field
//     this.requestForm.patchValue({ serialNumber: resultString });
//     this.closeScanner();
// }

// // Handle permission response
// onHasPermission(hasPermission: boolean) {
//     if (!hasPermission) {
//         alert('Camera permission denied. Please enable it in browser settings.');
//     }
// }

// // Handle camera detection
// // onCamerasFound(devices: MediaDeviceInfo[]) {
// //     if (devices.length > 0) {
// //         this.currentDevice = devices[0].deviceId; // Use first available camera
// //     }
// // }

// onCamerasFound(devices: MediaDeviceInfo[]): void {
//   this.availableDevices = devices;
//   this.hasDevices = Boolean(devices && devices.length);
// }
// // Handle torch compatibility
// onTorchCompatible(compatible: boolean) {
//     this.torchEnabled = compatible;
// }

onCodeResult(resultString: string) {
  console.log("Scanned code:", resultString);
  if (!resultString) {
      console.error("No barcode detected");
      return;
  }

  if (this.scanStart) {
      this.requestForm.get('holderNumber').setValue(resultString);
  } else if (this.scanSerialStart) {
      this.requestForm.get('serialNumber').setValue(resultString);
  }

  let audio = new Audio("assets/mp3/beep-02.mp3");
  audio.load();
  audio.play();

  this.spinner.show();
  setTimeout(() => {
      this.spinner.hide();
      this.closeScanner();
  }, 2000);
}

onCamerasFound(devices: MediaDeviceInfo[]): void {
this.availableDevices = devices;
this.hasDevices = Boolean(devices && devices.length);
}
onDeviceSelectChange(selected: string) {
const device = this.availableDevices.find(x => x.deviceId === selected);
this.currentDevice = device || null;
}

onHasPermission(has: boolean) {
this.hasPermission = has;
}

onTorchCompatible(isCompatible: boolean): void {
this.torchAvailable$.next(isCompatible || false);
}

toggleTorch(): void {
this.torchEnabled = !this.torchEnabled;
}

toggleTryHarder(): void {
this.tryHarder = !this.tryHarder;
}

closeScanner() {
this.scanStart = false;
this.scanSerialStart = false;
this.helperservice.hideModal("barcodeScan");
}
}