import { Component, OnInit, HostListener } from "@angular/core";
import { ArrayDataSource } from "@angular/cdk/collections";
import { FlatTreeControl } from "@angular/cdk/tree";
import { HttpService } from "app/shared/services/http.service";
import { PermissionService } from "app/shared/services/permission.service";

const TREE_DATA: ExampleFlatNode[] = [
  {
    name: 'Dashboard',
    expandable: true,
    level: 0,
    Url:'dashboard',
    icon:''
  }, {
    name: 'Repair/D2C Report',
    expandable: false,
    level: 1,
    Url:'dashboard',
    icon:'dashboard_alt'
  },
  {
    name: 'Return Report',
    expandable: false,
    level: 1,
    Url:'return_dashboard',
    icon:'dashboard_alt'
  },
  {
    name: 'Manager Report',
    expandable: false,
    level: 1,
    Url:'mreport',
    icon:'dashboard_alt'
  },
  {
    name: 'Waiting Wall Report',
    expandable: false,
    level: 1,
    Url:'missing-spares-report',
    icon:'dashboard_alt'
  }
];

/** Flat node with expandable and level information */
interface ExampleFlatNode {
    expandable: boolean;
    name: string;
    level: number;
    Url: string;
    isExpanded?: boolean;
    icon: string;
}
@Component({
    selector: "app-sidenav",
    templateUrl: "./sidenav.component.html",
    styleUrls: ["./sidenav.component.scss"],
})
export class SidenavComponent implements OnInit {

    public dashboad: boolean;
    public receiving: boolean;
    public repair_refurb: boolean;
    public packing: boolean;
    public scrap: boolean;
    public tray: boolean;
    public logs: boolean;
    public tray_logs: boolean;
    public user_management: boolean;
    public user_manual:boolean=false;
    permissions = [];
    public special_projects:boolean=true;

    treeControl = new FlatTreeControl<ExampleFlatNode>(
        (node) => node.level,
        (node) => node.expandable
    );
    companyId='';
    dataSource = new ArrayDataSource(TREE_DATA);
    constructor(private permissionsService: PermissionService) { }

    ngOnInit() {
        if(localStorage.getItem('loginSessionExit') && localStorage.getItem('loggedUserRoles'))
        this.getPermissions();
        this.userLDapResonseRoles = JSON.parse(
            localStorage.getItem("loggedUserRoles")
          );
        var string = "";
        var substring = "Admin";
        string.indexOf(substring) !== -1;


        this.companyId=this.permissionsService.companyId;
        this.permissionsService.oncompanyChange.subscribe({
            next: (event) => {
                // setTimeout(()=>{
                    this.companyId=this.permissionsService.companyId;
                    console.log(`Received message #${this.companyId}`);
                // })
            }
        })
    }

    hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

    getParentNode(node: ExampleFlatNode) {
        const nodeIndex = TREE_DATA.indexOf(node);

        for (let i = nodeIndex - 1; i >= 0; i--) {
            if (TREE_DATA[i].level === node.level - 1) {
                return TREE_DATA[i];
            }
        }

        return null;
    }

    shouldRender(node: ExampleFlatNode) {
        let parent = this.getParentNode(node);
        while (parent) {
            if (!parent.isExpanded) {
                return false;
            }
            parent = this.getParentNode(parent);
        }
        return true;
    }

    userLDapResonseRoles: any;
    @HostListener("window:resize")

    updateOrientatioState() {
        this.overLay();
        if (window.innerHeight > window.innerWidth) {
            console.log("portrait");
            $("#Left-Nav").removeClass("is-active");
        } else {
            console.log("landscape");
            if ($("#Left-Nav").hasClass("active") == true) {
                $("#Left-Nav").addClass("is-active");
                $("#main-event").addClass("active");
            } else {
                $("#Left-Nav").removeClass("active");
                $("#main-event").removeClass("active");
            }
        }
    }

    wasClicked = false;
    onClick() {
        this.wasClicked = !this.wasClicked;
        if (
            this.userLDapResonseRoles.userId != localStorage.getItem("userId")
        ) {
            window.location.reload();
        }
    }

    activeTag() {
        if (
            $("#Left-Nav").hasClass("active") == true ||
            $("#Left-Nav").hasClass("is-active") == true ||
            $(".isa-theme-page-title-event").hasClass("active") == true
        ) {
            $("#main-event").addClass("active");
            $(".isa-theme-page-title-event").addClass("active");
            $(".sidenav-overlay").removeClass("active");
            $("#Left-Nav").removeClass("active");
        }
    }

    async getPermissions() {
        this.user_manual= false;
        const resp = this.permissionsService.permissionData?this.permissionsService.permissionData : await this.permissionsService.checkPermissions();
        let permissions = resp['items']
        localStorage.setItem("permissions", JSON.stringify(resp['items']));
        permissions['dashboad'].toLowerCase() == 'true' ? this.dashboad = true : this.dashboad = false;
        permissions['receiving'].toLowerCase() == 'true' ? this.receiving = true : this.receiving = false;
        permissions['packing'].toLowerCase() == 'true' ? this.packing = true : this.packing = false;
        permissions['scrap'].toLowerCase() == 'true' ? this.scrap = true : this.scrap = false;
        permissions['tray'].toLowerCase() == 'true' ? this.tray = true : this.tray = false;
        permissions['logs'].toLowerCase() == 'true' ? this.logs = true : this.logs = false;
        permissions['tray_logs'].toLowerCase() == 'true' ? this.tray_logs = true : this.tray_logs = false;
        permissions['repair_refurb'].toLowerCase() == 'true' ? this.repair_refurb = true : this.repair_refurb = false;
        permissions['user-managment'].toLowerCase() == 'true' ? this.user_management = true : this.user_management = false;
        permissions['special_projects'] == 'true' ? this.special_projects = true : this.special_projects = true;
        this.user_manual = true;
        this.setDashbordMenuPermisson(permissions);
    }
    setDashbordMenuPermisson(data){
        if(data){
            TREE_DATA.forEach(element => {
                if(element.name=="Repair/D2C Report"){
                    data['dashboad.repair_d2c_reports'].toLowerCase()=='true'? element['permission']=true:element['permission']=false;
                }
                if(element.name=="Return Report"){
                    data['dashboard.return_report'].toLowerCase()=='true'? element['permission']=true:element['permission']=false;
                }
                if(element.name=="Manager Report"){
                    data['dashboard.manage_report'].toLowerCase()=='true'? element['permission']=true:element['permission']=false;
                }
            });
        }
       this.dataSource = new ArrayDataSource(TREE_DATA);
    }
    redirect(path) {
        window.location.href = path;
    }

    overLay() {
        $(this).removeClass("active");
        $("#Left-Nav").removeClass("active");
        $(".sidenav-overlay").removeClass("active");
        $(".isa-theme-page-title-event").removeClass("active");
    }
}
