import { Component, OnInit, ViewChild, Inject, ChangeDetectorRef, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective } from '@angular/forms';
import { HttpService } from "../../shared/services/http.service";
import { NgxSpinnerService } from "ngx-spinner";
import { HelperService } from 'app/helper.service';
import { prepareEventListenerParameters } from '@angular/compiler/src/render3/view/template';
import { BehaviorSubject } from 'rxjs';
import { BarcodeFormat } from '@zxing/library';


@Component({
  selector: 'standard-inspection',
  templateUrl: './standard-inspection.component.html',
  styleUrls: ['./standard-inspection.component.scss'],
})


export class StandardInspectionComponent implements OnInit {

  @ViewChild('userDform', { static: true }) form;
  standardInspectionForm: FormGroup;
  isChecked: boolean = true;
  ok: any;
  notOk: any;
  showClassification: boolean = false;
  showProblemFixed: boolean = false;
  showMixerZone:boolean = false;
  filesList: any = [];
  firstParam: any;
  projectItemCodes: any;
  responseMessage: any;
  classificationValues:any = [];
  mixerzoneValues:any=[];
  inspectionType:any;
  projectName:any = "";
  projectCategoryCode:any;
  fileSizeError: string = '';
  scanStart:boolean=false; 
  scanSerialStart: boolean = false;
  qrResultString: string;
  availableDevices: MediaDeviceInfo[];
  hasDevices: boolean;
  qrResults: any=[];
  currentDevice: MediaDeviceInfo = null;
  hasPermission: boolean;
  torchAvailable$ = new BehaviorSubject<boolean>(false);
  torchEnabled = false;
  tryHarder = false;
  recordCount: any;
    totalCount: any;
    category: any;

  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];
  // Barcode scanning variables end

  constructor(private route: ActivatedRoute, private fb: FormBuilder, private httpService: HttpService, private spinner: NgxSpinnerService, private helperservice: HelperService, private cdr: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.firstParam = this.route.snapshot.queryParamMap.get('projectID');
    this.getProjectDetails();
    this.getClassifications();
    this.getMixerZoneValues();

    this.standardInspectionForm = this.fb.group({
      itemcode: ['', [Validators.required]],
      holderNumber: ['', [Validators.required, Validators.maxLength(20), Validators.minLength(9)]],
      serialNumber: [''],
      label: ['OK', [Validators.required]],
      classification: [''],
      description:[''],
      problem: ['NO'],
      mixerzone:['']
    });
    // this.standardInspectionForm.reset();
    
  }

  getProjectDetails() {
    this.spinner.show();
    this.httpService.getRequest('specialProjectApi/v1/projectDetails?projectId=' + this.firstParam).subscribe(
      res => {
        this.spinner.hide();
        if(res['status'] === 'ERROR'){
          this.responseMessage = res['errorMessage'];
          this.helperservice.openStaticModal('ConfirmDialog');
          return;
        }
        this.projectItemCodes = res['projectDetails']['projectItemCodeMappingDtoList'];
        this.inspectionType =res['projectDetails']['inspectionType'];
        this.projectName = res['projectDetails']['projectName'];
        this.projectCategoryCode = res['projectDetails']['categoryCode'];
        this.category = res['projectDetails']['category'];
        this.recordCount = res['projectDetails']['recordCount'];
        this.totalCount = res['projectDetails']['totalQuantity'];
        // this.checkCategoryForMixerZone();
      },
      err => {
        // alert("Internal issue to fetch the information");
        this.spinner.hide();
        this.responseMessage = "Internal issue to fetch the information";
        this.helperservice.openStaticModal('ConfirmDialog');
      }
    );
  }
  getClassifications(){
    this.httpService.getRequest('specialProjectApi/v1/getClassificationForTechnicians').subscribe(
      res => {
        this.classificationValues = res['items'];
      },
      err => {
        // console.log("no classificaton found");
        this.responseMessage = "No classification found.";
        this.helperservice.openStaticModal('ConfirmDialog');
      }
    );
  }

  getMixerZoneValues(){
    this.httpService.getRequest('specialProjectApi/v1/getMixerZones').subscribe(
      res => {
        this.mixerzoneValues = res['items'];
      },
      err => {
        // console.log("no classificaton found");
        this.responseMessage = "No classification found.";
        this.helperservice.openStaticModal('ConfirmDialog');
      }
    );
  }

  categoryCode:any;
  mixerCategorySet:any =["F89","G11"];

  qualityOk(){
    console.log("ok");
    this.showClassification = false;
    this.showProblemFixed=  false;
    this.showMixerZone = false;
    this.mixerzoneValues=[];
    this.standardInspectionForm.controls['classification'].clearAsyncValidators();
    this.standardInspectionForm.controls['classification'].updateValueAndValidity();
    this.standardInspectionForm.controls['mixerzone'].clearAsyncValidators();
    this.standardInspectionForm.controls['mixerzone'].updateValueAndValidity();
  }

  qualityNotOk(){
    // console.log("not ok");
    // this.showClassification = true;
    // this.showProblemFixed=  true;
    // // this.showMixerZone = true;
    // this.showMixerZone = this.mixerCategorySet.includes(this.projectCategoryCode);
    // if(this.showMixerZone){
    //   this.getMixerZoneValues();
    // }
    // this.standardInspectionForm.controls['classification'].setValidators([Validators.required]);
    // this.standardInspectionForm.controls['classification'].updateValueAndValidity();
    // this.standardInspectionForm.controls['mixerzone'].setValidators([Validators.required]);
    // this.standardInspectionForm.controls['mixerzone'].updateValueAndValidity();

    console.log("not ok");
        this.showClassification = true;
        this.showProblemFixed = true;
        
        if (this.mixerCategorySet.includes(this.projectCategoryCode)) {
            this.showMixerZone = true;
            this.getMixerZoneValues();
            this.standardInspectionForm.controls["mixerzone"].setValidators([Validators.required]);
        } else {
            this.showMixerZone = false;
            this.standardInspectionForm.controls["mixerzone"].clearValidators();
        }
        this.standardInspectionForm.controls["mixerzone"].updateValueAndValidity();
    
        this.standardInspectionForm.controls["classification"].setValidators([Validators.required]);
        this.standardInspectionForm.controls["classification"].updateValueAndValidity();
    
        this.cdr.detectChanges();
  }

 

  standardInspectionSubmitForm(formDirective: FormGroupDirective) {
    if (!this.standardInspectionForm.valid) {
      this.helperservice.makeFormDirty(this.standardInspectionForm);
      console.log("validation failed");
      return;
      }

      const fd = new FormData();
      fd.append('userId', 'ISA_SPECIAL_PROJECT');

      if (this.filesList.length > 0) {
          for (let file of this.filesList) {
              fd.append('files', file);
          }
          this.uploadFiles(fd, formDirective);
      } else {
          this.saveStandardInspectionRecord([], formDirective);
      }
      }

      uploadImage(event: any) {
        var files = event.target.files;
        console.log(files);
    
        this.fileSizeError = '';
    
        for (var i = 0; i < files.length; i++) {
            var f = files[i];
            if (f.size > 5 * 1024 * 1024) {
                this.fileSizeError = `File ${f.name} exceeds the 5MB size limit.`;
                continue;
            }
    
            f.mediaSRC = URL.createObjectURL(f);
            this.filesList.push(f);
            console.log(this.filesList);
        }
    }

  closePopup() {
    this.helperservice.hideModal('ConfirmDialog');
  }

  // singleImageUpload1(data, formDirective) {
  //   this.spinner.show();
  //   this.httpService.uploadImageRequest('specialProject/uploadFiles', data).subscribe(
  //     res => {
  //       var inspFrom = this.standardInspectionForm.value;
  //       let postData = {
  //         "projectId": this.firstParam,
  //         "itemCode": inspFrom.itemcode,
  //         "inspectionType":this.inspectionType,
  //         "holderNumber": inspFrom.holderNumber,
  //         "serialNumber": inspFrom.serialNumber,
  //         "quality": inspFrom.label,
  //         "description": inspFrom.description?inspFrom.description:"",
  //         "zoneList":inspFrom.mixerzone?inspFrom.mixerzone:[],
  //         "problemFixedStatus":inspFrom.problem,
  //         "createdBy": localStorage.getItem('userName'),
  //         "fileUrls": res['fileUrl']
  //       };
  //       if(inspFrom.classification != null && inspFrom.classification != "" ) {
  //         postData["classification"] = inspFrom.classification
  //     }
  //       console.log("RecordStandardInspectionDto : "+postData);
  //       this.httpService.postRequest('specialProjectApi/v1/saveStandardInspectionRecord', postData).subscribe(
  //         res => {
  //           this.spinner.hide();
  //           if (res['status'] = 'SUCCESS') {
  //             this.responseMessage = res['response'];
  //             this.standardInspectionForm.reset();
  //             formDirective.resetForm();
  //             this.standardInspectionForm.controls['label'].setValue('OK');
  //             this.standardInspectionForm.controls['problem'].setValue('YES');
  //             this.helperservice.openStaticModal('ConfirmDialog');
  //           }else {
  //             this.responseMessage = res['response'];
  //             this.helperservice.openStaticModal('ConfirmDialog');
  //           }
  //         },
  //         err => {
  //           this.spinner.hide();
  //           // console.log("error block");
  //           this.responseMessage = err.response;
  //           this.helperservice.openStaticModal('ConfirmDialog');
  //         }
  //       );
  //     },
  //     err => {
  //       this.spinner.hide();
  //       this.responseMessage = "Error occurred during file upload.";
  //       this.helperservice.openStaticModal('ConfirmDialog');
  //     }
  //   );
  // }

  uploadFiles(data: FormData, formDirective: FormGroupDirective) {
    this.spinner.show();
    this.httpService.uploadImageRequest('specialProject/uploadFiles', data,"STANDARD_INSPECTION").subscribe(
        res => {
            this.saveStandardInspectionRecord(res['fileUrl'], formDirective);
        },
        err => {
            this.spinner.hide();
            this.responseMessage = "Error occurred during file upload.";
            this.helperservice.openStaticModal('ConfirmDialog');
        }
    );
}

saveStandardInspectionRecord(fileUrls: string[], formDirective: FormGroupDirective) {
    this.spinner.show();
    const formData = this.standardInspectionForm.value;

    let postData = {
        projectId: this.firstParam,
        itemCode: formData.itemcode,
        inspectionType: this.inspectionType,
        holderNumber: formData.holderNumber,
        serialNumber: formData.serialNumber,
        quality: formData.label,
        description: formData.description ? formData.description : "",
        zoneList: formData.mixerzone ? formData.mixerzone : [],
        problemFixedStatus: formData.problem,
        createdBy: localStorage.getItem('userName'),
        fileUrls: fileUrls
    };

    if (formData.classification != null && formData.classification !== "") {
        postData["classification"] = formData.classification;
    }

    this.spinner.show();
    this.httpService.postRequest('standardInspectionApi/v1/saveStandardInspectionRecord', postData).subscribe(
        res => {
            this.spinner.hide();
            if (res['status'] === 'SUCCESS') {
                this.responseMessage = res['response'];
                // this.standardInspectionForm.reset();
                // formDirective.resetForm();
                const currentHolder = formData.holderNumber;
                const defaultValues = {
                  itemcode: '',
                  holderNumber: currentHolder,
                  serialNumber: '',
                  label: 'OK',
                  classification: '',
                  description: '',
                  problem: 'YES',
                  mixerzone: ''
                };
                this.standardInspectionForm.reset(defaultValues);
                formDirective.resetForm(defaultValues);
                this.filesList = [];
              const fileInput = document.getElementById("chooseFile") as HTMLInputElement;
              if (fileInput) {
                fileInput.value = "";
              }
                this.showClassification = false;
                this.showProblemFixed = false;
                this.standardInspectionForm.controls['label'].setValue('OK');
                this.standardInspectionForm.controls['problem'].setValue('YES');
                this.helperservice.openStaticModal('ConfirmDialog');
            } else {
                this.responseMessage = res['response'];
                this.helperservice.openStaticModal('ConfirmDialog');
            }
        },
        err => {
            this.spinner.hide();
            this.responseMessage = err.response;
            this.helperservice.openStaticModal('ConfirmDialog');
        }
    );
}

onCodeResult(resultString: string) {
  console.log("Scanned code:", resultString);
  if (!resultString) {
      console.error("No barcode detected");
      return;
  }

  if (this.scanStart) {
      this.standardInspectionForm.get('holderNumber').setValue(resultString);
  } else if (this.scanSerialStart) {
      this.standardInspectionForm.get('serialNumber').setValue(resultString);
  }

  let audio = new Audio("assets/mp3/beep-02.mp3");
  audio.load();
  audio.play();

  this.spinner.show();
  setTimeout(() => {
      this.spinner.hide();
      this.closeScanner();
  }, 2000);
}

onCamerasFound(devices: MediaDeviceInfo[]): void {
this.availableDevices = devices;
this.hasDevices = Boolean(devices && devices.length);
}
onDeviceSelectChange(selected: string) {
const device = this.availableDevices.find(x => x.deviceId === selected);
this.currentDevice = device || null;
}

onHasPermission(has: boolean) {
this.hasPermission = has;
}

onTorchCompatible(isCompatible: boolean): void {
this.torchAvailable$.next(isCompatible || false);
}

toggleTorch(): void {
this.torchEnabled = !this.torchEnabled;
}

toggleTryHarder(): void {
this.tryHarder = !this.tryHarder;
}

closeScanner() {
this.scanStart = false;
this.scanSerialStart = false;
this.helperservice.hideModal("barcodeScan");
} 
}