import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { HttpService } from "../../shared/services/http.service";
import { HelperService } from 'app/helper.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BarcodeFormat } from '@zxing/library';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'oti-checks',
  templateUrl: './oti-checks.component.html',
  styleUrls: ['./oti-checks.component.scss'],
})
export class OtiChecksComponent implements OnInit {

  @ViewChild('userDform', { static: true }) form;
  otiChecksForm: FormGroup;
  isChecked: boolean = true;
  showMixerZoneValues: boolean = false;
  showMixerZone: boolean = false;
  firstParam: any;
  projectItemCodes: any;
  responseMessage: any;
  showClassification: boolean = false;
  showProblemFixed: boolean = false;
  filesList: any = [];
  classificationValues: any = [];
  mixerzoneValues: any = [];
  categoryCode: any = "";
  projectName: any = "";
  fileSizeError: string = '';
  scanStart:boolean=false; 
    scanSerialStart: boolean = false;
    qrResultString: string;
    availableDevices: MediaDeviceInfo[];
    hasDevices: boolean;
    qrResults: any=[];
    currentDevice: MediaDeviceInfo = null;
    hasPermission: boolean;
    torchAvailable$ = new BehaviorSubject<boolean>(false);
    torchEnabled = false;
    tryHarder = false;
    recordCount: any;
    totalCount: any;
    category: any;
  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private httpService: HttpService,
    private spinner: NgxSpinnerService,
    private helperservice: HelperService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.firstParam = this.route.snapshot.queryParamMap.get('projectID');
    this.getProjectDetails();
    this.getClassifications();
    this.getMixerZoneValues();

    this.otiChecksForm = this.fb.group({
      itemcode: ['', [Validators.required]],
      holderNumber: ['', [Validators.required, Validators.maxLength(20), Validators.minLength(9)]],
      serialNumber: [''],
      label: ['OK', [Validators.required]],
      classification: [''],
      description: [''],
      problem: ['YES'],
      mixerzone: ['']
    });

    this.categoryCode = "";
  }

  getProjectDetails() {
    this.spinner.show();
    this.httpService.getRequest(`specialProjectApi/v1/projectDetails?projectId=${this.firstParam}`).subscribe(
      res => {
        this.spinner.hide();
        if (res['status'] === 'ERROR') {
          this.responseMessage = res['errorMessage'];
          this.helperservice.openStaticModal('ConfirmDialog');
          return;
        }
        this.projectItemCodes = res['projectDetails']['projectItemCodeMappingDtoList'];
        this.categoryCode = res['projectDetails']['categoryCode'];
        this.category = res['projectDetails']['category'];
        this.recordCount = res['projectDetails']['recordCount'];
        this.totalCount = res['projectDetails']['totalQuantity'];
        this.projectName = res['projectDetails']['projectName'];
        this.showMixerZoneValues = this.categoryCode === "F89" || this.categoryCode === "G11";
      },
      err => {
        this.spinner.hide();
        this.responseMessage = "Internal issue to fetch the information";
        this.helperservice.openStaticModal('ConfirmDialog');
      }
    );
  }

  getClassifications() {
    this.httpService.getRequest('specialProjectApi/v1/getClassificationForTechnicians').subscribe(
      res => {
        this.classificationValues = res['items'];
      },
      err => {
        this.responseMessage = "No classification found due to an error.";
        this.helperservice.openStaticModal('ConfirmDialog');
      }
    );
  }

  getMixerZoneValues() {
    this.httpService.getRequest('specialProjectApi/v1/getMixerZones').subscribe(
      res => {
        this.mixerzoneValues = res['items'];
      },
      err => {
        this.responseMessage = "No classification found due to an error.";
        this.helperservice.openStaticModal('ConfirmDialog');
      }
    );
  }

  projectCategoryCode:any;
  mixerCategorySet:any =["F89","G11"];

  qualityOk() {
    this.showClassification = false;
    this.showProblemFixed=  false;
    this.showMixerZone = false;
    this.mixerzoneValues=[];
    this.otiChecksForm.controls['classification'].clearValidators();
    this.otiChecksForm.controls['classification'].updateValueAndValidity();
    this.otiChecksForm.controls['mixerzone'].clearValidators();
    this.otiChecksForm.controls['mixerzone'].updateValueAndValidity();
  }

  qualityNotOk() {
    // this.showClassification = true;
    // this.showProblemFixed=  true;
    // // this.showMixerZone = true;
    // this.showMixerZone = this.categoryCode === 'F89' || this.categoryCode === 'G11';
    // if(this.showMixerZone){
    //   this.getMixerZoneValues();
    // }
    // this.otiChecksForm.controls['classification'].setValidators([Validators.required]);
    // this.otiChecksForm.controls['classification'].updateValueAndValidity();
    // this.otiChecksForm.controls['mixerzone'].setValidators([Validators.required]);
    // this.otiChecksForm.controls['mixerzone'].updateValueAndValidity();

    console.log("not ok");
        this.showClassification = true;
        this.showProblemFixed = true;
        
        if (this.mixerCategorySet.includes(this.projectCategoryCode)) {
            this.showMixerZone = true;
            this.getMixerZoneValues();
            this.otiChecksForm.controls["mixerzone"].setValidators([Validators.required]);
        } else {
            this.showMixerZone = false;
            this.otiChecksForm.controls["mixerzone"].clearValidators();
        }
        this.otiChecksForm.controls["mixerzone"].updateValueAndValidity();
    
        this.otiChecksForm.controls["classification"].setValidators([Validators.required]);
        this.otiChecksForm.controls["classification"].updateValueAndValidity();
    
        this.cdr.detectChanges();

  }

  otiChecksSubmitForm(formDirective: FormGroupDirective) {
    if (!this.otiChecksForm.valid) {
      this.helperservice.makeFormDirty(this.otiChecksForm);
      return;
    }

    const fd = new FormData();
    fd.append('userId', 'ISA_SPECIAL_PROJECT');

    if (this.filesList.length > 0) {
      for (let file of this.filesList) {
        fd.append('files', file);
      }
      this.uploadFiles(fd, formDirective);
    } else {
      this.saveOtiCheckRecord([], formDirective);
    }
  }

  


  uploadImage(event: any) {
    var files = event.target.files;
    console.log(files);

    this.fileSizeError = '';

    for (var i = 0; i < files.length; i++) {
        var f = files[i];
        if (f.size > 5 * 1024 * 1024) {
            this.fileSizeError = `File ${f.name} exceeds the 5MB size limit.`;
            continue;
        }

        f.mediaSRC = URL.createObjectURL(f);
        this.filesList.push(f);
        console.log(this.filesList);
    }
}

  closePopup() {
    this.helperservice.hideModal('ConfirmDialog');
  }

  uploadFiles(data: FormData, formDirective: FormGroupDirective) {
    this.spinner.show();
    this.httpService.uploadImageRequest('specialProject/uploadFiles', data,"OTI_CHECK").subscribe(
      res => {
        this.saveOtiCheckRecord(res['fileUrl'], formDirective);
      },
      err => {
        this.spinner.hide();
        this.responseMessage = "Error occurred during file upload.";
        this.helperservice.openStaticModal('ConfirmDialog');
      }
    );
  }

  saveOtiCheckRecord(fileUrls: string[], formDirective: FormGroupDirective) {
    this.spinner.show();
    const formData = this.otiChecksForm.value;
    const currentHolder = formData.holderNumber;
    let postData = {
      projectId: this.firstParam,
      itemCode: formData.itemcode,
      holderNumber: formData.holderNumber,
      serialNumber: formData.serialNumber,
      quality: formData.label,
      description: formData.description || "",
      zoneList: formData.mixerzone || [],
      problemFixedStatus: formData.problem,
      createdBy: localStorage.getItem('userName'),
      fileUrls: fileUrls
    };

    if (formData.classification) {
      postData["classification"] = formData.classification;
    }

    this.httpService.postRequest('otiCheckApi/v1/saveOtiCheckRecord', postData).subscribe(
      res => {
        this.spinner.hide();
        if (res['status'] === 'SUCCESS') {
          this.responseMessage = res['response'];
          // this.otiChecksForm.reset();
          // formDirective.resetForm();
          // this.otiChecksForm.controls['label'].setValue('OK');
          // this.otiChecksForm.controls['problem'].setValue('YES');
          const defaultValues = {
            itemcode: '',
            holderNumber: currentHolder,
            serialNumber: '',
            label: 'OK',
            classification: '',
            description: '',
            problem: 'YES',
            mixerzone: ''
          };
          
          this.otiChecksForm.reset(defaultValues);
          formDirective.resetForm(defaultValues);
          
          this.filesList = [];
          const fileInput = document.getElementById("chooseFile") as HTMLInputElement;
          if (fileInput) {
            fileInput.value = "";
          }
          this.showClassification = false;
          this.showProblemFixed = false;
          this.showMixerZone = false;
          this.helperservice.openStaticModal('ConfirmDialog');
        } else {
          this.responseMessage = res['response'];
          this.helperservice.openStaticModal('ConfirmDialog');
        }
      },
      err => {
        this.spinner.hide();
        this.responseMessage = err.response;
        this.helperservice.openStaticModal('ConfirmDialog');
      }
    );
  }
  
  onCodeResult(resultString: string) {
    console.log("Scanned code:", resultString);
    if (!resultString) {
        console.error("No barcode detected");
        return;
    }
  
    if (this.scanStart) {
        this.otiChecksForm.get('holderNumber').setValue(resultString);
    } else if (this.scanSerialStart) {
        this.otiChecksForm.get('serialNumber').setValue(resultString);
    }
  
    let audio = new Audio("assets/mp3/beep-02.mp3");
    audio.load();
    audio.play();
  
    this.spinner.show();
    setTimeout(() => {
        this.spinner.hide();
        this.closeScanner();
    }, 2000);
  }
  
  onCamerasFound(devices: MediaDeviceInfo[]): void {
  this.availableDevices = devices;
  this.hasDevices = Boolean(devices && devices.length);
  }
  onDeviceSelectChange(selected: string) {
  const device = this.availableDevices.find(x => x.deviceId === selected);
  this.currentDevice = device || null;
  }
  
  onHasPermission(has: boolean) {
  this.hasPermission = has;
  }
  
  onTorchCompatible(isCompatible: boolean): void {
  this.torchAvailable$.next(isCompatible || false);
  }
  
  toggleTorch(): void {
  this.torchEnabled = !this.torchEnabled;
  }
  
  toggleTryHarder(): void {
  this.tryHarder = !this.tryHarder;
  }
  
  closeScanner() {
  this.scanStart = false;
  this.scanSerialStart = false;
  this.helperservice.hideModal("barcodeScan");
  } 
}
