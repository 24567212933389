import { Component, OnInit,ViewChild, Inject, ChangeDetectorRef, ElementRef, AfterViewInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective } from '@angular/forms';
import { HttpService } from "../../shared/services/http.service";
import { HelperService } from 'app/helper.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatAutocompleteTrigger } from '@angular/material';
import { map, startWith } from 'rxjs/operators';
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { BehaviorSubject } from 'rxjs';
import { BarcodeFormat } from '@zxing/library';

@Component({
    selector: 'app-rework',
    templateUrl: './rework.component.html',
    styleUrls: ['./rework.component.scss'],
})

export class ReworkComponent implements OnInit {

    @ViewChild(MatAutocompleteTrigger, { static: true }) inputAutoComplit: MatAutocompleteTrigger;
    @ViewChild('spform', {static:true}) spform;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    @ViewChild("itempaginator", { static: false }) itempaginator: MatPaginator;
    @ViewChild("itemsort", { static: false }) itemsort: MatSort;
    @ViewChild("itemCodeQtyToAddPaginator", { static: false })
    itemAndQtyToAddPaginator: MatPaginator;
    @ViewChild("itemCodeAndQtyToAddSort", { static: false })
    itemAndQtyToAddsort: MatSort;
    @ViewChild("focusInput", { static: true }) focusInput: ElementRef;
    spareForm: FormGroup;
    sparePartsForm: FormGroup;
    spareCodeFlag: boolean = true;
    spareParts: any = [];
    filesList: any = [];
    spareFormSubmit: FormGroup;
    projectList: any = [];
    reworkRecords: any = [];
    count = 1;
    ok: any;
    firstParam: any;
    projectItemCodes: any;
    projectName:any = "";
    sparePartsPost: any = [];
    recordDetailsByID: any;
    recordDetailsHolderNumber: any;
    recordDetailDescription: any;
    recordDetailstartDate: any;
    recordDetailspareParts: any;
    recordDetailfileUrls: any = [];
    recordDetailcloseRecord: any;
    recordDetailID: any;
    responseMessage:any;
    reworkForm: FormGroup;
    selectedImage: string | null = null;
    fileSizeError: string = '';
    displayedColumns = [
        "recordId",
"description",
"createdBy",
"serialNumber",
"startDate",
// "endDate",
        // "projectType",
        // "createdBy",
        // "category",
        // "projectStatus",
        // "startDate",
        // "endDate",
    ];

      // dataSource: MatTableDataSource<any>;
  dataSource: MatTableDataSource<UserDetailsInterface>;

  itemdataSoure: MatTableDataSource<any>;
  itemDisplayedColumns = ["itemCode", "quantity"];
  itemDisplayedColumnsWhileAdding = ["itemCode", "quantity", "delete"];
  itemCodeQuantityList: any;
  itemQuantityDataSourceToAdd: MatTableDataSource<any>;
  addedSpares: any = [];
  spareList: any = [];
  defectCodes: any = [];
  isSpareError: boolean = false;
  componentCodes: any = [];
  spareMasterCode: any = [];
  SMcode: any;

  openPanel(evt): void {
  evt.stopPropagation();
  this.inputAutoComplit.openPanel();
  this.loadFilter();
  }

  // Barcode scanning variables
public webcamImage: WebcamImage = null;
public errors: WebcamInitError[] = [];
cameraOpen: boolean = false;
public multipleWebcamsAvailable = false;
scanStart:boolean=false; 
scanSerialStart: boolean = false;
qrResultString: string;
availableDevices: MediaDeviceInfo[];
hasDevices: boolean;
qrResults: any=[];
currentDevice: MediaDeviceInfo = null;
hasPermission: boolean;
torchAvailable$ = new BehaviorSubject<boolean>(false);
torchEnabled = false;
tryHarder = false;
recordCount: any;
    totalCount: any;
    category: any;

formatsEnabled: BarcodeFormat[] = [
  BarcodeFormat.CODE_128,
  BarcodeFormat.DATA_MATRIX,
  BarcodeFormat.EAN_13,
  BarcodeFormat.QR_CODE,
];
// Barcode scanning variables end


constructor(private route: ActivatedRoute, private fb: FormBuilder, private httpService: HttpService, private spinner: NgxSpinnerService, private helperservice: HelperService){
    this.spareForm = this.fb.group({
        recoveredSparePart: ['', [Validators.required]]
    });
    this.spareFormSubmit = this.fb.group({

    });
    this.loadFilter();

}


  ngOnInit() {
    this.firstParam = this.route.snapshot.queryParamMap.get('projectID');
    this.getSpareParts();
    this.fetchReworkRecords();
    this.getProjectDetails();

    this.reworkForm = this.fb.group({
      itemcode: ['', [Validators.required]],
      holderNumber: ['', [Validators.required, Validators.maxLength(20), Validators.minLength(9)]],
      serialNumber: [''],
      description:[''],
      sparePartsRequired: ['', [Validators.required]]
        }); 
  }

  getProjectDetails() {
    this.spinner.show();
    this.httpService.getRequest('specialProjectApi/v1/projectDetails?projectId=' + this.firstParam).subscribe(
      res => {
        this.spinner.hide();
        if(res['status'] === 'ERROR'){
          this.responseMessage = res['errorMessage'];
          this.helperservice.openStaticModal('ConfirmDialog');
          return;
        }
        this.projectItemCodes = res['projectDetails']['projectItemCodeMappingDtoList'];
        this.projectName = res['projectDetails']['projectName'];
        this.category = res['projectDetails']['category'];
        this.recordCount = res['projectDetails']['recordCount'];
        this.totalCount = res['projectDetails']['totalQuantity'];
      },
      err => {
        // alert("Internal issue to fetch the information");
        this.spinner.hide();
        this.responseMessage = "Internal issue to fetch the information";
        this.helperservice.openStaticModal('ConfirmDialog');
      }
    );
  }

  getSpareParts() {
    this.httpService
        .getRequest("specialProjectApi/v1/spareParts")
        .subscribe(
            (res) => {
                if ((res["status"] = "SUCCESS")) {
                  this.spareParts = res["spareParts"];
                } else {
                    // console.log("error fetching spare parts");
                    this.responseMessage = "Error fetching spare parts.";
                    this.helperservice.openStaticModal('ConfirmDialog');
                }
            },
            err => {
              // alert("Internal issue to fetch the information");
              this.spinner.hide();
              this.responseMessage = "Internal issue to fetch the information";
              this.helperservice.openStaticModal('ConfirmDialog');
            }
        );
  }

  hasMoreRecords: boolean = false;

  fetchReworkRecords(){
    this.spinner.show();
    this.httpService
    .getRequest("reworkApi/v1/getOpenRecordPageForTechnician?pageNo="+this.count+"&pageSize=100"+"&projectId="+this.firstParam+"&userId="+localStorage.getItem('userId'))
    .subscribe(
            (res) => {
                this.spinner.hide();
                if(res['status'] === 'ERROR'){
                  this.responseMessage = res['response'];
                  this.helperservice.openStaticModal('ConfirmDialog');
                  return;
                }
                console.log(res);
                this.reworkRecords = res["response"];
                this.projectList = res["projects"];
                this.dataSource =
                    new MatTableDataSource<UserDetailsInterface>(
                        this.reworkRecords
                    );
                this.hasMoreRecords = res["response"].length == 100;
                console.log(this.dataSource);
                // this.setPaginator();
            },
            err => {
              // alert("Internal issue to fetch the information");
              this.spinner.hide();
              this.responseMessage = "Internal issue to fetch the information";
              this.helperservice.openStaticModal('ConfirmDialog');
            }
        );
  }

  nextRecords(){
    this.count++;
    if(this.count >= 0){
        this.spinner.show();
        this.httpService
        .getRequest("reworkApi/v1/getOpenRecordPageForTechnician?pageNo="+this.count+"&pageSize=100"+"&projectId="+this.firstParam+"&userId="+localStorage.getItem('userId'))
        .subscribe(
                (res) => {
                    this.spinner.hide();
                    console.log(res);
                    if(res['status'] === 'ERROR'){
                      this.responseMessage = res['response'];
                      this.helperservice.openStaticModal('ConfirmDialog');
                      return;
                    }
                    if(res["response"].length == 0){
                        console.log(res["response"].length);
                        // alert("No more records");
                        this.responseMessage = "No more records";
                        this.helperservice.openStaticModal('ConfirmDialog');
                    } else {
                        this.reworkRecords = res["response"];
                        this.projectList = res["projects"];
                        this.dataSource =
                            new MatTableDataSource<UserDetailsInterface>(
                                this.reworkRecords
                            );
                        console.log(this.dataSource);
                        // this.setPaginator();
                    }
    
                },
                err => {
                  // alert("Internal issue to fetch the information");
                  this.spinner.hide();
                  this.responseMessage = "Internal issue to fetch the information";
                  this.helperservice.openStaticModal('ConfirmDialog');
                }
            );
    } else {
        // alert();
          this.responseMessage = "Internal issue to fetch the information";
          this.helperservice.openStaticModal('ConfirmDialog');
    }

  }

  previousRecords(){
    this.count--;
    if(this.count <= 0){
        // alert("No less records");
        this.responseMessage = "Page number can not be less than 0.";
        this.helperservice.openStaticModal('ConfirmDialog');
    } else {
        this.spinner.show();
        this.httpService
        .getRequest("reworkApi/v1/getOpenRecordPageForTechnician?pageNo="+this.count+"&pageSize=100"+"&projectId="+this.firstParam+"&userId="+localStorage.getItem('userId'))
        .subscribe(
                (res) => {
                    this.spinner.hide();
                    console.log(res); 
                    if(res['status'] === 'ERROR'){
                      this.responseMessage = res['response'];
                      this.helperservice.openStaticModal('ConfirmDialog');
                      return;
                    }
                    if(res["response"].length == 0){
                        // alert("No more records");
                        this.responseMessage = "No more records";
                        this.helperservice.openStaticModal('ConfirmDialog');
                    } else {
                         this.reworkRecords = res["response"];
                        
                        this.projectList = res["projects"];
                        this.dataSource =
                            new MatTableDataSource<UserDetailsInterface>(
                                this.reworkRecords
                            );
                        console.log(this.dataSource);
                        // this.setPaginator();
                    }
    
                },
                err => {
                  // alert("Internal issue to fetch the information");
                  this.spinner.hide();
                  this.responseMessage = "Internal issue to fetch the information";
                  this.helperservice.openStaticModal('ConfirmDialog');
                }
            );
    }

  }


setPaginator() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
}
setPaginatorForItem() {
    this.itemdataSoure.paginator = this.itempaginator;
    this.itemdataSoure.sort = this.itemsort;
}

setPaginatorForItemCodeAndQtyToAdd() {
    this.itemQuantityDataSourceToAdd.paginator =
        this.itemAndQtyToAddPaginator;
    this.itemQuantityDataSourceToAdd.sort = this.itemAndQtyToAddsort;
}

applyFilter(filterValue: string) {
  filterValue = filterValue.trim(); // Remove whitespace
  filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
  this.dataSource.filter = filterValue;
}

  getRework() { //doubt
    this.httpService
        .getRequest("specialProjectApi/v1/rework")
        .subscribe(
            (res) => {
                if ((res["status"] = "SUCCESS")) {
                  this.spareParts = res["spareParts"];
                } else {
                    // console.log("error fetching spare parts");
                    this.responseMessage = "Error fetching spare parts.";
                    this.helperservice.openStaticModal('ConfirmDialog');
                }
            },
            err => {
              // alert("Internal issue to fetch the information");
              this.spinner.hide();
              this.responseMessage = "Internal issue to fetch the information";
              this.helperservice.openStaticModal('ConfirmDialog');
            }
        );
  }

    closePopup() {
      this.helperservice.hideModal('ConfirmDialog');
    }

    reworkSubmitForm(formDirective: FormGroupDirective){
      if (!this.reworkForm.valid) {
        this.helperservice.makeFormDirty(this.reworkForm);
        return;
      }
      
      if(this.addedSpares.length === 0){
        this.reworkForm.get('sparePartsRequired').setErrors({required: true});
        this.reworkForm.get('sparePartsRequired').markAsTouched();
        return;
      }
      const fd = new FormData();
      fd.append('userId', 'ISA_SPECIAL_PROJECT');
  
      if (this.filesList.length > 0) {
          for (let file of this.filesList) {
              fd.append('files', file);
          }
          this.uploadFiles(fd, formDirective);
      } else {
          this.saveReworkRecord([], formDirective);
      }
    }

    loadFilter() {
      this.spareMasterCode = this.spareForm.get('recoveredSparePart').valueChanges
        .pipe(
          startWith(''),
          map(value => this._filterSpareCode(value))
        );
        console.log("recovered spare parts",this.spareMasterCode);
    }

    private _filterSpareCode(value: string): string[] {
      if ((value === undefined || value === null) || (this.spareParts === undefined || this.spareParts === null))
        return;
      const filterValue = value.toLowerCase();
      var data = this.spareParts.filter(option1 => String(option1.matnr12nc).toLowerCase().indexOf(filterValue) > -1 ||
        option1.spareName.toLowerCase().indexOf(filterValue) > -1);
      return data
    }

    getSpareCodeVerified(event) {
      this.spareCodeFlag = event.isUserInput;
    }

    focusOutSpareCode(code) {
      if (code != undefined) {
        var codeFound = this.spareParts.find(x => x.spareName == code);
        if (codeFound != undefined && code == codeFound.spareName) {
          this.spareCodeFlag = true;
        }
        else {
          this.spareCodeFlag = false;
        }
      }
    }

    cancelSpare() {
      this.isSpareError = false;
      this.spareForm.reset();
      this.spform.resetForm(
        {
          recoveredSparePart: '',
          otherSparePart:'',
          quantity: '1',
          isSparePartRecovered: 'YES',
        }
      );
      this.helperservice.hideModal('Spare-Parts-Used-Dialog');
      this.loadFilter();
    }
  
   
    addSpare() {
      if (!this.spareForm.valid) {
        this.helperservice.makeFormDirty(this.spareForm);
        return;
      }
      const spare = this.spareForm.value;
      if (spare.recoveredSparePart) {
        const codeFound = this.spareParts.find(x => x.spareName === spare.recoveredSparePart);
    
        if (codeFound) {
          this.addedSpares.push(codeFound); // Store the full object for later mapping
          this.sparePartsPost.push(spare);
          this.spareList.push({
            matnr12nc: codeFound.matnr12nc,
            spareName: codeFound.spareName
          }); // Store both matnr12nc and spareName
          
          this.reworkForm.get('sparePartsRequired').setValue('valid');
          this.reworkForm.get('sparePartsRequired').setErrors(null);

          console.log("Added Spare List:", this.spareList);
          this.cancelSpare();
        }
      }
    }
    
    uploadImage(event: any) {
      var files = event.target.files;
      console.log(files);
  
      this.fileSizeError = '';
  
      for (var i = 0; i < files.length; i++) {
          var f = files[i];
          if (f.size > 5 * 1024 * 1024) {
              this.fileSizeError = `File ${f.name} exceeds the 5MB size limit.`;
              continue;
          }
  
          f.mediaSRC = URL.createObjectURL(f);
          this.filesList.push(f);
          console.log(this.filesList);
      }
  }    

      formatDateUTC(date: Date): string {
        if (!date) return '';
        const options: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: 'UTC' // Ensures UTC time
        };
        return new Intl.DateTimeFormat('en-GB', options).format(date).replace(',', '');
    }
    





uploadFiles(data: FormData, formDirective: FormGroupDirective) {
  this.spinner.show();
  this.httpService.uploadImageRequest('specialProject/uploadFiles', data,"REWORK").subscribe(
      res => {
          this.saveReworkRecord(res['fileUrl'], formDirective);
      },
      err => {
          this.spinner.hide();
          this.responseMessage = "Error occurred during file upload.";
          this.helperservice.openStaticModal('ConfirmDialog');
      }
  );
}

saveReworkRecord(fileUrls: string[], formDirective: FormGroupDirective) {
  this.spinner.show();
  const formData = this.reworkForm.value;
  const date = new Date().toISOString().slice(0, 10);

  let postData = {
      projectId: this.firstParam,
      itemCode: formData.itemcode,
      holderNumber: formData.holderNumber,
      serialNumber: formData.serialNumber,
      startDate: date.split('.')[0].replace("T", " "),
      sparePartIdList: this.spareList.map(spare => spare.matnr12nc),
      description: formData.description,
      fileUrls: fileUrls,
      createdBy: localStorage.getItem('userId')
  };

  this.spinner.show();
  this.httpService.postRequest('reworkApi/v1/saveRecord', postData).subscribe(
      res => {
          this.spinner.hide();
          if (res["status"] === "SUCCESS") {
                        this.responseMessage = res['response'];
                        this.helperservice.openStaticModal('ConfirmDialog');
                        this.addedSpares = [];
                        // this.reworkForm.reset();
                        // formDirective.resetForm();
                        const currentHolder = formData.holderNumber;
                        const defaultValues = {
                        itemcode: '',
                        holderNumber: currentHolder,
                        serialNumber: '',
                        description: '',
                        sparePartsRequired: '',
                      };
                      this.reworkForm.reset(defaultValues);
                      formDirective.resetForm(defaultValues);
                       this.filesList = [];
                        const fileInput = document.getElementById("chooseFile") as HTMLInputElement;
                                    if (fileInput) {
                                        fileInput.value = "";
                                    }
                        this.fetchReworkRecords();

                        this.sparePartsPost = [];
                        this.addedSpares = [];
                        this.spareList = [];
                      } else {
                        this.responseMessage = res['response'];
                        this.helperservice.openStaticModal('ConfirmDialog');
                      }
      },
      err => {
          this.spinner.hide();
          this.responseMessage = err.response;
          this.helperservice.openStaticModal('ConfirmDialog');
      }
  );
}

    
    getRecordDetails(recordID) {
      this.recordDetailID = recordID;
      this.spinner.show();
      this.httpService
        .getRequest("reworkApi/v1/getRecordDetailsForId?reworkRecordId=" + recordID)
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res['status'] === "SUCCESS") {
              const recordDetails = res['response'];
              this.recordDetailsByID = recordDetails.serialNumber;
              this.recordDetailsHolderNumber = recordDetails.holderNumber;
              this.recordDetailDescription = recordDetails.description;
              // this.recordDetailstartDate = res["response"].startTime.split('.')[0].replace("T", " ");
              this.recordDetailstartDate = this.formatDateUTC(new Date(res["response"].startTime));
              this.recordDetailfileUrls = recordDetails.fileUrls;
              console.log("fetched image",this.recordDetailfileUrls)
              this.recordDetailcloseRecord = recordDetails.createdBy;
              
              this.recordDetailspareParts=[]
              this.recordDetailspareParts = recordDetails.sparePartIdList.map(matnr12nc => {
                const spare = this.spareParts.find(sp => sp.matnr12nc === matnr12nc);
                return spare ? spare.spareName : matnr12nc; 
              });
    
              console.log("Mapped Spare Parts:", this.recordDetailspareParts);
              this.helperservice.openStaticModal("seeProject-Dialog");
            } else {
              this.responseMessage = res['response'];
              this.helperservice.openStaticModal('ConfirmDialog');
            }
          },
          err => {
            this.spinner.hide();
            this.responseMessage = "Internal issue to fetch the information";
            this.helperservice.openStaticModal('ConfirmDialog');
          }
        );
    }
    
    

      closeRecordModal(){
        this.helperservice.hideModal("seeProject-Dialog");
      }

      closeRecord(){
        this.recordDetailID;
        var dateTime = new Date().toISOString();
        console.log(dateTime);
        this.spinner.show();
            let postData = {
              "projectId": this.firstParam,
              "recordId": this.recordDetailID,
              "endTime": dateTime
            };
            console.log("PostData : "+JSON.stringify(postData));
            this.httpService.postRequest('reworkApi/v1/closeRecord', postData).subscribe(
              res => {
                this.helperservice.hideModal("seeProject-Dialog");
                this.spinner.hide();
                this.fetchReworkRecords();
                console.log("look here",res);
              },
              err => {
                this.spinner.hide();
                this.responseMessage = err.response;//ask
                this.helperservice.openStaticModal('ConfirmDialog');
              }
            );
      }


      editComments(){
        this.spinner.show();
        let postData = {
          "projectId": this.firstParam,
          "recordId": this.recordDetailID,
          "description": this.recordDetailDescription
        };
        console.log("PostData : "+JSON.stringify(postData));
        this.httpService.postRequest('reworkApi/v1/updateRecordDescription', postData).subscribe(
          res => {
            this.helperservice.hideModal("seeProject-Dialog");
            this.spinner.hide();
            // alert("Comments Updated Successfully");
            this.responseMessage = "Comments Updated Successfully"
            this.helperservice.openStaticModal('ConfirmDialog');
            this.fetchReworkRecords();
            console.log(res);
          },
          err => {
            this.spinner.hide();
            this.responseMessage = err.response;//ask
            this.helperservice.openStaticModal('ConfirmDialog');
          }
        );
      }


      openCamera() {
        this.qrResultString = null;
        this.cameraOpen = true;
        WebcamUtil.getAvailableVideoInputs()
          .then((mediaDevices: MediaDeviceInfo[]) => {
            this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
          });
      }
      
      clearResult(): void {
        this.qrResultString = null;
      }
      
      onCamerasFound(devices: MediaDeviceInfo[]): void {
        this.availableDevices = devices;
        this.hasDevices = Boolean(devices && devices.length);
      }
      
      // onCodeResult(resultString: string) {
      //   console.log("Scanned code", resultString);
      //   if(!resultString){
      //     console.error("no barcode");
      //     return;
      //   }

      //   this.qrResultString = resultString;
      //   // this.qrResults.push(this.qrResultString);

      //   this.reworkForm.get('holderNumber').setValue(this.qrResultString);
        
      //   let audio = new Audio("assets/mp3/beep-02.mp3");
      //   // audio.src = "assets/mp3/beep-02.mp3";
      //   audio.load();
      //   audio.play();
      
      //   this.spinner.show();
      //   setTimeout(() => {
      //     this.spinner.hide();
      //     this.closeScanner();
      //   }, 2000);
      // }

      onCodeResult(resultString: string) {
        console.log("Scanned code:", resultString);
        if (!resultString) {
            console.error("No barcode detected");
            return;
        }
    
        if (this.scanStart) {
            this.reworkForm.get('holderNumber').setValue(resultString);
        } else if (this.scanSerialStart) {
            this.reworkForm.get('serialNumber').setValue(resultString);
        }
    
        let audio = new Audio("assets/mp3/beep-02.mp3");
        audio.load();
        audio.play();
    
        this.spinner.show();
        setTimeout(() => {
            this.spinner.hide();
            this.closeScanner();
        }, 2000);
    }
    
      openImage(imageUrl: string) {
        this.selectedImage = imageUrl;
    }
    
    closeImage() {
        this.selectedImage = null;
    }
      
      onDeviceSelectChange(selected: string) {
        const device = this.availableDevices.find(x => x.deviceId === selected);
        this.currentDevice = device || null;
      }
      
      onHasPermission(has: boolean) {
        this.hasPermission = has;
      }
      
      onTorchCompatible(isCompatible: boolean): void {
        this.torchAvailable$.next(isCompatible || false);
      }
      
      toggleTorch(): void {
        this.torchEnabled = !this.torchEnabled;
      }
      
      toggleTryHarder(): void {
        this.tryHarder = !this.tryHarder;
      }
      
      // closeScanner() {
      //   this.scanStart = false;
      //   this.helperservice.hideModal("barcodeScan");
      // }

      closeScanner() {
        this.scanStart = false;
        this.scanSerialStart = false;
        this.helperservice.hideModal("barcodeScan");
    }    
      
      removeCam() {
        this.cameraOpen = false;
        this.helperservice.hideModal('camera-media-dialog');
      }

}

export interface UserDetailsInterface {
    serialNumber: any;
    description: string;
    createdBy: any;
    recordId: any;
  }
  
  
  
