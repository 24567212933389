/**
 * Created by shubham on 27/2/18.
 */

import { Injectable}     from "@angular/core";
// import { Http, Response, Headers } from "@angular/http";
import { HttpClient, HttpErrorResponse,HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { SessionHandler } from './session-handler.service';
import { APPCONFIG } from '../../config';
import { Observable } from "rxjs/Rx";
import { Router } from "@angular/router";
import { CommonService } from '../../shared/services/common.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, from } from 'rxjs';
import {DialogComponent} from '../../dialog/dialog.component'
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Http, Response, Headers, RequestOptions, ResponseContentType } from '@angular/http';

@Injectable()
export class HttpService {
    private pendingHTTPRequests$ = new Subject<void>();
    public urlImageUpload:any = APPCONFIG.URL;
    public url:any = APPCONFIG.URL+"/_ah/api";
    public serviceDocUrl=APPCONFIG.SERVICEDOCURL;
    public headers = new Headers({'Content-Type': 'application/json','apiKey':APPCONFIG.apiKey,'authentication': localStorage.getItem('userToken'),'userId': localStorage.getItem('userId')});
    adminFlag : boolean = false;
    userisAdmin: boolean;
    //public headers = new Headers({'Content-Type': 'application/json','apiKey':APPCONFIG.apiKey});
        constructor(private http: Http,private httpClient:HttpClient,private router: Router,public sessionHandler : SessionHandler,
                public commonService:CommonService,public dialog: MatDialog) {
                    // console.log("localStorage.getItem('userToken'):"+localStorage.getItem('userToken'))
                }

    getRequest(url) : Observable<Response[]> {
        this.adminRoleCheck();
       if(this.loginCheckSession()){
        localStorage.setItem("timestamp", Math.round(new Date().getTime()/1000.0).toString());
       } 
       let headers = new HttpHeaders;
        let otherHeaders = new HttpHeaders({'Content-Type': 'application/json','apiKey':APPCONFIG.apiKey,'authentication': localStorage.getItem('userToken'),'userId': localStorage.getItem('userId')
        ,'language':localStorage.getItem('language')});
        let adminHeaders = new HttpHeaders({'Content-Type': 'application/json','apiKey':APPCONFIG.apiKey,'authentication': localStorage.getItem('userToken'),'userId': localStorage.getItem('userId')
        ,'language':localStorage.getItem('language'),'companyId': localStorage.getItem('companyId')});
        this.adminFlag? headers = adminHeaders : headers = otherHeaders;    //check if admin exists

        return this.httpClient.get(this.url +"/"+url,{headers: headers})
        .map((res:Response) => this.apiSuccess(res,url))
        .catch((error:any) => Observable.throw(this.apiError(error,url)));
        //     .catch((error:any) =>{
        //          if (error.status === 401) {
        //         // console.log("Please login again")
        //         localStorage.clear();
        //         // console.log(error)
        //         this.router.navigate(['/login']);
        //         window.location.reload();
        //         return Observable.throw(this.apiError(error.json(),url));
        //        }
        //        else{
        //         // console.log("else");
        //          return  Observable.throw(this.apiError(error.json(),url));
        //        }
        //    }); 
    }
    //temporay fix as country id is needed for API calls
    getCompanyList(url,res?) : Observable<Response[]> {
        try {
            this.adminRoleCheck();
            if(this.loginCheckSession()){
             localStorage.setItem("timestamp", Math.round(new Date().getTime()/1000.0).toString());
            } 
            let headers = new HttpHeaders;
            
            if(res){
                let otherHeaders = new HttpHeaders({'Content-Type': 'application/json','apiKey':APPCONFIG.apiKey,'authentication': res.userToken,'userId': res.userId,
                 'language':res.language});
                 headers = otherHeaders;    

            }else{
                let otherHeaders = new HttpHeaders({'Content-Type': 'application/json','apiKey':APPCONFIG.apiKey,'authentication': localStorage.getItem('userToken'),'userId': localStorage.getItem('userId')
                 ,'language':localStorage.getItem('language')})
                 headers = otherHeaders;    
            }



             return this.httpClient.get(this.url +"/"+url,{headers: headers})
             .map((res:Response) => this.apiSuccess(res,url))
             .catch((error:any) => Observable.throw(this.apiError(error,url)));
        } catch (error) {
            console.log('exception')
        }


    }
    postRequest(url,body: Object) : Observable<Response[]> {
        this.adminRoleCheck();
        if(this.loginCheckSession()){
            localStorage.setItem("timestamp", Math.round(new Date().getTime()/1000.0).toString());
           }
        let headers = new HttpHeaders;
        let otherHeaders = new HttpHeaders({'Content-Type': 'application/json','apiKey':APPCONFIG.apiKey,'authentication': localStorage.getItem('userToken'),'userId': localStorage.getItem('userId')
        ,'language':localStorage.getItem('language')  });
        let adminHeaders = new HttpHeaders({'Content-Type': 'application/json','apiKey':APPCONFIG.apiKey,'authentication': localStorage.getItem('userToken'),'userId': localStorage.getItem('userId')
        ,'language':localStorage.getItem('language'),'companyId':localStorage.getItem('companyId') });
        this.adminFlag? headers = adminHeaders : headers =otherHeaders;    //check if admin exists
            
        return this.httpClient.post(this.url +"/"+url, body, {headers: headers})
            .map((res:Response) => this.apiSuccess(res,url))
            .catch((error:any) => Observable.throw(this.apiError(error,url)));




    //        .catch((error:any) =>{
    //         if (error.status === 401) {
    //     //    console.log("Please login again")
    //        localStorage.clear();
    //        this.router.navigate(['/login']);
    //        //window.location.href="#/login"
    //        window.location.reload();
    //        return Observable.throw(this.apiError(error.json(),url));
    //       }
    //       else{
    //     //    console.log("else");
    //         return  Observable.throw(this.apiError(error.json(),url));
    //       }
    //   }); 
    }

    postRequestWithAnyResponse(url,body: Object) : Observable<any> {
        this.adminRoleCheck();
        if(this.loginCheckSession()){
            localStorage.setItem("timestamp", Math.round(new Date().getTime()/1000.0).toString());
           }
        let headers = new Headers;
        let otherHeaders = new Headers({'Content-Type': 'application/json','apiKey':APPCONFIG.apiKey,'authentication': localStorage.getItem('userToken'),'userId': localStorage.getItem('userId')
        ,'language':localStorage.getItem('language')  });
        let adminHeaders = new Headers({'Content-Type': 'application/json','apiKey':APPCONFIG.apiKey,'authentication': localStorage.getItem('userToken'),'userId': localStorage.getItem('userId')
        ,'language':localStorage.getItem('language'),'companyId':localStorage.getItem('companyId') });
        this.adminFlag? headers = adminHeaders : headers =otherHeaders;    //check if admin exists
            
        let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
        return this.http.post(APPCONFIG.URL +"/"+url, body, options)
            .map((res:Response) => this.apiSuccess(<Blob>res.blob(),url) )
            .catch((error:any) => Observable.throw(this.apiError(<Blob>error.blob(),url)));
    }

    uploadPostRequest(url,body: Object) : Observable<Response[]> {
        let headers = new Headers({});
     // let headers = new Headers({'apitoken': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ'});
      return this.http.post(this.serviceDocUrl +"/"+url, body, {headers: headers})
            .map((res:Response) => this.apiSuccess(res.json(),url))
            .catch((error:any) => Observable.throw(this.apiError(error.json(),url)));
    }

    uploadImageRequest(url,body: Object,projectType: String) : Observable<Response[]> {
        let headers = new Headers({"projectType":projectType});
     // let headers = new Headers({'apitoken': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ'});
      return this.http.post(this.urlImageUpload +"/"+url, body, {headers: headers})
            .map((res:Response) => this.apiSuccess(res.json(),url))
            .catch((error:any) => Observable.throw(this.apiError(error.json(),url)));
    }

    downloadPostRequest(url,body: Object) : Observable<Response[]> {      
        let headers = new Headers({'Content-Type': 'application/json'});
      //  let headers = new Headers({'apitoken': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ'});
        return this.http.post(this.serviceDocUrl +"/"+url, body, {headers: headers})
            .map((res:Response) => this.apiSuccess(res.json(),url))
            .catch((error:any) => Observable.throw(this.apiError(error.json(),url)));
    }

    loginpostRequest(url,body: Object) : Observable<Response[]> {
        let headers = new Headers({'Content-Type': 'application/json','apiKey':APPCONFIG.apiKey});

        return this.http.post(this.url +"/"+url, body, {headers: headers})
            .map((res:Response) => this.apiSuccess(res.json(),url))
            .catch((error:any) => Observable.throw(this.apiError(error.json(),url)));
    }

    validatePermissions(url,body: Object) : Observable<any> {
        this.adminRoleCheck();

        if(this.loginCheckSession()){
            localStorage.setItem("timestamp", Math.round(new Date().getTime()/1000.0).toString());
           }
           let headers = new Headers;
           let otherHeaders = new Headers({'Content-Type': 'application/json','apiKey':APPCONFIG.apiKey,
           'authentication': localStorage.getItem('userToken'),'userId': localStorage.getItem('userId'),
           'language':localStorage.getItem('language')});
           let adminHeaders = new Headers({'Content-Type': 'application/json','apiKey':APPCONFIG.apiKey,
           'authentication': localStorage.getItem('userToken'),'userId': localStorage.getItem('userId'),
           'language':localStorage.getItem('language'),'companyId':localStorage.getItem('companyId') });
           this.adminFlag? headers = adminHeaders : headers =otherHeaders;    //check if admin exists
            return this.http.post(this.url +"/"+url, body, {headers: headers})
            .map((res:Response) => this.apiSuccess(res.json(),url))
            .catch((error:any) => Observable.throw(this.apiError(error.json(),url)));
    }

    public apiSuccess(jsonResult, api) {
        console.log(jsonResult)
        return jsonResult;
    }
    public apiError(jsonResult, api) {
        console.log(jsonResult)
        return jsonResult;
    }

// public apiHandler(err){
//     debugger;
//     if(err.status==401){
//     localStorage.removeItem('userToken');
//     this.router.navigate(['login']);
//         //this.openDialog()
//         window.location.reload();   
// }
//     return false;
// }

    openDialog() {
        this.dialog.open(DialogComponent, {
      data: {
        message :"Session Ended, Please Login Again"
      }
    });}
  
    public loginCheckSession() {
    let timestamp = localStorage.getItem('timestamp');
    if(timestamp == null) {
        return false;
    }
    let currentTimeStamp = Math.round(new Date().getTime()/1000.0);
    let diff = Math.abs(Math.floor((currentTimeStamp-parseInt(timestamp))/3600));
    //let diff1 = (currentTimeStamp-parseInt(timestamp)/60000);
    // return true;
    let token = JSON.parse(localStorage.getItem('Token'));
    if(diff >= 1){
        return false;
    } else {
        return  true;
    }

}
 public showSessionError() {
    // this.confirmDialogRefSession = this.dialog.open(sessionConfirmDialogComponent);
    // this.confirmDialogRefSession.componentInstance.confirmMessage = 'Session Ended, Please Login Again';
    localStorage.clear();
    this.openDialog();
    this.router.navigateByUrl('/login');
  }

  adminRoleCheck(){
    localStorage.getItem('UserRole')== 'SA' ? this.adminFlag = true : this.adminFlag= false;
  }
}