import { Component, OnInit, ViewChild, Inject, ChangeDetectorRef, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective } from '@angular/forms';
import { HttpService } from "../../shared/services/http.service";
import { NgxSpinnerService } from "ngx-spinner";
import { HelperService } from 'app/helper.service';
import { MatAutocompleteTrigger } from '@angular/material';
import { map, startWith } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { BarcodeFormat } from '@zxing/library';

@Component({
  selector: 'oti-repair',
  templateUrl: './oti-repair.component.html',
  styleUrls: ['./oti-repair.component.scss'],
})


export class OtiRepairComponent implements OnInit {

  @ViewChild('userDform', { static: true }) form;
  @ViewChild(MatAutocompleteTrigger, { static: true }) inputAutoComplit: MatAutocompleteTrigger;
  @ViewChild('spform', {static:true}) spform;
  otiRepairForm: FormGroup;
  spareForm: FormGroup;
  isChecked: boolean = true;
  spareCodeFlag: boolean = true;
  ok: any;
  notOk: any;
  filesList: any = [];
  firstParam: any;
  projectItemCodes: any;
  responseMessage: any;
  projectName:any = "";
  spareParts: any = [];
  addedSpares: any = [];
  spareList: any = [];
  defectCodes: any = [];
  isSpareError: boolean = false;
  componentCodes: any = [];
  spareMasterCode: any = [];
  fileSizeError: string = '';
  scanStart:boolean=false; 
  scanSerialStart: boolean = false;
  qrResultString: string;
  availableDevices: MediaDeviceInfo[];
  hasDevices: boolean;
  qrResults: any=[];
  currentDevice: MediaDeviceInfo = null;
  hasPermission: boolean;
  torchAvailable$ = new BehaviorSubject<boolean>(false);
  torchEnabled = false;
  tryHarder = false;
  filteredComponentCodes: Observable<string[]>;
  recordCount: any;
    totalCount: any;
    category: any;


  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];
  // openPanel(evt): void {
  //   evt.stopPropagation();
  //   // this.inputAutoComplit.openPanel();

  //   const target = evt.target as HTMLElement;
  //   if(target.closest('.isa-modal-add-spare')){
  //     return;
  //   }
  //   this.inputAutoComplit.openPanel();
  // }

  openPanel(evt: Event): void {
    evt.stopPropagation();
    const target = evt.target as HTMLElement;
    if(this.inputAutoComplit.panelOpen){
      return;
    }
    if (target.closest('.isa-modal-add-spare')) {
      
      return;
    }
    const compCodeControl = this.otiRepairForm.get('componentCode');
    if (!compCodeControl.value) {
      compCodeControl.setValue('');
    }
    setTimeout(() => {
      this.inputAutoComplit.openPanel();
    });
  }
  

  
  
  constructor(private route: ActivatedRoute, private fb: FormBuilder, private httpService: HttpService, private spinner: NgxSpinnerService, private helperservice: HelperService) {

  }

  ngOnInit() {
    this.firstParam = this.route.snapshot.queryParamMap.get('projectID');
    this.getProjectDetails();    
    this.getComponentCodes();
    this.getDefectCodes();
    this.getSpareParts();

    this.otiRepairForm = this.fb.group({
      itemcode: ['', [Validators.required]],
      holderNumber: ['', [Validators.required, Validators.maxLength(20), Validators.minLength(9)]],
      serialNumber: [''],
      componentCode: [''],
      defectCode: [''],
      description:[''],
    });
    this.spareForm = this.fb.group({
      spareCode: ['', [Validators.required]],
    });
    this.otiRepairForm.reset();
    this.loadFilter();

    this.filteredComponentCodes = this.otiRepairForm.get('componentCode').valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value? value[0]: ''),
      map(name => this._filterComponentCodes(name))
    );
  }

  getProjectDetails() {
    this.spinner.show();
    this.httpService.getRequest('specialProjectApi/v1/projectDetails?projectId=' + this.firstParam).subscribe(
      res => {
        this.spinner.hide();
        if(res['status'] === 'ERROR'){
          this.responseMessage = res['errorMessage'];
          this.helperservice.openStaticModal('ConfirmDialog');
          return;
        }
        this.projectItemCodes = res['projectDetails']['projectItemCodeMappingDtoList'];
        this.projectName = res['projectDetails']['projectName'];
        this.category = res['projectDetails']['category'];
        this.recordCount = res['projectDetails']['recordCount'];
        this.totalCount = res['projectDetails']['totalQuantity'];
      },
      err => {
        // alert("Internal issue to fetch the information");
        this.spinner.hide();
        this.responseMessage = "Internal issue to fetch the information";
        this.helperservice.openStaticModal('ConfirmDialog');
      }
    );
  }

  otiRepairSubmitForm(formDirective: FormGroupDirective) {
    if (!this.otiRepairForm.valid) {
      this.helperservice.makeFormDirty(this.otiRepairForm);
      return;
    } else {
      var fd = new FormData();
      fd.append('userId', 'ISA_SPECIAL_PROJECT');
      if(this.filesList.length > 0) {                           //bug 10
      for (var i = 0; i < this.filesList.length; i++) {
        var file = this.filesList[i]
        fd.append('files', file);
      }
      this.singleImageUpload1(fd, formDirective);
    } else {
      this.saveOtiRepairRecord([], formDirective);
    }
    }
  }

  uploadImage(event: any) {
    var files = event.target.files;
    console.log(files);

    this.fileSizeError = '';

    for (var i = 0; i < files.length; i++) {
        var f = files[i];
        if (f.size > 5 * 1024 * 1024) {
            this.fileSizeError = `File ${f.name} exceeds the 5MB size limit.`;
            continue;
        }

        f.mediaSRC = URL.createObjectURL(f);
        this.filesList.push(f);
        console.log(this.filesList);
    }
}

  closePopup() {
    this.helperservice.hideModal('ConfirmDialog');
  }

  

  singleImageUpload1(data: FormData, formDirective: FormGroupDirective) {
    if (!data.has('files')) {
      this.saveOtiRepairRecord([], formDirective);
      return;
    }
  
    this.spinner.show();
    this.httpService.uploadImageRequest('specialProject/uploadFiles', data,"OTI_REPAIR").subscribe(
      res => {
        this.saveOtiRepairRecord(res['fileUrl'], formDirective);
      },
      err => {
        this.spinner.hide();
        this.responseMessage = "Error occurred during file upload.";
        this.helperservice.openStaticModal('ConfirmDialog');
      }
    );
  }
  
  saveOtiRepairRecord(fileUrls: string[], formDirective: FormGroupDirective) {
    this.spinner.show();
    const inspFrom = this.otiRepairForm.value;
    let postData = {
      projectId: this.firstParam,
      itemCode: inspFrom.itemcode,
      holderNumber: inspFrom.holderNumber,
      serialNumber: inspFrom.serialNumber,
      componentCode: inspFrom.componentCode,
      defectCode: inspFrom.defectCode,
      sparePartIdList: this.spareList,
      createdBy: localStorage.getItem('userName'),
      description: inspFrom.description || "",
      fileUrls: fileUrls
    };
  
    this.httpService.postRequest('otiRepairApi/v1/saveOtiRepairRecord', postData).subscribe(
      res => {
        this.spinner.hide();
        if (res['status'] === 'SUCCESS') {
          this.responseMessage = res['response'];
          this.addedSpares = [];
          // this.otiRepairForm.reset();
          // formDirective.resetForm();
          const currentHolder = inspFrom.holderNumber;
        const defaultValues = {
          itemcode: '',
          holderNumber: currentHolder,
          serialNumber: '',
          componentCode: '',
          defectCode: '',
          description: ''
        };
        this.otiRepairForm.reset(defaultValues);
        formDirective.resetForm(defaultValues);
          this.filesList = [];
          const fileInput = document.getElementById("chooseFile") as HTMLInputElement;
          if (fileInput) {
            fileInput.value = "";
          }
          this.helperservice.openStaticModal('ConfirmDialog');
        } else {
          this.responseMessage = res['response'];
          this.helperservice.openStaticModal('ConfirmDialog');
        }
      },
      err => {
        this.spinner.hide();
        this.responseMessage = err.response;
        this.helperservice.openStaticModal('ConfirmDialog');
      }
    );
  }
  

  getDefectCodes() {
    this.httpService
        .getRequest("specialProjectApi/v1/defectCodes")
        .subscribe(
            (res) => {
                if ((res["status"] = "SUCCESS")) {
                  this.defectCodes = res["defectCodes"];
                } 
                else {
                    this.responseMessage = res['response'];
                    this.helperservice.openStaticModal('ConfirmDialog');
                    return;
                }
            },
            (err) => {
                // alert("Internal issue to fetch the information");
                this.responseMessage = err.response;
                this.helperservice.openStaticModal('ConfirmDialog');
            }
        );
}

getComponentCodes() {
  this.httpService
      .getRequest("specialProjectApi/v1/componentCodes")
      .subscribe(
          (res) => {
              if ((res["status"] = "SUCCESS")) {
                this.componentCodes = res["componentCodes"];
              } 
              // else {
              //     console.log("error fetching component codes");
              // }
              else {
                this.responseMessage = res['response'];
                this.helperservice.openStaticModal('ConfirmDialog');
                return;
            }
          },
          (err) => {
              // alert("Internal issue to fetch the information");
              this.responseMessage = "Internal issue to fetch the information";
              this.helperservice.openStaticModal('ConfirmDialog');
          }
      );
}

displayComponentCode(code: any): string{
  // return code ? `${code[0]} - ${code[1]}` : '';
  return code ? code : '';
}

private _filterComponentCodes(value: string): any[] {
  if(!this.componentCodes){
    return [];
  }

  const filterValue = value.toLowerCase();
  return this.componentCodes.filter(option =>
    // option[0].toLowerCase().includes(filterValue) || option[1].toLowerCase().includes(filterValue)
    `${option[0]} - ${option[1]}`.toLowerCase().includes(filterValue)
  )
}

getComponentCodeVerified(event: any) {
  if (event.isUserInput) {
    console.log('Selected Component Code:', event.source.value);
  }
}

getSpareParts() {
  this.httpService
      .getRequest("specialProjectApi/v1/spareParts")
      .subscribe(
          (res) => {
              if ((res["status"] = "SUCCESS")) {
                this.spareParts = res["spareParts"];
              } 
              // else {
              //     console.log("error fetching spare parts");
              // }
              else {
                this.responseMessage = res['response'];
                this.helperservice.openStaticModal('ConfirmDialog');
                return;
            }
          },
          (err) => {
              // alert("Internal issue to fetch the information");
              this.responseMessage = "Internal issue to fetch the information";
              this.helperservice.openStaticModal('ConfirmDialog');
          }
      );
}

loadFilter() {
  this.spareMasterCode = this.spareForm.get('spareCode').valueChanges
    .pipe(
      startWith(''),
      map(value => this._filterSpareCode(value))
    );

}

private _filterSpareCode(value: string): string[] {
  if ((value === undefined || value === null) || (this.spareParts === undefined || this.spareParts === null))
    return;
  const filterValue = value.toLowerCase();
  var data = this.spareParts.filter(option1 => String(option1.matnr12nc).toLowerCase().indexOf(filterValue) > -1 ||
    option1.spareName.toLowerCase().indexOf(filterValue) > -1);
  return data
}

SMcode: any;
getSpareCodeVerified(event) {
  this.spareCodeFlag = event.isUserInput;
}

focusOutSpareCode(code) {
  if (code != undefined) {
    var codeFound = this.spareParts.find(x => x.spareName == code);
    if (codeFound != undefined && code == codeFound.spareName) {
      this.spareCodeFlag = true;
    }
    else {
      this.spareCodeFlag = false;
    }
  }

}

cancelSpare() {
  this.isSpareError = false;
  this.spareForm.reset();
  this.spform.resetForm(
    {
      recoveredSparePart: '',
      otherSparePart:'',
      quantity: '1',
      isSparePartRecovered: 'YES',
    }
  );
  this.helperservice.hideModal('Add-Spare-Dialog');
  this.loadFilter();
}

addSpare() {
  if (!this.spareForm.valid) {
    this.helperservice.makeFormDirty(this.spareForm);
    return;
  }
  var spare = this.spareForm.value;
  if (spare.spareCode != undefined) {
    var codeFound = this.spareParts.find(x => x.spareName == spare.spareCode);
    this.addedSpares.push(codeFound);
    this.spareList.push(codeFound.matnr12nc);
        this.spareCodeFlag = false;
        this.cancelSpare() ; 
      }
}

onCodeResult(resultString: string) {
  console.log("Scanned code:", resultString);
  if (!resultString) {
      console.error("No barcode detected");
      return;
  }

  if (this.scanStart) {
      this.otiRepairForm.get('holderNumber').setValue(resultString);
  } else if (this.scanSerialStart) {
      this.otiRepairForm.get('serialNumber').setValue(resultString);
  }

  let audio = new Audio("assets/mp3/beep-02.mp3");
  audio.load();
  audio.play();

  this.spinner.show();
  setTimeout(() => {
      this.spinner.hide();
      this.closeScanner();
  }, 2000);
}

onCamerasFound(devices: MediaDeviceInfo[]): void {
this.availableDevices = devices;
this.hasDevices = Boolean(devices && devices.length);
}
onDeviceSelectChange(selected: string) {
const device = this.availableDevices.find(x => x.deviceId === selected);
this.currentDevice = device || null;
}

onHasPermission(has: boolean) {
this.hasPermission = has;
}

onTorchCompatible(isCompatible: boolean): void {
this.torchAvailable$.next(isCompatible || false);
}

toggleTorch(): void {
this.torchEnabled = !this.torchEnabled;
}

toggleTryHarder(): void {
this.tryHarder = !this.tryHarder;
}

closeScanner() {
this.scanStart = false;
this.scanSerialStart = false;
this.helperservice.hideModal("barcodeScan");
} 
}