import {
    Component,
    OnInit,
    ViewChild,
    Inject,
    ChangeDetectorRef,
    ElementRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
    FormGroup,
    FormBuilder,
    Validators,
    FormControl,
    FormGroupDirective,
} from "@angular/forms";
import { HttpService } from "../../shared/services/http.service";
import { NgxSpinnerService } from "ngx-spinner";
import { HelperService } from "app/helper.service";
import { BehaviorSubject } from "rxjs";
import { BarcodeFormat } from '@zxing/library';

@Component({
    selector: "safe-launch",
    templateUrl: "./safe-launch.component.html",
    styleUrls: ["./safe-launch.component.scss"],
})
export class SafeLaunchComponent implements OnInit {
    @ViewChild("userDform", { static: true }) form;
    safeLaunchForm: FormGroup;
    isChecked: boolean = true;
    ok: any;
    notOk: any;
    showClassification: boolean = false;
    showProblemFixed: boolean = false;
    filesList: any = [];
    firstParam: any;
    projectItemCodes: any;
    responseMessage: any;
    mixerZoneData: any = [];
    getCFT: any;
    classificationValues: any = [];
    inspectionType: any;
    showMixerZone: boolean = false;
    maxRecords: number = 10;
    recordCount: any;
    errorMessage: string = '';
    showComponent: boolean = true;
    fileSizeError: string = '';
    scanStart:boolean=false; 
    scanSerialStart: boolean = false;
    qrResultString: string;
    availableDevices: MediaDeviceInfo[];
    hasDevices: boolean;
    qrResults: any=[];
    currentDevice: MediaDeviceInfo = null;
    hasPermission: boolean;
    torchAvailable$ = new BehaviorSubject<boolean>(false);
    torchEnabled = false;
    tryHarder = false;
    projectName: any;
    category: any;
    totalCount: any;
  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];
    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private httpService: HttpService,
        private spinner: NgxSpinnerService,
        private helperservice: HelperService,
        private cdr: ChangeDetectorRef
    ) {}
    ngOnInit() {
        this.firstParam = this.route.snapshot.queryParamMap.get("projectID");
        this.getProjectDetails();
        this.getMixerZone();
        this.getClassificationFT();
        this.safeLaunchForm = this.fb.group({
            itemcode: ["", [Validators.required]],
            holderNumber: ['', [Validators.required, Validators.maxLength(20), Validators.minLength(9)]],
            serialNumber: [""],
            label: ['OK', [Validators.required]],
            classification: [""],
            description: [""],
            problem: ["NO"],
            mixerZone: [""],
        });
    }

    getProjectDetails() {
        this.spinner.show();
        this.httpService.getRequest("specialProjectApi/v1/projectDetails?projectId=" + this.firstParam).subscribe(
        (res) => {
            this.spinner.hide();
            if(res['status'] === 'ERROR'){
                this.responseMessage = res['errorMessage'];
                this.helperservice.openStaticModal('ConfirmDialog');
                return;
              }
                this.projectItemCodes =res["projectDetails"]["projectItemCodeMappingDtoList"];
                this.inspectionType =res["projectDetails"]["inspectionType"];
                this.projectName = res['projectDetails']['projectName'];
                this.projectCategoryCode = res["projectDetails"]["categoryCode"];
                this.category = res['projectDetails']['category'];
                this.recordCount = res['projectDetails']['recordCount'];
                this.totalCount = res['projectDetails']['totalQuantity'];
                },
                
                err => {
                    // alert("Internal issue to fetch the information");
                    this.spinner.hide();
                    this.responseMessage = "Internal issue to fetch the information";
                    this.helperservice.openStaticModal('ConfirmDialog');
                  }
            );
    }

    getMixerZone() {
        this.spinner.show();
        this.httpService
            .getRequest("specialProjectApi/v1/getMixerZones")
            .subscribe(
                (res) => {
                    this.spinner.hide();
                    console.log("getMixerZone", res["items"]);
                    this.mixerZoneData = res["items"];
                },
                err => {
                    // alert("Internal issue to fetch the information");
                    this.spinner.hide();
                    this.responseMessage = "Internal issue to fetch the information";
                    this.helperservice.openStaticModal('ConfirmDialog');
                  }
            );
    }

    projectCategoryCode:any;
    mixerCategorySet:any =["F89","G11"];
    
    qualityOk() {
        console.log("ok");
        this.showClassification = false;
        this.showMixerZone = false;
        this.showProblemFixed=  false;
        this.mixerZoneData=[];
        this.safeLaunchForm.controls["problem"].setValue("NO");
        this.safeLaunchForm.controls["classification"].clearAsyncValidators();
        this.safeLaunchForm.controls["classification"].updateValueAndValidity();
        this.safeLaunchForm.controls["mixerZone"].clearAsyncValidators();
        this.safeLaunchForm.controls["mixerZone"].updateValueAndValidity();
    }
    // qualityNotOk() {
    //     console.log("not ok");
    //     this.showClassification = true;
    //     this.showProblemFixed=  true;
    //     // this.showMixerZone = true;
    //     this.showMixerZone = this.mixerCategorySet.includes(this.projectCategoryCode);
    //     if(this.showMixerZone) {
    //         this.getMixerZone();
    //     }
    //     this.safeLaunchForm.controls["classification"].setValidators([Validators.required,]);
    //     this.safeLaunchForm.controls["classification"].updateValueAndValidity();
    //     this.safeLaunchForm.controls["mixerZone"].setValidators([Validators.required,]);
    //     this.safeLaunchForm.controls["mixerZone"].updateValueAndValidity();

    //     this.cdr.detectChanges();

    // }

    qualityNotOk() {
        console.log("not ok");
        this.showClassification = true;
        this.showProblemFixed = true;
        
        if (this.mixerCategorySet.includes(this.projectCategoryCode)) {
            this.showMixerZone = true;
            this.getMixerZone();
            this.safeLaunchForm.controls["mixerZone"].setValidators([Validators.required]);
        } else {
            this.showMixerZone = false;
            this.safeLaunchForm.controls["mixerZone"].clearValidators();
        }
        this.safeLaunchForm.controls["mixerZone"].updateValueAndValidity();
    
        this.safeLaunchForm.controls["classification"].setValidators([Validators.required]);
        this.safeLaunchForm.controls["classification"].updateValueAndValidity();
    
        this.cdr.detectChanges();
    }
    
    

    itemCodeChange() {
        var itemCode = this.safeLaunchForm.value.itemcode;
        console.log("itemcode : " + itemCode);
        // if (
        //     itemCode != null &&
        //     this.safeLaunchForm.value.itemcode.includes("5KSM55")
        // ) {
        //     this.showMixerZone = true;
        // } else {
        //     this.showMixerZone = false;
        // }
    }

    safeLaunchSubmitForm(formDirective: FormGroupDirective) {
        if (!this.safeLaunchForm.valid) {
            this.helperservice.makeFormDirty(this.safeLaunchForm);
            return;
        } else {
            var fd = new FormData();
            fd.append("userId", "ISA_SPECIAL_PROJECT");
            for (var i = 0; i < this.filesList.length; i++) {
                var file = this.filesList[i];
                fd.append("files", file);
            }
            this.singleImageUpload1(fd, formDirective);
        }
    }

    getClassificationFT() {
        this.spinner.show();
        this.httpService
            .getRequest("specialProjectApi/v1/getClassificationForTechnicians")
            .subscribe(
                (res) => {
                    this.spinner.hide();
                    console.log("getClassificationFT", res["items"]);
                    this.getCFT = res["items"];
                },
                err => {
                    // alert("Internal issue to fetch the information");
                    this.spinner.hide();
                    this.responseMessage = "Internal issue to fetch the information";
                    this.helperservice.openStaticModal('ConfirmDialog');
                  }
            );
    }

    

    uploadImage(event: any) {
        var files = event.target.files;
        console.log(files);
    
        this.fileSizeError = '';
    
        for (var i = 0; i < files.length; i++) {
            var f = files[i];
            if (f.size > 5 * 1024 * 1024) {
                this.fileSizeError = `File ${f.name} exceeds the 5MB size limit.`;
                continue;
            }
    
            f.mediaSRC = URL.createObjectURL(f);
            this.filesList.push(f);
            console.log(this.filesList);
        }
    }

    closePopup() {
        this.helperservice.hideModal("ConfirmDialog");
    }

    singleImageUpload1(data, formDirective) {
        this.spinner.show();
        this.httpService
            .uploadImageRequest("specialProject/uploadFiles", data,"SAFE_LAUNCH")
            .subscribe(
                (res) => {
                    var inspFrom = this.safeLaunchForm.value;
                    let postData = {
                        projectId: this.firstParam,
                        itemCode: inspFrom.itemcode,
                        inspectionType: this.inspectionType,
                        holderNumber: inspFrom.holderNumber,
                        serialNumber: inspFrom.serialNumber,
                        quality: inspFrom.label,
                        description: inspFrom.description
                            ? inspFrom.description
                            : "",
                        zoneList: inspFrom.mixerZone ? inspFrom.mixerZone : [],
                        problemFixedStatus: inspFrom.problem,
                        createdBy: localStorage.getItem("userName"),
                        fileUrls: res["fileUrl"],
                    };
                    if(inspFrom.classification != null && inspFrom.classification != "" ) {
                        postData["classification"] = inspFrom.classification
                    }
                    this.httpService
                        .postRequest(
                            "safeLaunchApi/v1/saveSafeLaunchRecord",
                            postData
                        )
                        .subscribe(
                            (res) => {
                                this.spinner.hide();

                                if ((res[status] = "SUCCESS")) {
                                    this.responseMessage = res["response"];
                                    // this.safeLaunchForm.reset();
                                    // formDirective.resetForm();
                                    const currentHolder = inspFrom.holderNumber;
                                    const defaultValues = {
                                        itemcode: '',
                                        holderNumber: currentHolder,
                                        serialNumber: '',
                                        label: 'OK',
                                        classification: '',
                                        description: '',
                                        problem: 'NO',
                                        mixerzone: ''
                                      };
                                  this.safeLaunchForm.reset(defaultValues);
                                  formDirective.resetForm(defaultValues);

                                    this.filesList = [];
                                    this.showProblemFixed = false;
                                    this.showClassification = false;
                                    this.qualityOk();
                                    const fileInput = document.getElementById("chooseFile") as HTMLInputElement;
                                    if (fileInput) {
                                        fileInput.value = "";
                                    }
                                    this.showClassification = false;
                                    this.safeLaunchForm.controls[
                                        "label"
                                    ].setValue("OK");
                                    this.safeLaunchForm.controls[
                                        "problem"
                                    ].setValue("NO");
                                    this.helperservice.openStaticModal(
                                        "ConfirmDialog"
                                    );
                                } else {
                                    this.responseMessage = res['response'];
                                    this.helperservice.openStaticModal('ConfirmDialog');
                                  }
                                },
                                err => {
                                  this.spinner.hide();
                                  // console.log("error block");
                                  this.responseMessage = err.response;
                                  this.helperservice.openStaticModal('ConfirmDialog');
                                }
                              );
                            },
                            err => {
                              this.spinner.hide();
                              this.responseMessage = "Error occurred during file upload.";
                              this.helperservice.openStaticModal('ConfirmDialog');
                            }
                          );
    }
    
    onCodeResult(resultString: string) {
        console.log("Scanned code:", resultString);
        if (!resultString) {
            console.error("No barcode detected");
            return;
        }
      
        if (this.scanStart) {
            this.safeLaunchForm.get('holderNumber').setValue(resultString);
        } else if (this.scanSerialStart) {
            this.safeLaunchForm.get('serialNumber').setValue(resultString);
        }
      
        let audio = new Audio("assets/mp3/beep-02.mp3");
        audio.load();
        audio.play();
      
        this.spinner.show();
        setTimeout(() => {
            this.spinner.hide();
            this.closeScanner();
        }, 2000);
      }
      
      onCamerasFound(devices: MediaDeviceInfo[]): void {
      this.availableDevices = devices;
      this.hasDevices = Boolean(devices && devices.length);
      }
      onDeviceSelectChange(selected: string) {
      const device = this.availableDevices.find(x => x.deviceId === selected);
      this.currentDevice = device || null;
      }
      
      onHasPermission(has: boolean) {
      this.hasPermission = has;
      }
      
      onTorchCompatible(isCompatible: boolean): void {
      this.torchAvailable$.next(isCompatible || false);
      }
      
      toggleTorch(): void {
      this.torchEnabled = !this.torchEnabled;
      }
      
      toggleTryHarder(): void {
      this.tryHarder = !this.tryHarder;
      }
      
      closeScanner() {
      this.scanStart = false;
      this.scanSerialStart = false;
      this.helperservice.hideModal("barcodeScan");
      } 
}
