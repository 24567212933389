import { Component, OnInit, ViewChild, Inject, ChangeDetectorRef, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective } from '@angular/forms';
import { HttpService } from "../../shared/services/http.service";
import { NgxSpinnerService } from "ngx-spinner";
import { HelperService } from 'app/helper.service';
import { prepareEventListenerParameters } from '@angular/compiler/src/render3/view/template';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { BarcodeFormat } from '@zxing/library';


@Component({
  selector: 'replug',
  templateUrl: './replug.component.html',
  styleUrls: ['./replug.component.scss'],
})


export class ReplugComponent implements OnInit {
  @ViewChild(MatAutocompleteTrigger, { static: true }) inputAutoComplit: MatAutocompleteTrigger;
  @ViewChild('userDform', { static: true }) form;
  replugForm: FormGroup;
  firstParam: any;
  projectItemCodes: any;
  responseMessage: any;
  projectName:any;
  itemCodeValues:any;
  itemCodes:any;
  scanStart: boolean = false
  scanSerialStart: boolean = false;
  itemCodeFlag:boolean = true;
  torchAvailable$ = new BehaviorSubject<boolean>(false);
  torchEnabled = false;
  tryHarder = false;
  currentDevice: MediaDeviceInfo = null;
  hasPermission: boolean;
  hasDevices: boolean;
  recordCount: any;
    totalCount: any;
    category: any;
  from: any;
  to: any;
  availableDevices: MediaDeviceInfo[];
  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];
  fileSizeError: string = '';
    openPanel(evt): void {
        evt.stopPropagation();
        this.inputAutoComplit.openPanel();
    }


  constructor(private route: ActivatedRoute, private fb: FormBuilder, private httpService: HttpService, private spinner: NgxSpinnerService, private helperservice: HelperService) {

  }

  ngOnInit() {
    this.firstParam = this.route.snapshot.queryParamMap.get('projectID');
    this.getProjectDetails();

    this.initialiseForm();
    // this.standardInspectionForm.reset();
    this.getItemCodes();
  }
  initialiseForm(){
    this.replugForm = this.fb.group({
      itemcode: ['', [Validators.required]],
      newItemCode:['',Validators.required],
      serialNumber: [''],
      hipotEquipmentChecked: ['false'],
      calibrationRecords: ['false'],
      hipotTest: ['false'],
      continuityTest: ['false'],
      functionalTest: ['false'],
      description:[''],
    });
  }

  getProjectDetails() {
    this.spinner.show();
    this.httpService.getRequest('specialProjectApi/v1/projectDetails?projectId=' + this.firstParam).subscribe(
      res => {
        this.spinner.hide();
        if(res['status'] === 'ERROR'){
          this.responseMessage = res['errorMessage'];
          this.helperservice.openStaticModal('ConfirmDialog');
          return;
        }
        this.projectItemCodes = res['projectDetails']['projectItemCodeMappingDtoList'];
        this.projectName = res['projectDetails']['projectName'];
        this.category = res['projectDetails']['category'];
        this.recordCount = res['projectDetails']['recordCount'];
        this.totalCount = res['projectDetails']['totalQuantity'];
        this.from = res['projectDetails']['from'];
        this.to = res['projectDetails']['to'];

      },
      err => {
        // alert("Internal issue to fetch the information");
        this.spinner.hide();
        this.responseMessage = "Internal issue to fetch the information";
        this.helperservice.openStaticModal('ConfirmDialog');
      }
    );
  }

  getItemCodeVerified(event) {
    this.itemCodeFlag = event.isUserInput;
  }
  focusOutItemCode(code) {
    if (code != undefined) {
      var codeFound = this.itemCodeValues.find(x => x == code);
      if (codeFound != undefined && code == codeFound) {
        this.itemCodeFlag = true;
      }
      else {
        this.itemCodeFlag = false;
      }
    }
  
  }
 
  onCodeResult(resultString: string) {
    console.log("Scanned code:", resultString);
    if (!resultString) {
        console.error("No barcode detected");
        return;
    }

    if (this.scanStart) {
        this.replugForm.get('holderNumber').setValue(resultString);
    } else if (this.scanSerialStart) {
        this.replugForm.get('serialNumber').setValue(resultString);
    }

    let audio = new Audio("assets/mp3/beep-02.mp3");
    audio.load();
    audio.play();

    this.spinner.show();
    setTimeout(() => {
        this.spinner.hide();
        this.closeScanner();
    }, 2000);
}

onCamerasFound(devices: MediaDeviceInfo[]): void {
  this.availableDevices = devices;
  this.hasDevices = Boolean(devices && devices.length);
}
onDeviceSelectChange(selected: string) {
  const device = this.availableDevices.find(x => x.deviceId === selected);
  this.currentDevice = device || null;
}

onHasPermission(has: boolean) {
  this.hasPermission = has;
}

onTorchCompatible(isCompatible: boolean): void {
  this.torchAvailable$.next(isCompatible || false);
}

toggleTorch(): void {
  this.torchEnabled = !this.torchEnabled;
}

toggleTryHarder(): void {
  this.tryHarder = !this.tryHarder;
}

closeScanner() {
  this.scanStart = false;
  this.scanSerialStart = false;
  this.helperservice.hideModal("barcodeScan");
}    
getItemCodes() {
  this.spinner.show();
  this.httpService.getRequest("specialProjectApi/v1/itemCodes").subscribe(
      (res) => {
          this.spinner.hide();
          console.log(res["ItemCodes"]);
          this.itemCodeValues = res["ItemCodes"];
          this.loadFilterForItemCode();
      },
      err => {
        // alert("Internal issue to fetch the information");
        this.spinner.hide();
        this.responseMessage = "Internal issue to fetch the information";
        this.helperservice.openStaticModal('ConfirmDialog');
      }
  );
}

loadFilterForItemCode() {
this.itemCodes = this.replugForm.get('newItemCode').valueChanges
  .pipe(
    startWith(''),
    map(value => this._filterItemCode(value))
  );

}
private _filterItemCode(value: string): string[] {
  if ((value === undefined || value === null) || (this.itemCodeValues === undefined || this.itemCodeValues === null))
    return;
  const filterValue = value.toLowerCase();
  var data = this.itemCodeValues.filter(option1 =>option1.toLowerCase().indexOf(filterValue) > -1);
  return data
}

  replugSubmitForm(formDirective: FormGroupDirective) {
    if (!this.replugForm.valid) {
      this.helperservice.makeFormDirty(this.replugForm);
      console.log("validation failed");
      return;
    } else {
      var fd = new FormData();
      fd.append('userId', 'ISA_SPECIAL_PROJECT');
      this.saveReplugRecord(fd, formDirective);
    }
  }

  closePopup() {
    this.helperservice.hideModal('ConfirmDialog');
  }

  saveReplugRecord(data, formDirective) {
    this.spinner.show();
        var inspFrom = this.replugForm.value;
        let postData = {
          "projectId": this.firstParam,
          "oldItemCode": inspFrom.itemcode,
          "newItemCode": inspFrom.newItemCode,
          "serialNumber": inspFrom.serialNumber,
          "hipotEquipmentChecked": inspFrom.hipotEquipmentChecked,
          "calibrationRecords": inspFrom.calibrationRecords,
          "hipotTest": inspFrom.hipotTest,
          "continuityTest":inspFrom.continuityTest,
          "functionalTest":inspFrom.functionalTest,
          "description": inspFrom.description?inspFrom.description:"",
          "createdBy": localStorage.getItem('userName')
        };
        console.log("RecordReplugDto : "+postData);
        this.httpService.postRequest('replugApi/v1/saveReplugRecord', postData).subscribe(
          res => {
            this.spinner.hide();
            if (res['status'] = 'SUCCESS') {
              this.responseMessage = res['response'];
             this.initialiseForm();
              this.helperservice.openStaticModal('ConfirmDialog');
            } else {
              this.responseMessage = res['response'];
              this.helperservice.openStaticModal('ConfirmDialog');
            }
          },
          err => {
            this.spinner.hide();
            this.responseMessage = err.response; //ask
            this.helperservice.openStaticModal('ConfirmDialog');
        }
        );
  }

}